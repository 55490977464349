import { AnyObject } from '../../../types/types';

type MakeFilterProps = {
  search?: string;
  sku?: string | null;
  name?: string | null;
};

export const makeFilter = ({ search, sku, name }: MakeFilterProps) => {
  const filter: AnyObject = {};

  if (search) {
    const valueSearch = search.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'name',
          value: search,
          method: 'includes'
        },
        {
          path: 'sku',
          value: search,
          method: 'includes'
        }
      ];
      return accumulator;
    }, {});
    filter.search = [...valueSearch[0]];
  }

  if (name) {
    filter.name = [
      {
        path: 'name',
        value: name,
        method: 'includes'
      }
    ];
  }
  if (sku) {
    filter.sku = [
      {
        path: 'sku',
        value: sku,
        method: 'includes'
      }
    ];
  }
  return filter;
};
