import { Box } from '@material-ui/core';
import React from 'react';
import { Order_reporting_delivery_control_reporting_group } from '../../../../../../interfaces/business';
import { Column } from '../../../../../components/InfoTable';

export const columnsReportingDeliveryControlGroup: Array<Column> = [
  {
    id: 'sla',
    label: 'SLA'
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'marketplace_id'
  },

  {
    id: 'quantity',
    label: 'Quantity',
    orderById: 'quantity'
  },
  {
    id: 'quantity',
    label: '% Quantity',
    orderById: 'quantity',
    render: ({ quantity_percentage }: Order_reporting_delivery_control_reporting_group) => <Box>{quantity_percentage.toFixed(2)}%</Box>
  },
  {
    id: 'total_hour_percentage',
    label: 'Average time in hours',
    orderById: 'hour',
    render: ({ total_hour_percentage }: Order_reporting_delivery_control_reporting_group) => <Box>{total_hour_percentage}</Box>
  },
  {
    id: 'effectiveness',
    label: 'Effectiveness',
    orderById: 'effectiveness',
    render: ({ effectiveness }: any) => <Box>{effectiveness.toFixed(2)}%</Box>
  }
];
