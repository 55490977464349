import React, { useCallback } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { BudgetList } from './routes/BudgetList';
import { useBreadcrumbs, useFilerParamsId } from '../../hooks';
import { makeFilter } from './utils/makeFilter';
import { useOrderTableLogic } from '../Orders/routes/OrdersList/hook/useOrderTableLogic'; // TODO:CHANGE IMPLEMENTATIO TO BUDGET
import { LibbyObject } from '../../types';
import { BudgetNew } from './routes/BudgetNew';
import { useTranslation } from '../../services/translation';
import { OrderStateLogs } from '../Orders/routes/OrderStateLogs';
import { OrderDetail } from '../Orders/routes/OrderDetail';
import { ORDER_SOURCE_CANAL_OF_LINE_ENABLED } from '../../const/canalOffLine';

interface BudgetTypes {
  libby: LibbyObject;
}
const filterInit = makeFilter({ canalOffLine: ORDER_SOURCE_CANAL_OF_LINE_ENABLED, orderStatus: '29' });

export const BudgetRouter = ({ libby }: BudgetTypes) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();

  const title = useBreadcrumbs(t('Budget'));
  const match = useRouteMatch();

  const detailsRedirection = useCallback((order_id: string) => history.push(`orders/detail/${order_id}`), [history]);

  const { filter, orderBy, direction, paramsFetch, setFilter, handleRequestSort, resetFilter } = useFilerParamsId({
    orderInit: 'order_id',
    daoName: 'ster_order_so_with_delivery_date',
    aspect: 'list_order_so_order',
    init: filterInit
  });

  const { rows, columns, working, fetchMore, reFetch } = useOrderTableLogic({
    libby,
    paramsFetch,
    detailsRedirection,
    columnCheckEnabled: true,
    actions: false,
    path: match.path,
    deliveryDate: true
  });

  const onRowClick = (row: LibbyObject) => {
    history.push(`${path}/detail/${row.order_id}`);
  };

  const onNewBudget = () => {
    history.push(`${path}/new-budget`);
  };

  return (
    <Switch>
      <Route path={`${path}/new-budget`}>
        <BudgetNew libby={libby} title={title} />
      </Route>
      <Route path={`${match.path}/logs/:id`}>
        <OrderStateLogs title={title} />
      </Route>
      <Route path={`${match.path}/detail/:id`}>
        <OrderDetail title={title} />
      </Route>
      <BudgetList
        title={title}
        detailUrl={`${path}/detail`}
        setFilter={setFilter}
        rows={rows}
        columns={columns}
        working={working}
        filter={filter}
        fetchMore={fetchMore}
        onSortChange={handleRequestSort}
        orderBy={orderBy}
        direction={direction}
        onRowClick={onRowClick}
        reFetch={reFetch}
        resetFilter={resetFilter}
        onNewBudget={onNewBudget}
        canalOffLine
      />
    </Switch>
  );
};

export const Budget = DatabaseConnector(BudgetRouter)(
  'ster_order_buyer',
  'ster_product',
  'ster_order_source',
  'order_state',
  'ster_order_document_type',
  'courier_order',
  'ster_order_table',
  'ster_order_shipment',
  'ster_order_so_with_delivery_date'
);
