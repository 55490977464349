import { Moment } from 'moment';
import { startDateFormat, endDateFormat } from '../../../../utils';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class GoalDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_goal', 'goal_id');
  }

  getAllGoal() {
    return this.query('list_all_goal').limit(0, 10000).run();
  }

  getAllGoalByDate({ from, to }: { from: Moment; to: Moment }) {
    const fromUtc = startDateFormat(from);
    const toUtc = endDateFormat(to);
    return this.query('list_all_goal').equals('goal_type_value', '2').equals('goal_period.start_date', fromUtc.format('YYYY-M-D HH:mm:ss')).equals('goal_period.end_date', toUtc.format('YYYY-M-D HH:mm:ss')).limit(0, 10000).run();
  }

  async getByDateGoal() {
    const ls = this.query();
    return ls.run();
  }
}
