import React, { useCallback, useMemo, MouseEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { useFilerParamsId } from '../../../hooks';
import { useLibbyFetch } from '../../../hooks/useLibbyFetch';
import { Message } from '../../../interfaces/business/message';
import { LibbyObject } from '../../../types';
import { RowsMessageType } from '../types';
import { columnsMessage, validate, formInitialValues } from '../utils';
import { makeFilter } from '../utils/makeFilter';
import { useTranslation } from '../../../services/translation';
import { MESSAGE_STATE } from '../../../const';
import { MessageRecipientForm, MessageRecipientFormConfirmType } from '../Components/MessageEmailForm';
import CustomModal from '../../../services/customFormDialog';
import { MenuActions, MenuItemComponentType } from '../../../components/MenuActions';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { MessageVisualizer } from '../../MessageTemplate/components/MessageVisualizer';

type useMessagesLogicProps = {
  libby: LibbyObject;
};

const RecipientDialogModal = CustomModal(MessageRecipientForm);

const ConfirmModal = CustomModal(ConfirmDialog);

const ShowMessage = CustomModal(MessageVisualizer);

export const useMessagesLogic = ({ libby }: useMessagesLogicProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const { paramsFetch, orderBy, filter, setFilter, resetFilter, direction, handleRequestSort } = useFilerParamsId({
    orderInit: 'updated_at',
    daoName: 'ster_message',
    directionInit: 'desc',
    initAuxiliary: makeFilter({
      source_id: searchParams.get('source_id') ?? undefined,
      message_source: searchParams.get('message_source') ?? undefined
    })
  });

  const { data, working, updateData, removeData, fetchMore, reFetch } = useLibbyFetch(libby, paramsFetch);

  const dataWithInterface = useMemo(() => data as Message[], [data]);

  const handleOpenEditModal = useCallback(
    async (message: Message, ccEnabled: boolean = false) => {
      const { message_to, message_id, message_cc } = message;
      const newDispatchInfo = (await RecipientDialogModal.show({
        confirmText: t('Update'),
        cancelText: t('Cancel'),
        title: t(!ccEnabled ? 'Recipient update' : 'CC update'),
        validate,
        formInitialValues: message_to
          ? {
              email: !ccEnabled ? message_to : message_cc
            }
          : formInitialValues
      })) as MessageRecipientFormConfirmType;
      if (newDispatchInfo) {
        try {
          const resend = await libby.ster_message_updated.save({
            ...message,
            message_to: !ccEnabled ? newDispatchInfo.email : message.message_to,
            message_cc: ccEnabled ? newDispatchInfo.email : message.message_cc
          });
          updateData(resend, 'message_id');
          enqueueSnackbar(t('Recipient of message $$$ successfully updated').replace('$$$', message_id), {
            variant: 'success'
          });
        } catch (error) {
          enqueueSnackbar(t('Error changing to recipint message $$$').replace('$$$', message_id), {
            variant: 'error'
          });
        }
      }
    },
    [t, enqueueSnackbar, libby.ster_message_updated, updateData]
  );

  const handleResend = useCallback(
    async (message: Message) => {
      const {
        message_id,
        message_source: { message_source_id },
        message_state,
        resend
      } = message;
      try {
        const message_reason_order = message_source_id === '1';
        const resendMessage = await libby.ster_message_updated.save({
          ...message,
          message_state: !message_reason_order
            ? {
                message_state_id: '2'
              }
            : message_state,
          resend: message_reason_order ? true : resend
        });
        updateData(resendMessage, 'message_id');
        enqueueSnackbar(t('Message $$$ change to forward successfully').replace('$$$', message_id), {
          variant: 'success'
        });
      } catch (error) {
        enqueueSnackbar(t('Error changing to forward message $$$').replace('$$$', message_id), {
          variant: 'error'
        });
      }
    },
    [libby.ster_message_updated, updateData, enqueueSnackbar, t]
  );

  const handleCancelModal = useCallback(
    async ({ message_id }: Message) => {
      try {
        const deleteMessage = await ConfirmModal.show({
          title: t('Delete Message'),
          content: `${t('You are about to delete the message $$$$, do you want to continue?')}`.replace('$$$$', message_id),
          confirmText: t('Confirm'),
          cancelText: t('Cancel')
        });
        if (deleteMessage) {
          await libby.ster_message_updated.remove({
            message_id
          });

          removeData(message_id, 'message_id');
          enqueueSnackbar(t('Message $$$ successfully deleted').replace('$$$', message_id), { variant: 'success' });
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`${t('Failed to delete message $$$')}: ${error}`, {
            variant: 'error'
          });
        }
      }
    },
    [enqueueSnackbar, t, libby, removeData]
  );

  const menuArray = useCallback(
    (message: Message): MenuItemComponentType[] => {
      const {
        resend,
        message_state: { message_state_id }
      } = message;
      const resendButtonDisabled = [MESSAGE_STATE.DELIVERED, MESSAGE_STATE.QUEUED];

      const menu: MenuItemComponentType[] = [
        {
          title: 'Resend',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleResend(message);
          },
          disabled: resend || resendButtonDisabled.includes(parseInt(message_state_id, 10))
        },
        {
          title: 'Edit Recipient',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleOpenEditModal(message);
          },
          disabled: false
        },
        {
          title: 'Edit cc',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleOpenEditModal(message, true);
          },
          disabled: false
        },
        {
          title: 'Delete',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleCancelModal(message);
          },
          color: 'error',
          disabled: false
        }
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [handleResend, handleCancelModal, handleOpenEditModal]
  );

  const showMessageDialog = useCallback((message: string, isDownSm: boolean, isMobile: boolean) => {
    ShowMessage.show({
      message,
      isDownSm,
      isMobile
    });
  }, []);

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down(450));
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const rows = useMemo(
    () =>
      dataWithInterface.reduce((allRows: RowsMessageType[], message: Message) => {
        const {
          message_id,
          created_at,
          source_id,
          updated_at,
          message_title,
          template,
          message_to,
          message_reason: { name: message_reason_name },
          message_source: { name: message_source_name },
          message_state: { name: message_status_name },
          resend,
          result,
          message: messageSent,
          message_cc
        } = message;
        const copyAllRows = [...allRows];
        copyAllRows.push({
          id: message_id,
          actions: <MenuActions menuArray={menuArray(message)} />,
          source_id,
          created_at,
          updated_at,
          message_title,
          message_subject: (
            <Box width="150px" display="flex" alignItems="center">
              {template?.message_subject || ''}
            </Box>
          ),
          message_to: { recipents: message_to, message_id },
          message_reason: message_reason_name,
          message_source: message_source_name,
          message_status: message_status_name,
          result,
          resend: resend ? 'Enabled' : 'Disabled',
          message: { showMessageDialog, messageSent, isDownSm, isMobile },
          message_cc
        });
        return copyAllRows;
      }, []),
    [dataWithInterface, menuArray, isDownSm, isMobile, showMessageDialog]
  );

  return {
    working,
    data: dataWithInterface,
    rows,
    reFetch,
    fetchMore,
    updateData,
    columns: columnsMessage,
    orderBy,
    filter,
    setFilter,
    resetFilter,
    direction,
    handleRequestSort
  };
};
