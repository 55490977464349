import React, { useState, useCallback, useLayoutEffect } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { OrderDetail } from './routes/OrderDetail';
import { OrdersList } from './routes/OrdersList';
import { OrderStateLogs } from './routes/OrderStateLogs';
import { useOrderTableLogic } from './routes/OrdersList/hook/useOrderTableLogic';
import { LibbyObject } from '../../types/types';
import { useBreadcrumbs, useFilerParamsId, useQueryParams } from '../../hooks';
import { makeFilter } from './routes/OrdersList/utils/makeFilter';
import { Marketplace } from '../../interfaces/business';

export const OrdersRouter = ({ libby }: LibbyObject) => {
  const { path } = useRouteMatch();

  const history = useHistory();

  const detailsRedirection = useCallback((order_id: string) => history.push(`orders/detail/${order_id}`), [history]);
  const [initAuxiliary, setinitAuxiliary] = useState('');

  const validateSearchQueryParams = useQueryParams('search');
  const validateMarketplaceNameQueryParams = useQueryParams('marketplace_name');
  const validateOwnerQueryParams = useQueryParams('owner_id');

  const searchMarketplace = useCallback(async (): Promise<Marketplace | undefined> => {
    if (!validateMarketplaceNameQueryParams) return undefined;
    const dataMarketplace = (await libby.ster_marketplace.query().includes('name', validateMarketplaceNameQueryParams).run()) as Marketplace[];
    return dataMarketplace.find(({ name }: Marketplace) => name.toLowerCase() === validateMarketplaceNameQueryParams.toLowerCase());
  }, [validateMarketplaceNameQueryParams, libby.ster_marketplace]);

  const initMakeFilter = useCallback(async () => {
    const marketplace = await searchMarketplace();
    setinitAuxiliary(marketplace?.marketplace_id || '');
  }, [searchMarketplace]);

  useLayoutEffect(() => {
    initMakeFilter();
  }, [initMakeFilter]);

  const { paramsFetch, orderBy, filter, setFilter, resetFilter, direction, handleRequestSort } = useFilerParamsId({
    orderInit: 'order_id',
    daoName: 'ster_order',
    directionInit: 'desc',
    aspect: 'list_order_so_order',
    initAuxiliary: makeFilter({
      search: validateSearchQueryParams || '',
      marketplace: initAuxiliary,
      ownerId: validateOwnerQueryParams || ''
    })
  });

  const { rows, columns, working, fetchMore, reFetch } = useOrderTableLogic({
    libby,
    paramsFetch,
    detailsRedirection,
    actions: true,
    path
  });

  const title = useBreadcrumbs('Orders');

  const onRowClick = (row: LibbyObject) => {
    history.push(`${path}/detail/${row.order_id}`);
  };

  return (
    <Switch>
      <Route path={`${path}/logs/:id`}>
        <OrderStateLogs title={title} />
      </Route>
      <Route path={`${path}/detail/:id`}>
        <OrderDetail title={title} />
      </Route>
      <Route path={`${path}`}>
        <OrdersList
          title={title}
          detailUrl={`${path}/detail`}
          setFilter={setFilter}
          rows={rows}
          columns={columns}
          working={working}
          filter={filter}
          fetchMore={fetchMore}
          onSortChange={handleRequestSort}
          orderBy={orderBy}
          direction={direction}
          onRowClick={onRowClick}
          reFetch={reFetch}
          resetFilter={resetFilter}
        />
      </Route>
    </Switch>
  );
};

export const Orders = DatabaseConnector(OrdersRouter)('ster_order', 'ster_order_table', 'ster_order_cancelled', 'ster_marketplace');
