import { LibbyFetchDAO } from './LibbyFetchDAO';
import { ACCOUNT_ROLE } from '../../../const';

export class AdminAccountDAO extends LibbyFetchDAO {
  constructor() {
    super('admin_account', 'account_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  async getAccountLogisticsOperator() {
    return this.query().equals('role.account_role_id', ACCOUNT_ROLE.LOGISTICS_OPERATOR).run();
  }

  async getAccountUsername(username: string) {
    return this.query().equals('username', username).run();
  }

  async getAccountEmail(email: string) {
    return this.query().equals('email', email).run();
  }
}
