import { Billing_period } from '../../../interfaces/business/orders/Order_reporting_billings_periods_ml';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_reporting_billing_period_ml';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Billing_period>(DAO_NAME);

// hooks
export const useReportingBillingPeriodsMlFetchById = fetchById;
export const useReportingBillingPeriodsMlLibbyCall = libbyCall;
export const useReportingBillingPeriodsMlDAO = libbyDAO;
export const useReportingBillingPeriodsMlLibbyFetch = libbyFetch;
