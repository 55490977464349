import { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from '../../services/translation';

export enum VALIDATIONS {
  ATLEASTEIGHT = 1,
  UPPERCASE = 2,
  LOWERCASE = 3,
  ONENUMBER = 4,
  SPECIALS = 5
}

interface Error {
  error: boolean;
  id: number;
  text?: string;
}

export const useValidateLogic = (value: string) => {
  const { t } = useTranslation();
  const atLeastEightTrue: Error = useMemo(() => ({ error: true, id: 1, text: t('Minimum 8 characters') }), [t]);
  const upperCaseTrue: Error = useMemo(() => ({ error: true, id: 2, text: t('One upper case') }), [t]);
  const lowerCaseTrue: Error = useMemo(() => ({ error: true, id: 3, text: t('One lower case') }), [t]);
  const oneNumberTrue: Error = useMemo(() => ({ error: true, id: 4, text: t('One number') }), [t]);
  const specialTrue: Error = useMemo(() => ({ error: true, id: 5, text: t('One special character') }), [t]);

  const [atLeastEight, setAtLeastEight] = useState(atLeastEightTrue);
  const [upperCase, setUpperCase] = useState(upperCaseTrue);
  const [lowerCase, setLowerCase] = useState(lowerCaseTrue);
  const [oneNumber, setOneNumber] = useState(oneNumberTrue);
  const [special, setSpecial] = useState(specialTrue);

  const checkIndividualErrors = useCallback(() => {
    setAtLeastEight({ error: value.length < 8, id: 1, text: atLeastEightTrue.text });
    setUpperCase({ error: !/[A-Z]/.test(value), id: 2, text: upperCaseTrue.text });
    setLowerCase({ error: !/[a-z]/.test(value), id: 3, text: lowerCaseTrue.text });
    setOneNumber({ error: !/\d/.test(value), id: 4, text: oneNumberTrue.text });
    setSpecial({ error: !/[~`.@!#$%^&*+=\-[\]\\';,/{}()|\\":<>?]/.test(value), id: 5, text: specialTrue.text });
  }, [value, atLeastEightTrue, upperCaseTrue, specialTrue, lowerCaseTrue, oneNumberTrue]);

  useEffect(() => {
    checkIndividualErrors();
    // eslint-disable-next-line
    },[value])

  return {
    atLeastEight,
    upperCase,
    lowerCase,
    oneNumber,
    special
  };
};
