import React, { useMemo } from 'react';
import { AnyObject } from '../../types';
import { GeneralSelect } from '../GeneralSelect';
import { MARKETPLACE_ML_MP } from '../../const';

type SelectMpMlProps = {
  inputProps: AnyObject;
  [k: string]: any;
  label?: string;
  sort?: boolean | 'asc' | 'desc';
  optionAll?: boolean;
};

export const SelectMpMl = ({ optionAll = false, inputProps, label = 'Marketplace', sort, ...styles }: SelectMpMlProps) => {
  const data = useMemo(
    () =>
      MARKETPLACE_ML_MP.map(({ marketplace_id, name }: AnyObject) => ({
        id: marketplace_id.toString(),
        value: name
      })),
    []
  );

  return (
    <>
      <GeneralSelect optionAll={optionAll} working={false} label={label} data={data} inputProps={{ ...inputProps }} {...styles} sort={sort} />
    </>
  );
};
