import React, { useMemo } from 'react';
import { capitalize as capitalizeMUI } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useRouteMatch, useHistory } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject, OrderType, LibbyObject } from '../../../../../types/types';
import LoadingData from '../../../../components/LoadingData';
import { MakeCell } from '../../../../components/MakeCell';
import { SearchFilters } from './SearchFilters';
import { INBOX_ORDER_STATE } from '../../../../../const/InboxOrderState';
import { formatDecimal, formatDate } from '../../../../../utils';
import { ExportDialogComponent } from '../../../../../components/ExportDialog';
import CustomModal from '../../../../../services/customFormDialog';
import InfoTable from '../../../../components/InfoTable';

const SearchDialogModal = CustomModal(ExportDialogComponent);

const handleOpenDocumentsModal = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, errorMessage: string) => {
  event.stopPropagation();
  try {
    await SearchDialogModal.show({
      title: 'Error',
      properties: ['documentNumber', 'type.name'],
      id: ' order_id',
      label: '',
      render: () => <div>{errorMessage}</div>
    });
  } catch (error) {
    // nothing
  }
};

type OrderTableProps = {
  filter: object;
  data: AnyObject;
  working: boolean;
  setFilter: (makeFilter: object) => void;
  fetchMore: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
};

type RefType = unknown | OrderTableProps;

const columns = [
  {
    id: 'inbox_order_id',
    label: 'ID',
    width: '5%'
  },
  {
    id: 'date',
    label: 'Last Update',
    width: '20%',
    orderById: 'updated_at'
  },
  {
    id: 'import_id',
    label: 'Import Account',
    width: '20%',
    orderById: 'import_user_id.account.username'
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    render: ({ status }: OrderType, item: any, t: (str: string) => string) => t(capitalizeMUI(status ?? '')),
    orderById: 'state.name'
  },
  {
    id: 'owner_id',
    label: 'Owner ID',
    style: {
      whiteSpace: 'nowrap'
    },
    width: '20%',
    orderById: 'owner_id'
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    width: '20%',
    orderById: 'marketplace.name'
  },
  {
    id: 'reference',
    label: 'Reference',
    width: '10%',
    orderById: 'reference'
  },
  {
    id: 'error',
    label: 'Error',
    width: '10%',
    noSort: true
  },
  {
    id: 'actions',
    label: 'Detail',
    minwidth: 170,
    noSort: true
  }
];

export const InboxOrderTable = ({ data, working, filter, setFilter, fetchMore, onSortChange, orderBy, direction }: OrderTableProps) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();

  const rows = useMemo(
    () =>
      data.map(({ inbox_order_id, updated_at, marketplace, state, reference, situation, owner_id, importation }: AnyObject) => ({
        id: formatDecimal(inbox_order_id),
        inbox_order_id: formatDecimal(inbox_order_id),
        date: formatDate(updated_at, true, false),
        status: state.name,
        marketplace: marketplace.name,
        owner_id,
        reference,
        actions: <MakeCell label="" icon={AssignmentIcon} path={`${path}/detail/${inbox_order_id}`} />,
        import_id: importation?.account.username || 'N/A',
        error: state.inbox_order_state_id === INBOX_ORDER_STATE.PROBLEM && situation.exception ? <MakeCell icon={ErrorIcon} label="" onClick={(e) => handleOpenDocumentsModal(e, situation.exception)} /> : '-'
      })),
    [data, path]
  );

  const goToDetails = (row: LibbyObject) => {
    history.push(`${path}/detail/${row.inbox_order_id}`);
  };

  return (
    <>
      <SearchFilters onFilterChange={setFilter} initFetch={filter} />
      <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} onSortChange={onSortChange} onRowClick={goToDetails} orderBy={orderBy} direction={direction} />
      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={data.length} />
    </>
  );
};
