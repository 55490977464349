import React, { useEffect } from 'react';
import { useTranslation } from '../../../../../services/translation';
import InfoTable from '../../../../components/InfoTable';
import LoadingData from '../../../../components/LoadingData';
import { BudgetTableProps } from '../../../types';
import { SearchFilters } from './SearchFilter'; // TODO:Change logic to BUDGET

export const BudgetTable = ({ columns, rows, working, filter, setFilter, fetchMore, reFetch, onSortChange, orderBy, direction, onRowClick, canalOffLine = false, resetFilter }: BudgetTableProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (reFetch) reFetch();
  }, [reFetch]);

  return (
    <>
      <SearchFilters onFilterChange={setFilter} initFetch={filter} canalOffLine={canalOffLine} resetFilter={resetFilter} />
      <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} rowIdKey="order_id" onRowClick={onRowClick} onSortChange={onSortChange} orderBy={orderBy} direction={direction} />
      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
    </>
  );
};
