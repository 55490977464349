import React, { useMemo, useCallback } from 'react';
import { orderBy } from 'lodash';
import { Box, Card, Grid, Switch, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useTranslation } from '../../../../services/translation';
import { format } from '../../../../util';
import { BarChartComponent } from '../../../../components/BarChart';
import { useInterval } from '../../../../hooks/useIntervalPage';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { ButtonDetailsType } from '../../../../types/Button';
import { ButtonComponent } from '../../../../components/Button';
import { useReportingObjectiveSaleCategoryLogic } from './hook';
import { SearchFilters } from './components/SearchFilters';
import { FilterBarSelectionReportingObjectiveSales } from './type';
import { useBreadcrumbs } from '../../../../hooks';
import { useMenuAction } from '../../../../hooks/useMenuActions';
import { MENU, MENU_ACTIONS } from '../../../../const';

const filterInit: FilterBarSelectionReportingObjectiveSales = {
  canal: [],
  category: []
};

export const ReportingObjectiveSalesList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));

  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  // Build the columns, depends on t function for translations

  // eslint-disable-next-line arrow-body-style

  // FIXME CHINO-SDK IS NOT TAKING THE ALIAS, REMOVE THIS WHEN IS OK

  const [filter, setFilter] = React.useState<FilterBarSelectionReportingObjectiveSales>(filterInit);

  const { validateMenuActions } = useMenuAction(MENU.REPORTING_OBJECT_SALES);

  const {
    statisticsProductsCategory,
    dataFormatPayload,
    statisticsQuantityCategory,
    statisticsProductCanalWithTotal,
    dataFormatPayloadCanal,
    statisticsReportingObjectiveSaleCanal,
    recall,
    working: workingData,
    statisticsProductsMonetaryAllTotal
  } = useReportingObjectiveSaleCategoryLogic({ filterInit, filter });
  const [stateRefresh, setState] = React.useState(false);

  const delay = useMemo(() => (stateRefresh ? 60000 : null), [stateRefresh]);

  const refresh = useCallback(() => {
    recall?.(filter);
  }, [recall, filter]);

  const { time, resetTime } = useInterval(refresh, delay);

  const OnChangeApplyFilters = useCallback(() => {
    if (stateRefresh) {
      resetTime();
    } else {
      refresh();
    }
  }, [refresh, stateRefresh, resetTime]);

  const title = useBreadcrumbs('Sales by goals');
  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'dispatchDetailAddOrder',
        onClick: OnChangeApplyFilters,
        title: `${t('Refresh')} ${stateRefresh ? `(${time} ${t('Seconds').toLowerCase()})` : ''}`,
        disabled: workingData,
        loading: stateRefresh ? workingData : false,
        show: true
      },
      {
        id: 'updatedAddOrder',
        onClick: () =>
          history.push({
            pathname: `${match.path}/setting`,
            state: {
              goBack: title
            }
          }),
        title: t('Settings'),
        variant: 'contained',
        show: validateMenuActions(MENU_ACTIONS.SETTING.toString())
      }
    ],
    [time, OnChangeApplyFilters, workingData, stateRefresh, t, match.path, history, title, validateMenuActions]
  );

  const allQuantityTotal = useMemo(() => {
    return [
      {
        name: 'Monetary',
        data: [
          {
            title: 'Total reached of the goal',
            value: format(statisticsProductsMonetaryAllTotal.reached, 'Currency', t),
            icon: <AttachMoneyIcon style={{ fontSize: 50 }} color="primary" />
          },
          {
            title: 'Percentage of goal reached',
            value: `${statisticsProductsMonetaryAllTotal.objective > 0 ? ((statisticsProductsMonetaryAllTotal.reached * 100) / statisticsProductsMonetaryAllTotal.objective).toFixed(2) : 0}%`
          },
          {
            title: 'Total goal',
            value: format(statisticsProductsMonetaryAllTotal.objective, 'Currency', t),
            icon: <AttachMoneyIcon style={{ fontSize: 50 }} color="primary" />
          }
        ]
      },
      {
        name: 'Quantity',
        data: [
          {
            title: 'Total reached of the goal',
            value: format(statisticsProductCanalWithTotal.reached, 'Integer', t)
          },
          {
            title: 'Percentage of goal reached',
            value: `${statisticsProductCanalWithTotal.objective > 0 ? ((statisticsProductCanalWithTotal.reached * 100) / statisticsProductCanalWithTotal.objective).toFixed(2) : 0}%`
          },
          {
            title: 'Total goal',
            value: format(statisticsProductCanalWithTotal.objective, 'Integer', t)
          }
        ]
      }
    ];
  }, [t, statisticsProductCanalWithTotal, statisticsProductsMonetaryAllTotal]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.checked);
  };

  return (
    <ScreenAligned
      title={title}
      additionalTitle={
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Switch checked={stateRefresh} onChange={handleChange} name="checkedA" color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />
          </Box>
          {buttons
            ?.filter((dataButton: ButtonDetailsType) => !!dataButton.show)
            .map((dataButton: ButtonDetailsType) => (
              <ButtonComponent
                key={dataButton.id}
                title={dataButton.title}
                className={dataButton.className}
                variant={dataButton.variant}
                color={dataButton.color}
                onClick={dataButton.onClick}
                type={dataButton.type}
                disabled={dataButton.disabled}
                loading={dataButton.loading}
              />
            ))}
        </>
      }
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <SearchFilters initFetch={filter} onFilterChange={setFilter} />

        {allQuantityTotal.map((quantityTotal) => (
          <Box my={2} display="flex" width="100%" flexDirection="column">
            <Grid container xs={12} justify="space-between" direction="column">
              <Grid item xs={12}>
                <Typography variant="h5">{t(quantityTotal.name)}</Typography>
              </Grid>
              <Grid container xs={12} justify="space-between">
                {quantityTotal.data.map((dataTotal) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Box my={isMd ? 2 : 1} mx={isMd ? 2 : 1}>
                      <Card>
                        <Box display="flex" flexDirection="row" p={isMd ? 3 : 1} bgcolor="#fff1f4" alignItems="center">
                          {dataTotal.icon && (
                            <Box borderRadius="50%" borderColor="primary.main" border={1} mr={isMd ? 2 : 1}>
                              {dataTotal.icon}
                            </Box>
                          )}
                          <Box display="flex" flexDirection="column">
                            <Box height="100%" width="100%" mb={1}>
                              <Typography variant={isMd ? 'subtitle2' : 'body2'} color="primary">
                                {t(dataTotal.title).toUpperCase()}
                              </Typography>
                            </Box>
                            <Box height="100%" width="100%">
                              <Typography variant={isMd ? 'h2' : 'h4'} color="primary">
                                {dataTotal.value}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        ))}

        <Grid container xs={12} spacing={isSm ? 4 : 2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box bgcolor="white" p={isXs ? 2 : 4} height="100%" justifyContent="center">
              <Box height="10%">
                <Typography variant="h5">{t('Goal by product')}</Typography>
              </Box>
              <Box display="flex" height="90%" justifyContent="center" alignItems="center" py={2}>
                <BarChartComponent
                  working={workingData}
                  dataChart={orderBy(statisticsProductsCategory.slice(0, 10), ['objective', 'reached'], ['desc'])}
                  bar={['objective', 'reached']}
                  dataKeyXAxis="reached"
                  dataFormatPayload={dataFormatPayload}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box bgcolor="white" p={isXs ? 2 : 4} height="100%" justifyContent="center">
              <Box height="10%">
                <Typography variant="h5">{t('Goal by marketplace')}</Typography>
              </Box>
              <Box display="flex" height="90%" justifyContent="center" alignItems="center" py={2}>
                <BarChartComponent
                  working={workingData}
                  dataChart={orderBy(statisticsReportingObjectiveSaleCanal.slice(0, 10), ['objective', 'reached'], ['desc'])}
                  bar={['objective', 'reached']}
                  dataKeyXAxis="reached"
                  dataFormatPayload={dataFormatPayloadCanal}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box bgcolor="white" p={isXs ? 2 : 4} height="100%" justifyContent="center">
              <Box height="10%">
                <Typography variant="h5">{t('Goals by products units')}</Typography>
              </Box>
              <Box display="flex" height="90%" justifyContent="center" alignItems="center" py={2}>
                <BarChartComponent working={workingData} dataChart={orderBy(statisticsQuantityCategory.slice(0, 10), ['objective', 'reached'], ['desc'])} bar={['objective', 'reached']} dataKeyXAxis="reached" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </ScreenAligned>
  );
};
