import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { PaginatedTable } from '../../../../../components/PaginatedTable';
import { useModalFormStyles } from '../../../../../theme';
import { useTranslation } from '../../../../../services/translation';
import { Product } from '../../../../../interfaces/business';
import { RowsType } from '../../../types';

interface ProductSelectionProps {
  products: RowsType[];
  open?: boolean;
  customTheme?: any;
  onRowClick?: (product: Product) => void;
  onCancel: () => void;
}

export const ProductSelection = ({ products = [], open = false, customTheme = null, onRowClick = () => {}, onCancel = () => {} }: ProductSelectionProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();

  const onAddProduct = (row: any) => {
    onRowClick(row as Product);
    onCancel();
  };

  const columns = [
    {
      id: 'sku',
      label: 'SKU'
    },
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'price',
      label: 'Price'
    },
    {
      id: 'company',
      label: 'Company'
    },
    {
      id: 'actions',
      label: 'Add',
      render: (row: any) => {
        return (
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              onAddProduct(row);
            }}
            color="primary"
          >
            {t('Add')}
          </Button>
        );
      }
    }
  ];

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title" maxWidth={false}>
      <DialogContent className={styles.title}>
        <Typography variant="h3">Select Product</Typography>
      </DialogContent>
      <DialogContent className={styles.content}>
        <PaginatedTable onRowClick={onAddProduct} headCells={columns} rows={products} rowIdKey="sku" />
      </DialogContent>
      <DialogActions />
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
