import React, { useCallback, useMemo, MouseEvent } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../../../../../services/translation';
import { useBreadcrumbs, useFilerParamsId } from '../../../../../../hooks';
import { Non_working_day } from '../../../../../../interfaces/business';
import { RowsType } from '../../../../../../utils/tables';
import CustomModal from '../../../../../../services/customFormDialog';
import { formReportingLogisticNonWorkingDayInitialValues, validateReportingLogisticSlaAdd } from '../utils/modelReportingLogisticNonWorkingDayAdd';
import { MenuActions, MenuItemComponentType } from '../../../../../../components/MenuActions';
import ConfirmDialog from '../../../../../../components/ConfirmDialog';
import { AddNonWorkingDayForm, CreateNonWorkingDayType } from '../components/AddNonWorkingDayForm';
import { ButtonDetailsType } from '../../../../../../types/Button';
import { TitleBreadcrumbs } from '../../../../../../interfaces';
import { useNonWorkingDayLibbyFetch } from '../../../../../../business/public/NonWorkingDay';
import { useNonWorkingDayUpdateDAO } from '../../../../../../business/public/NonWorkingDayUpdate';

const AddNonWorkingDayModal = CustomModal(AddNonWorkingDayForm);

const ConfirmModal = CustomModal(ConfirmDialog);
export const useReportingLogisticSlaLogic = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'day_date',
    daoName: 'ster_non_working_day'
  });

  const { data: allData, fetchMore, removeData, working, addCreate, updateData } = useNonWorkingDayLibbyFetch(paramsFetch);

  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();

  const title = useBreadcrumbs('Non-working days');
  const titleShow = useBreadcrumbs('Non-working days', state && state?.goBack?.length ? state.goBack : title);
  const nonWorkingDayUpdate = useNonWorkingDayUpdateDAO();

  const handleOnOpenAddNonWorkingDay = useCallback(
    async (nonWorkingDay?: Non_working_day) => {
      try {
        const nonWorkingDayDataForm = (await AddNonWorkingDayModal.show({
          confirmText: t(nonWorkingDay ? 'Update' : 'Add'),
          cancelText: t('Cancel'),
          title: t(!nonWorkingDay ? 'Add non-working day' : 'Update non-working day'),
          validate: validateReportingLogisticSlaAdd,
          formInitialValues: nonWorkingDay || formReportingLogisticNonWorkingDayInitialValues
        })) as CreateNonWorkingDayType;

        if (nonWorkingDayDataForm) {
          const { day_date, description } = nonWorkingDayDataForm;
          const newNonWorkingDay = await nonWorkingDayUpdate.save({
            ...nonWorkingDay,
            day_date,
            description
          });
          const message = nonWorkingDay ? 'modified' : 'aggregated';
          if (!nonWorkingDay) {
            addCreate(newNonWorkingDay);
          } else {
            updateData(newNonWorkingDay, 'non_working_day_id');
          }
          enqueueSnackbar(`${t(`Non-working day $$$$ successfully ${message}`).replace('$$$$', newNonWorkingDay.non_working_day_id)}`, { variant: 'success' });
        }
      } catch (error) {
        // nothing
      }
    },
    [t, nonWorkingDayUpdate, updateData, enqueueSnackbar, addCreate]
  );

  const handleCancelModal = useCallback(
    async (nonWorkingDay: Non_working_day) => {
      try {
        const cancel = await ConfirmModal.show({
          title: t('Delete non-working day'),
          content: `${t('You are about to delete $$$$ non-working day, do you want to continue?')}`.replace('$$$$', String(nonWorkingDay.day_date)),
          confirmText: t('Confirm'),
          cancelText: t('Cancel')
        });
        if (cancel) {
          await nonWorkingDayUpdate.remove({
            ...nonWorkingDay
          });
          removeData(nonWorkingDay.non_working_day_id, 'non_working_day_id');
          enqueueSnackbar(t('Non-working day deleted'), { variant: 'success' });
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`${t('Failed to delete')}: ${error}`, {
            variant: 'info'
          });
        }
      }
    },
    [enqueueSnackbar, nonWorkingDayUpdate, removeData, t]
  );

  const menuArray = useCallback(
    (marketplaceSla: Non_working_day): MenuItemComponentType[] => {
      const menu: MenuItemComponentType[] = [
        {
          title: 'Edit',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleOnOpenAddNonWorkingDay(marketplaceSla);
          },
          disabled: false
        },
        {
          title: 'Delete',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleCancelModal(marketplaceSla);
          },
          disabled: false,
          color: 'error'
        }
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [handleOnOpenAddNonWorkingDay, handleCancelModal]
  );

  const rows: RowsType[] = useMemo(() => {
    const dataOrder = allData as Non_working_day[];
    return dataOrder.map((nonWorkingDay: Non_working_day) => {
      const { non_working_day_id } = nonWorkingDay;
      return {
        id: non_working_day_id,
        ...nonWorkingDay,
        actions: <MenuActions menuArray={menuArray(nonWorkingDay)} />
      };
    });
  }, [allData, menuArray]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'add-non-working-day',
        onClick: () => handleOnOpenAddNonWorkingDay(),
        title: t('Add non-working day'),
        variant: 'contained'
      }
    ],
    [handleOnOpenAddNonWorkingDay, t]
  );

  return {
    working,
    direction,
    orderBy,
    handleRequestSort,
    setFilter,
    filter,
    rows,
    fetchMore,
    buttons,
    titleShow,
    handleCancelModal
  };
};
