import React, { ReactNode } from 'react';
import Container, { ContainerTypeMap } from '@material-ui/core/Container';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Footer } from '../Footer';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column'
  },
  defaultClass: {
    paddingTop: 20,
    paddingRight: 45,
    paddingLeft: 45
  },
  [theme.breakpoints.down('xs')]: {
    defaultClass: {
      paddingTop: 20,
      paddingLeft: 12,
      paddingRight: 12
    }
  }
}));

type ScreenProps = {
  children: NonNullable<ReactNode>;
  maxWidth?: ContainerTypeMap['props']['maxWidth'];
  className?: string;
  hideFooter?: boolean;
};

export const Screen = ({ children, maxWidth = 'xl', className = '', hideFooter = false }: ScreenProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Container maxWidth={maxWidth} className={cx(className, classes.defaultClass)}>
        {children}
      </Container>
      {!hideFooter && <Footer />}
    </div>
  );
};
