import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, TextField } from '@material-ui/core';
import { AnyObject } from '../../../types';
import { useTranslation } from '../../../services/translation';
import { makeFilter } from '../utils/MakeFilter';
import { FilterBar } from '../../../components/FilterBar';
import { gridBreakpoints, gridBreakpointsFields } from '../../../const';
import { useInitFilterDebounced } from '../../OrderReport/routes/OrderReportList/hook';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: AnyObject;
  resetFilter: () => void;
};

const SearchFiltersRaw = ({ onFilterChange, filter, resetFilter }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const { search: sku, handleChange: handleChangeSku } = useInitFilterDebounced({
    initFetch: filter.sku,
    init: '',
    onFilterChange,
    id: 'sku',
    makeFilter
  });

  const { search, handleChange } = useInitFilterDebounced({
    initFetch: filter.search,
    init: '',
    onFilterChange,
    id: 'search',
    makeFilter
  });

  const { search: name, handleChange: handleChangeName } = useInitFilterDebounced({
    initFetch: filter.name,
    init: '',
    onFilterChange,
    id: 'name',
    makeFilter
  });

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
      handleReset={resetFilter}
    >
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="sku"
          label={t('Sku')}
          type="search"
          value={sku}
          onChange={handleChangeSku}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="name"
          label={t('Product Name')}
          type="search"
          value={name}
          onChange={handleChangeName}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
