import React, { useCallback, useEffect, useState } from 'react';
import { FormConfig } from 'react-final-form-hooks';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { Box, Button, DialogActions } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { FileInput } from '../../../../Imports/routes/ImportDetail/components/FileInput';
import { useTranslation } from '../../../../../services/translation';

const useStyles = makeStyles(() => ({
  content: {
    '& > div:nth-child(n+1)': {
      marginTop: '15px'
    }
  },
  title: {
    overflow: 'visible'
  }
}));

interface ZipFormDialogType extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  title: string;
  data: any;
  customTheme: string | null;
  onConfirm: (files: any) => void;
  cancelText: string;
  confirmText: string;
}

export const UpdatedStateWithReferForm = ({ open = false, onCancel = () => {}, title = 'Confirmation Dialog', customTheme = null, data, onConfirm, cancelText = 'No', confirmText = 'Yes' }: ZipFormDialogType) => {
  const styles = useStyles();

  const { t } = useTranslation();

  const [files, setFiles] = useState<any>([]);

  const handleUpdateTempAttachments = useCallback(
    (filename: string, base64Content: string, originalFilename: string, extension: string, delimiter?: string, id?: string) => {
      setFiles((Allfile: any) => {
        const copyFiles = [...Allfile];
        const nameNewFile = copyFiles.findIndex((copyFile) => copyFile.filename === filename);
        if (nameNewFile === -1) {
          copyFiles.push({
            filename,
            base64Content,
            originalFilename,
            extension,
            delimiter,
            id
          });
        }

        return copyFiles;
      });
    },
    [setFiles]
  );
  const handleDeleteImport = useCallback((name: string) => {
    setFiles((Allfile: any) => {
      const copyFiles = [...Allfile];
      const nameNewFile = copyFiles.findIndex((copyFile) => copyFile.filename === name);
      if (nameNewFile > -1) {
        copyFiles.splice(nameNewFile, 1);
      }
      return copyFiles;
    });
  }, []);

  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      onConfirm({
        files
      });
    },
    [onConfirm, files]
  );

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <DialogContent>
        <form noValidate onSubmit={onSubmit}>
          {data?.length &&
            data.map(({ order_id }: any) => {
              const newFileName = `receipt_order_${order_id}`;
              const rest = files?.find((file: any) => file.filename === newFileName);
              const originalFilename = rest?.originalFilename || undefined;
              return (
                <Box mb={2}>
                  <Typography variant="h5">{`${t('Receipt of the order')} (${order_id})`}</Typography>
                  <FileInput key={order_id} id={order_id} onUpdateFiles={handleUpdateTempAttachments} onDeleteImport={handleDeleteImport} filename={newFileName} {...rest} url={originalFilename} base64={originalFilename} />
                </Box>
              );
            })}
          <DialogActions>
            <Button onClick={onCancel}>{cancelText}</Button>
            <Button color="primary" type="submit">
              {t(confirmText)}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );

  return !customTheme ? (
    dialog
  ) : (
    <ThemeProvider theme={customTheme}>
      <SnackbarProvider maxSnack={3}>{dialog}</SnackbarProvider>
    </ThemeProvider>
  );
};
