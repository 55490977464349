import React, { useCallback } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useBreadcrumbs, useFilerParamsId } from '../../hooks';
import { OrdersList } from '../Orders/routes/OrdersList';
import { LibbyObject } from '../../types/types';
import { useOrderTableLogic } from '../Orders/routes/OrdersList/hook/useOrderTableLogic';
import { makeFilter } from '../Orders/routes/OrdersList/utils/makeFilter';
import { OrderStateLogs } from '../Orders/routes/OrderStateLogs';
import { OrderDetail } from '../Orders/routes/OrderDetail';
import { ORDER_SOURCE_CANAL_OF_LINE_ENABLED } from '../../const/canalOffLine';

const filterInit = makeFilter({ canalOffLine: ORDER_SOURCE_CANAL_OF_LINE_ENABLED });

export const CanalOfflineRouter = ({ libby }: LibbyObject) => {
  const match = useRouteMatch();
  const title = useBreadcrumbs('Canal Off Line');
  const history = useHistory();

  const detailsRedirection = useCallback((order_id: string) => history.push(`orders/detail/${order_id}`), [history]);

  const { filter, orderBy, direction, paramsFetch, setFilter, handleRequestSort, resetFilter } = useFilerParamsId({
    orderInit: 'order_id',
    daoName: 'ster_order_so_with_delivery_date',
    aspect: 'list_order_so_order',
    init: filterInit
  });

  const { rows, columns, working, fetchMore, allButtonsActions } = useOrderTableLogic({
    libby,
    paramsFetch,
    detailsRedirection,
    columnCheckEnabled: true,
    actions: false,
    path: match.path,
    deliveryDate: true
  });

  return (
    <Switch>
      <Route path={`${match.path}/logs/:id`}>
        <OrderStateLogs title={title} />
      </Route>
      <Route path={`${match.path}/detail/:id`}>
        <OrderDetail title={title} />
      </Route>
      <Route path={`${match.path}`}>
        <OrdersList
          canalOffLine
          title={title}
          detailUrl={`${match.path}/detail`}
          setFilter={setFilter}
          rows={rows}
          columns={columns}
          working={working}
          filter={filter}
          fetchMore={fetchMore}
          onSortChange={handleRequestSort}
          orderBy={orderBy}
          direction={direction}
          allButtonsActions={allButtonsActions}
          resetFilter={resetFilter}
        />
      </Route>
    </Switch>
  );
};

export const CanalOffline = DatabaseConnector(CanalOfflineRouter)('ster_order', 'ster_order_table', 'ster_order_cancelled', 'ster_order_document_generate', 'ster_order_so_with_delivery_date');
