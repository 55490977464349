import { useForm } from 'react-final-form-hooks';
import { useCallback } from 'react';
import { FORM_ERROR } from 'final-form';
import { useSnackbar } from 'notistack';
import { makeValidator, ValidatorType } from '../../../utils/validator';
import { useTranslation } from '../../../services/translation';
import { LibbyObject } from '../../../types/types';
import { API_URL } from '../../../config';

const validate = makeValidator([
  { key: 'username', validators: [ValidatorType.REQUIRED] },
  { key: 'password', validators: [ValidatorType.REQUIRED] }
]);
export const useLoginForm = (libby: LibbyObject) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const onSubmit = useCallback(
    async ({ username, password }) => {
      try {
        const isServerUp = await fetch(`${API_URL}/platform/status`, { method: 'POST' });
        if (isServerUp.status === 200) {
          const userNameLower = username.toLowerCase();

          const result = await libby.session.login(username, password, { username: userNameLower, password });
          if (!result) {
            enqueueSnackbar(t('Invalid User'), { variant: 'error' });
          }
        } else {
          const message = 'we are having some issues';
          enqueueSnackbar(t(message), { variant: 'error' });
        }
        return {};
      } catch (e) {
        const message = 'we are having some issues';
        enqueueSnackbar(t(message), { variant: 'error' });
        return { [FORM_ERROR]: message };
      }
    },
    [t, enqueueSnackbar, libby]
  );
  return useForm({
    initialValues: { email: '', password: '' },
    onSubmit,
    validate
  });
};
