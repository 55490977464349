import React from 'react';
import Link from '@material-ui/core/Link';
import { formatDate } from '../../utils';
import { AnyObject } from '../../types/types';
import { PaginatedTable } from '../../components/PaginatedTable';

export const documentsColumns = [
  {
    id: 'documentNumber',
    label: 'Number'
  },
  {
    id: 'source',
    label: 'Source',
    render: (row: any, item: any, t: (str: string) => string) => t(row.source.name)
  },
  {
    id: 'type',
    label: 'Type',
    render: (row: any, item: any, t: (str: string) => string) => t(row.type.name)
  },
  {
    id: 'createdAt',
    label: 'Creation Date',
    render: (row: any) => formatDate(row.createdAt, true, false)
  },
  {
    id: 'url',
    label: 'Link',
    render: (row: any, item: any, t: (str: string) => string) => (
      <Link target="_blank" href={row.url}>
        {t('Go to file')}
      </Link>
    )
  }
];

export const DocumentsTable = React.memo(({ documents = [], columns = documentsColumns }: AnyObject) => <PaginatedTable headCells={columns} rows={documents} rowIdKey="order_document_id" />);
