import React, { useCallback, useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AnyObject } from '../../types/types';
import { GeneralSelect } from '../GeneralSelect';
import { useTranslation } from '../../services/translation';
import { useGoalTypeLibbyCall } from '../../business/goal';
import { GoalType } from '../../interfaces/business';
import { DataGenerateSelectType } from '../../utils/generateSelect';

type SelectGoalTypeProps = {
  multiple?: boolean;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label?: string;
};

export const SelectGoalType = ({ multiple = false, inputProps = {}, optionAll = false, disabled = false, sort, label, ...styles }: SelectGoalTypeProps) => {
  const { t } = useTranslation();
  const { data: dataAll, working } = useGoalTypeLibbyCall({
    methodName: 'getAllGoalType'
  });

  const data = useMemo(() => {
    const dataGoal = dataAll as GoalType[];

    return dataGoal?.length
      ? dataGoal.map(({ goal_type_id, name }: GoalType) => ({
          id: goal_type_id,
          value: t(name)
        }))
      : [];
  }, [dataAll, t]);

  const onChangeAutoComplete = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((marketplace: DataGenerateSelectType) => ({
          value: marketplace.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          renderInput={(params) => <TextField {...params} variant="standard" label={t(`All types goal`)} placeholder="" />}
        />
      ) : (
        <GeneralSelect disabled={disabled} working={working} label="Goal type" optionAll={optionAll} data={data} inputProps={inputProps} {...styles} sort={sort} />
      )}
    </>
  );
};
