import { MARKETPLACE_ML_MP } from '../../../const';
import { Filter } from '../../../types';

type MakeFilterProps = {
  billing_period?: string;
  marketplace_id?: string;
};

export const makeFilter = ({ billing_period, marketplace_id }: MakeFilterProps) => {
  const filter: Filter = {};

  if (marketplace_id) {
    const marketplace_type_id = MARKETPLACE_ML_MP.find((marketplace) => marketplace.marketplace_id === marketplace_id)?.marketplace_type;
    filter.marketplace_id = [
      {
        path: 'marketplace_id',
        value: marketplace_id
      }
    ];
    filter.marketplace_type_id = [
      {
        path: 'marketplace_type_id',
        value: marketplace_type_id || ''
      }
    ];
  }

  if (billing_period) {
    filter.billing_period = [
      {
        path: 'billing_period',
        value: billing_period
      }
    ];
  }

  return filter;
};
