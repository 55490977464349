import React, { useMemo, useCallback } from 'react';
import { Box, Collapse, Grid, IconButton, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AnyObject, LibbyObject } from '../../../../types/types';
import { useTranslation } from '../../../../services/translation';
import { useLibbyFetch } from '../../../../hooks';
import { SelectOrderCourier } from '../../../components/SelectOrderCourier';
import { SelectCompany } from '../../../../components/SelectCompany';
import { SelectAllMarketplaceCompany } from '../../../../components/SelectAllMarketplaceCompany';
import { SelectOrderSourceType } from '../../../../components/SelectOrderSourceType';
import { DataGenerateAutocompleteType } from '../../../../utils/generateAutocomplete';

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      margin: '5px',
      width: '25ch'
    }
  },
  title: {
    verticalAlign: 'baseline'
  },
  head_filter: {
    fontWeight: 600
  },
  date: {
    marginBottom: '0px'
  },
  buttonFilters: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: '4px'
  }
}));

type Courier = {
  id: string;
  value: string;
};

export type FilterBarSelection = {
  filter_id?: string;
  marketplaces: any[];
  company?: DataGenerateAutocompleteType[];
  from?: Date;
  to?: Date;
  courier: Courier[];
  canal?: DataGenerateAutocompleteType[];
};

export type FilterBarProps = {
  libby: LibbyObject;
  onFilter: (filter: any) => void;
  initExpanded?: boolean;
  filter: FilterBarSelection;
  canalEnable?: boolean;
  companyEnable?: boolean;
  working: boolean;
};

function FilterBar({ libby, onFilter, initExpanded = false, filter, canalEnable = false, companyEnable = false, working = false }: FilterBarProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(initExpanded);

  const { t } = useTranslation();
  // FIXME POR EL AMOR DE JEBBBBBBUS
  const libbiFilter = useMemo<AnyObject>(() => ({}), []);
  const { data } = useLibbyFetch(libby, {
    daoName: 'ster_marketplace',
    filter: libbiFilter,
    direction: 'asc',
    orderBy: 'name'
  });
  // FIXME WE MUST COMPONENTIZE THE FIELDS TO AVOID SO MANY TAGS ON A SIMPLE FORM
  // FIXME THE AUTOCOMPLETE SHOULD TO HAVE THE FRAME AND ALSO THE CHIPS HAVE TO BE OF A DIFFERENT COLOR
  // FIXME WE SHOULD CHANGE THE ONCHANGES METHODS WITH USECALLBACK TO AVOID RE-RENDERS
  // FIXME DATEPICKER IS NO CLOSING ON SELECTION

  const handleChangeCanals = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: DataGenerateAutocompleteType[]) => {
      const newFilter: FilterBarSelection = {
        ...filter,
        canal: value
      };
      onFilter(newFilter);
    },
    [filter, onFilter]
  );

  const handleChangeCompany = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: DataGenerateAutocompleteType[]) => {
      const newFilter: FilterBarSelection = {
        ...filter,
        company: value,
        marketplaces: []
      };

      onFilter(newFilter);
    },
    [filter, onFilter]
  );
  return (
    <Grid container direction="row" justify="flex-end" alignItems="flex-end">
      <Box mb={2}>
        <Grid item>
          <IconButton onClick={() => setExpanded(!expanded)} className={classes.buttonFilters}>
            <Typography variant="h5" className={classes.title}>
              {t('filters')}
            </Typography>
            {expanded ? <ExpandLess /> : <ExpandMore />}{' '}
          </IconButton>
        </Grid>
      </Box>

      <Grid container direction="row" justify="flex-end" alignItems="flex-end" spacing={1}>
        <Grid item xs={12}>
          <Collapse in={expanded} timeout="auto">
            <Grid container direction="row" justify="space-between" alignItems="flex-end" spacing={2}>
              {companyEnable && (
                <Grid item md={3} sm={6} xs={12}>
                  <SelectCompany
                    multiple
                    disabled={working}
                    label="Companies"
                    inputProps={{
                      onChange: handleChangeCompany,
                      valueArray: filter.company
                    }}
                  />
                </Grid>
              )}

              <Grid item md={3} sm={6} xs={12}>
                <SelectAllMarketplaceCompany
                  disabled={working}
                  dataOptions={!filter?.company?.length && data}
                  inputProps={{
                    valueArray: filter.marketplaces,
                    onChange: (event: React.ChangeEvent<{}>, value: any) => {
                      const newFilter: FilterBarSelection = {
                        ...filter,
                        marketplaces: value
                      };
                      onFilter(newFilter);
                    }
                  }}
                  company_id={filter.company}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <SelectOrderCourier
                  multiple
                  disabled={working}
                  inputProps={{
                    value: filter.courier,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>, value: Courier[]) => {
                      const newFilter: FilterBarSelection = {
                        ...filter,
                        courier: value
                      };
                      onFilter(newFilter);
                    }
                  }}
                  optionAll
                />
              </Grid>
              {canalEnable && (
                <Grid item md={3} sm={6} xs={12}>
                  <SelectOrderSourceType
                    disabled={working}
                    multiple
                    label="Source types"
                    inputProps={{
                      valueArray: filter.canal,
                      onChange: handleChangeCanals
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container direction="row" alignItems="flex-end" spacing={2}>
              <Grid item md={3} sm={6} xs={6}>
                <KeyboardDatePicker
                  disabled={working}
                  className={classes.date}
                  fullWidth
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="normal"
                  id="date-picker-inline"
                  label={t('From')}
                  value={filter.from}
                  onChange={(value: MaterialUiPickersDate) => {
                    if (value) {
                      const newFilter: FilterBarSelection = {
                        ...filter,
                        from: value
                          .set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0
                          })
                          .toDate()
                      };
                      onFilter(newFilter);
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={6}>
                <KeyboardDatePicker
                  disabled={working}
                  fullWidth
                  className={classes.date}
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="normal"
                  id="date-picker-inline"
                  label={t('To')}
                  value={filter.to}
                  onChange={(value: MaterialUiPickersDate) => {
                    if (value) {
                      const newFilter: FilterBarSelection = {
                        ...filter,
                        to: value
                          .set({
                            hour: 23,
                            minute: 59,
                            second: 59,
                            millisecond: 999
                          })
                          .toDate()
                      };
                      onFilter(newFilter);
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );
}

const i = DatabaseConnector(FilterBar)('ster_marketplace');
export { i as FilterBar };
