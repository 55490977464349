import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, TextField } from '@material-ui/core';
import moment from 'moment';
import { FilterBar } from '../../../../../../components/FilterBar';
import { gridBreakpoints, gridBreakpointsFields } from '../../../../../../const/gridBreakpoints';
import { useInitFilterDebounced, useInitFilter, useInitFilterArray } from '../../../../../OrderReport/routes/OrderReportList/hook';
import { makeFilter } from '../utils/makeFilter';
import { Filter } from '../../../../../../types';
import { useTranslation } from '../../../../../../services/translation';
import { DateClose } from '../../../../../../components/DateClose';
import { SelectEffectiveness } from './SelectEffectiveness';
import { SelectOrderSourceType } from '../../../../../../components/SelectOrderSourceType';
import { SelectOrderCourier } from '../../../../../components/SelectOrderCourier';
import { DataGenerateAutocompleteType } from '../../../../../../utils/generateAutocomplete';
import { Courier } from '../../../../../../interfaces/business';
import { SelectAllMarketPlace } from '../../../../../components/AllMarketplace';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: Filter;
};

export type FilterBarSelection = {
  filter_id?: string;
  marketplaces: any[];
  company?: DataGenerateAutocompleteType[];
  from?: Date;
  to?: Date;
  courier: Courier[];
  canal?: DataGenerateAutocompleteType[];
};

const SearchFiltersRaw = ({ onFilterChange, filter }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const { search, handleChange } = useInitFilterDebounced({
    initFetch: filter.search,
    init: '',
    onFilterChange,
    id: 'search',
    makeFilter
  });

  const { search: startDate, handleChangeFilterString: setStartDate } = useInitFilter({
    initFetch: filter.startDate,
    onFilterChange,
    init: null,
    id: 'startDate',
    date: true,
    makeFilter
  });

  const { search: endDate, handleChangeFilterString: setEndDate } = useInitFilter({
    initFetch: filter.endDate,
    onFilterChange,
    id: 'endDate',
    date: true,
    init: null,
    makeFilter
  });
  const { search: effectiveness, handleChangeFilter: handleChangeEffectiveness } = useInitFilter({
    initFetch: filter.effectiveness,
    init: '',
    onFilterChange,
    id: 'effectiveness',
    makeFilter
  });

  const { search: marketplace, handleChangeFilter: setMarketplace } = useInitFilterArray({
    initFetch: filter.marketplace,
    onFilterChange,
    id: 'marketplace',
    makeFilter
  });

  const { search: canal, handleChangeFilter: setCanal } = useInitFilterArray({
    initFetch: filter.canal,
    onFilterChange,
    id: 'canal',
    makeFilter
  });

  const { search: courier, handleChangeFilter: setCourier } = useInitFilterArray({
    initFetch: filter.courier,
    onFilterChange,
    id: 'courier',
    makeFilter
  });
  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
    >
      <Grid item {...gridBreakpointsFields}>
        <SelectAllMarketPlace multiple onChange={setMarketplace} allMarketplace={marketplace} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectOrderCourier
          multiple
          inputProps={{
            value: courier,
            onChange: setCourier
          }}
          optionAll
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectOrderSourceType
          multiple
          label="Source types"
          inputProps={{
            valueArray: canal,
            onChange: setCanal
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectEffectiveness inputProps={{ value: effectiveness, onChange: handleChangeEffectiveness }} optionAll />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="Start Date" value={moment.isMoment(startDate) ? startDate : null} onChange={setStartDate} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="End Date" value={moment.isMoment(endDate) ? endDate : null} minValue={moment.isMoment(startDate) ? startDate : null} onChange={setEndDate} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
