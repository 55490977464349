import React, { useCallback, useMemo, useState } from 'react';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { ImportDetail } from './routes/ImportDetail';
import { ImportsList } from './routes/ImportsList';
import { LibbyObject } from '../../types';
import { makeFilter } from './routes/ImportsList/utils/makeFilter';
import { useLibbyFetch, useBreadcrumbs } from '../../hooks';
import { ImportationsTargets } from '../../const';

const limit = 10;
export const ImportsRouter = ({ libby }: LibbyObject) => {
  const match = useRouteMatch();
  const { importationTargetId } = useParams();

  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('importation_id');

  const handleRequestSort = useCallback((newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  }, []);

  const [filter, setFilter] = useState(
    makeFilter({
      startDate: null,
      endDate: null
    })
  );

  const importationTarget = useMemo(() => {
    let target = [{ path: 'importation_type.importation_target.importation_target_id', value: importationTargetId }];
    if (importationTargetId === '1') {
      const targetNewImportationType = [
        {
          path: 'importation_type.importation_target.importation_target_id',
          value: ImportationsTargets.INBOX_ORDER_COLLECTED
        },
        {
          path: 'importation_type.importation_target.importation_target_id',
          value: ImportationsTargets.INBOX_ORDER_CANCELLED
        }
      ];
      target = [...target, ...targetNewImportationType];
    }
    return target;
  }, [importationTargetId]);

  const paramsFetch = useMemo(
    () => ({
      daoName: 'app_importation',
      limit,
      filter: { ...filter, importation_target: importationTarget },
      direction,
      orderBy,
      aspect: 'list_importation'
    }),
    [filter, direction, orderBy, importationTarget]
  );

  const { data, working, fetchMore, addCreate } = useLibbyFetch(libby, paramsFetch);

  const onChangeFilter = (value: object) => {
    if (JSON.stringify(filter) !== JSON.stringify(value)) {
      setFilter(value);
    }
  };

  const title = useBreadcrumbs('Massive Uploads');
  return (
    <Switch>
      <Route path={`${match.url}/detail/:id`}>
        <ImportDetail title={title} />
      </Route>
      <Route path={`${match.url}`}>
        <ImportsList
          title={title}
          data={data}
          working={working}
          filter={filter}
          setFilter={onChangeFilter}
          fetchMore={fetchMore}
          addCreate={addCreate}
          direction={direction}
          orderBy={orderBy}
          onSortChange={handleRequestSort}
          importationTargetId={importationTargetId}
        />
      </Route>
    </Switch>
  );
};
export const Imports = DatabaseConnector(ImportsRouter)('app_importation');
