import React, { useMemo, useState } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Box, Grid, Tooltip, Typography, useMediaQuery, Theme } from '@material-ui/core';
import moment from 'moment';
import { orderBy } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { ReportingSalesListProps } from '../../../../ReportingSales/routes';
import { FilterBarSelection } from '../../../../Reporting/routes/ReportingList/FilterBar';
import { endDateFormat, startDateFormat } from '../../../../../utils';
import { useLibbyCall } from '../../../../../hooks';
import InfoTable, { Column } from '../../../../components/InfoTable';
import { format } from '../../../../../util';
import { MarketplaceReportingType, TotalMarketplaceReportingType } from '../../../../ReportingSales/routes/ReportingSalesList/type';

export const useStyles = makeStyles({
  bold: {
    fontWeight: 600,
    color: 'black'
  }
});

type TypeColumn = {
  name: string;
  total: number;
  count: number;
  id: string;
};
type AggregateRow = {
  [k: string]: any;
};

const filterInit: FilterBarSelection = {
  marketplaces: [],
  courier: [],
  from: startDateFormat(moment()).toDate(),
  to: endDateFormat(moment().add('1', 'days')).toDate(),
  canal: [],
  company: []
};

const emptyName = ['Discounts', 'Shipping cost', 'Sub total'];
const textBold = [...emptyName, 'Total'];

const BudgetProductList = ({ libby, daoName = 'ster_order_reporting_sales', columnsProductAux, vatEnabled = false }: ReportingSalesListProps) => {
  const classes = useStyles();
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [directionProduct, setDirectionProduct] = useState<'asc' | 'desc'>('desc');
  const { data, working } = useLibbyCall(libby, {
    daoName,
    methodName: 'getByDate',
    params: [filterInit]
  });
  const workingData = useMemo(() => (typeof working === 'boolean' && data?.marketplaces ? working : true), [working, data]);

  const columnsProducts: Array<Column> = [
    {
      id: 'name',
      label: 'Products',
      style: {
        whiteSpace: 'nowrap'
      },
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (
        <Tooltip title={`${tr(value.name)}`}>
          <Box maxWidth={isMd ? 400 : 100} minWidth={100}>
            <Typography noWrap variant="body1" color="textSecondary" align={textBold.includes(value.name) ? 'right' : 'left'} className={textBold.includes(value.name) ? classes.bold : ''}>
              {tr(value.name)}
            </Typography>
          </Box>
        </Tooltip>
      ),
      noSort: true
    },
    {
      id: 'count',
      label: 'Count',
      render: (value: TypeColumn) => {
        const text = emptyName.includes(value.name) ? '' : format(value.count, 'Integer');
        return textBold.includes(value.name) ? <b>{text}</b> : text;
      },
      align: 'right',
      noSort: true
    },
    {
      id: 'total',
      label: 'Total',
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (textBold.includes(value.name) ? <b>{format(value.total, 'Currency', tr)}</b> : format(value.total, 'Currency', tr)),
      align: 'right'
    }
  ];
  const dataReportingSales = useMemo(
    () =>
      data?.marketplaces
        ? data
        : {
            marketplaces: [],
            products: [],
            companies: [],
            source: []
          },
    [data]
  );
  const statisticsProducts: TypeColumn[] = useMemo(
    () =>
      dataReportingSales.products.reduce((result: any[], element: AggregateRow) => {
        if (element.namep) {
          result.push({
            count: parseInt(element.countofitems_name, 10),
            total: parseFloat(element.sumofamount),
            vat: element.sumvat ? parseFloat(element.sumvat).toFixed(2) : 0,
            name: element.namep
          });
        }
        return result;
      }, []),
    [dataReportingSales]
  );
  const withOrderProducts = useMemo(() => {
    if (!dataReportingSales.marketplaces.length) return [];
    const { subTotal, total, shipping_cost, vat } = dataReportingSales.marketplaces.reduce(
      (resultMarketplace: TotalMarketplaceReportingType, { sumsubamount, sumofamount, sumshipping_cost, sumvat }: MarketplaceReportingType) => {
        resultMarketplace.subTotal += parseFloat(sumsubamount);
        resultMarketplace.total += parseFloat(sumofamount);
        resultMarketplace.vat += sumvat ? parseFloat(sumvat) : 0;
        resultMarketplace.shipping_cost += parseFloat(sumshipping_cost);
        return resultMarketplace;
      },
      { subTotal: 0, total: 0, shipping_cost: 0, vat: 0 }
    );
    const newArray: Array<any> = [...statisticsProducts];
    newArray.push(
      newArray.reduce(
        (transport: AggregateRow, element: AggregateRow) => {
          transport.count += element.count;
          transport.total += element.total;
          transport.vat += element?.vat || 0;
          return transport;
        },
        { name: 'Sub total', count: 0, total: 0, vat: 0 }
      )
    );

    const all = newArray.pop();
    const rowsOrderBy = orderBy(newArray, ['total', 'count'], [directionProduct]);
    rowsOrderBy.push(all);
    const discounts = subTotal + shipping_cost - total;
    const totalWithDiscounts = all.total - discounts;
    const totalAll = vatEnabled ? totalWithDiscounts - vat : totalWithDiscounts;
    rowsOrderBy.push({ name: 'Discounts', total: `-${discounts}` });
    if (vatEnabled) rowsOrderBy.push({ name: 'Vat', total: `-${vat}` });
    rowsOrderBy.push({ name: 'Shipping cost', total: shipping_cost });
    rowsOrderBy.push({ name: 'Total', total: totalAll + shipping_cost, count: all.count });
    return rowsOrderBy;
  }, [statisticsProducts, directionProduct, dataReportingSales, vatEnabled]);

  const handleRequestProductSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setDirectionProduct(newDirection);
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Box my={2} display="flex" width="100%">
        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={4}>
          <InfoTable
            padding={false}
            columns={columnsProductAux || columnsProducts}
            rows={withOrderProducts}
            onBottomScroll={() => {}}
            onSortChange={handleRequestProductSort}
            direction={directionProduct}
            rowIdKey="name"
            height="auto"
            md={12}
            sm={12}
            xs={12}
            working={workingData}
          />
        </Grid>
      </Box>
    </Grid>
  );
};
// El dao a usar debe de heredar de LibbyFetchDAO para que funcione
export const BudgetProduct = DatabaseConnector(BudgetProductList)(
  'ster_order_table',
  'ster_order_so',
  'ster_order_table_update',
  'ster_order_history_marketplace',
  'ster_marketplace',
  'ster_order_reporting_sales',
  'ster_order_reporting_orders',
  'ster_order_reporting_orders_history_marketplace',
  'ster_order_reporting_sales_taxation'
);
