import { TextFieldProps } from '@material-ui/core';
import React from 'react';
import { GeneralSelect } from '../../../../../../components/GeneralSelect';

type SelectRoleProps = {
  inputProps?: TextFieldProps;
  optionAll?: boolean;
  disabled?: boolean;
};

const all_effectiveness = [
  {
    id: 1,
    name: 'In time'
  },
  {
    id: 2,
    name: 'Out of time'
  }
];

export const SelectEffectiveness = ({ inputProps = {}, optionAll = false, disabled = false }: SelectRoleProps) => {
  const data = all_effectiveness.map(({ id, name: value }: { id: number; name: string }) => ({
    id,
    value
  }));

  return <GeneralSelect working={false} label="Effectiveness" optionAll={optionAll} data={data} inputProps={inputProps} disabled={disabled} />;
};
