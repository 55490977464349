import { useCallback, useEffect, useRef, useState } from 'react';
import { useLibby } from '@phinxlab/libby-rest-web';

export interface LibbyCallOptions {
  daoName: string;
  methodName: string;
  params?: any[];
  noAutoCall?: boolean;
  aspect?: string;
}

export interface LibbyCallReturn<T> {
  data?: T;
  working: boolean;
  recall: (...args: any[]) => Promise<T>;
}

export const useLibbyCall = <T>({ daoName, methodName, params = [], noAutoCall = false, aspect }: LibbyCallOptions): LibbyCallReturn<T> => {
  const { libby } = useLibby([daoName]);
  const paramsRef = useRef(params);
  const [working, setWorking] = useState(false);
  const [data, setData] = useState<T>();

  const recall = useCallback(
    async (...newParams) => {
      if (!libby[daoName] || !libby[daoName][methodName]) {
        // eslint-disable-next-line
        console.error('Cannot found dao or method in dao');
        return null;
      }
      if (aspect) {
        libby[daoName].aspect(aspect);
      }
      const paramsToBeUsed = newParams.length ? newParams : paramsRef.current;
      setWorking(true);
      const result = await libby[daoName][methodName](...paramsToBeUsed);
      setData(result);
      setWorking(false);
      return result;
    },
    [daoName, libby, methodName, aspect]
  );
  useEffect(() => {
    if (!noAutoCall) {
      recall();
    }
  }, [noAutoCall, recall]);

  return { data, working, recall };
};
