import React, { ReactNode } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles, PropTypes } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { WrapperVariant } from '@material-ui/pickers/wrappers/Wrapper';

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      margin: '5px',
      width: '25ch'
    }
  },
  title: {
    verticalAlign: 'baseline'
  },
  head_filter: {
    fontWeight: 600
  },
  date: {
    marginBottom: '0px'
  },
  buttonFilters: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: '4px'
  }
}));

interface DateInputProps {
  working?: boolean;
  value: any;
  variant?: WrapperVariant;
  label: ReactNode;
  id?: string;
  disableToolbar?: boolean;
  format?: string;
  margin?: PropTypes.Margin;
  onChange: (value: MaterialUiPickersDate) => void;
}

export const DateInput = ({ working = false, value, variant = 'inline', label, id = 'date-piker', disableToolbar = false, format = 'DD/MM/YYYY', margin = 'normal', onChange }: DateInputProps) => {
  const classes = useStyles();
  return (
    <KeyboardDatePicker
      autoOk
      disabled={working}
      fullWidth
      className={classes.date}
      disableToolbar={disableToolbar}
      variant={variant}
      format={format}
      margin={margin}
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      KeyboardButtonProps={{
        'aria-label': 'change date'
      }}
      InputLabelProps={{
        shrink: true
      }}
    />
  );
};
