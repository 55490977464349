import { MARKETPLACE_ML_MP } from '../../../../const';
import { FilterBarSelectionReportingBillingMl } from '../../../../routes/ReportingBillingMl/type';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderReportBillingMlDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_reporting_billing_ml', 'order_id');
  }

  getAll() {
    return this.query().limit(0, 20000).run();
  }

  async getByPeriodMpReportingMl(filter: FilterBarSelectionReportingBillingMl) {
    let ls = this.query();
    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;
    if (filter?.billing_period) {
      const billing_period = filter?.billing_period?.[0].value as string;
      if (needsAnd) ls = ls.and();
      ls = ls.equals('billing_period', billing_period);
      needsAnd = true;
    }

    if (filter?.marketplace_id) {
      const marketplace_id = filter?.marketplace_id?.[0].value as string;
      if (needsAnd) ls = ls.and();
      const marketplace_type_id = MARKETPLACE_ML_MP.find((marketplace) => marketplace.marketplace_id === marketplace_id)?.marketplace_type;
      ls = ls.equals('marketplace_id', marketplace_id).and().equals('marketplace_type_id', marketplace_type_id);
      needsAnd = true;
    }

    return ls.run();
  }
}
