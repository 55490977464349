/* eslint-disable class-methods-use-this */
const manager = {
  getItem: (key: string) => localStorage.getItem(key),
  setItem: (key: string, value: string) => localStorage.setItem(key, value),
  removeItem: (key: string) => localStorage.removeItem(key)
};

export class TokenManager {
  async retrieve(): Promise<string | null | boolean> {
    try {
      const token = await manager.getItem('x-chino-token');
      return token;
      // return manager.getItem('x-chino-token');
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      return false;
    }
  }

  clean(): void | boolean {
    try {
      return manager.removeItem('x-chino-token');
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      return false;
    }
  }

  persist(token: string): void | boolean {
    try {
      return manager.setItem('x-chino-token', token);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      return false;
    }
  }

  static create(): TokenManager {
    return new TokenManager();
  }
}
