import { FilterBarSelection } from '../../routes/Reporting/routes/ReportingList/FilterBar';
import { ReportingSalesFilter } from './components/ReportingSales/ReportingSalesFilter';
import { makeFilterBar } from './generator/makeFilterBar';
import { ScreenComponent } from './types';

export enum SCREENS {
  REPORTING_SALES = 1
}

export const SCREEN_COMPONENT: { [k: number]: ScreenComponent<any> } = {
  [SCREENS.REPORTING_SALES]: { screen_id: String(SCREENS.REPORTING_SALES), render: ReportingSalesFilter }
};

export const FilterReportinSales = makeFilterBar<FilterBarSelection>(SCREEN_COMPONENT[SCREENS.REPORTING_SALES]);
