import React, { useMemo, useCallback, MouseEvent } from 'react';
import Delete from '@material-ui/icons/Delete';
import { Grid, IconButton } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { Check, Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { Filter } from '../../../../interfaces/business';
import { Column } from '../../../../routes/components/InfoTable';
import { useFilterDAO } from '../../../../business/Filter';
import { MenuActions, MenuItemComponentType } from '../../../MenuActions';
import { useGlobalContext } from '../../../../hooks';
import { userInfoType } from '../../../../types';
import { ACCOUNT_ROLE } from '../../../../const';

interface FilterManageTableColumnsProps<T> {
  reFetch: () => void;
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>;
  onFilter: (value: Filter<T>) => void;
  setToSave: React.Dispatch<React.SetStateAction<boolean>>;
  onGoBack: (value?: boolean) => void;
  onEdit: (value: Filter<T>) => void;
}

export const useFilterManageTableColumns = <T,>({ reFetch, onEdit, onFilter, onGoBack }: FilterManageTableColumnsProps<T>) => {
  const { enqueueSnackbar } = useSnackbar();

  const { userInfo }: { userInfo: userInfoType } = useGlobalContext();

  const filterDAO = useFilterDAO();
  const onDelete = useCallback(
    async (row: Filter<T>) => {
      try {
        await filterDAO.remove({ filter_id: row.filter_id });
        enqueueSnackbar('Delete filter successfull', { variant: 'success' });
        reFetch();
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Fail to deleted filter', { variant: 'error' });
      }
    },
    [filterDAO, enqueueSnackbar, reFetch]
  );
  const onEditRow = useCallback(
    (row: Filter<T>) => {
      onFilter({ ...row });
      onEdit(row);

      onGoBack(true);
    },
    [onFilter, onGoBack, onEdit]
  );

  const validateAccount = useCallback(
    (row: Filter) => {
      const result = userInfo?.groups?.find((item) => item.id.toString() === ACCOUNT_ROLE.ADMINISTRATOR) ? false : row.account.account_id.toString() !== userInfo?.id;
      return result;
    },
    [userInfo]
  );

  const menuArray = useCallback(
    (row: Filter<T>) => {
      const menu: MenuItemComponentType[] = [
        {
          title: 'Aplicar',
          onClick: (event: MouseEvent) => {
            event.stopPropagation();
            onFilter({ ...row });
            onGoBack();
          },
          disabled: false
        }
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [onFilter, onGoBack]
  );
  return useMemo<Column[]>(
    () => [
      {
        id: 'check',
        label: '',
        width: '3%',
        hideSortIcon: true,
        noSort: true,
        render: (row: Filter<T>) => {
          return <MenuActions menuArray={menuArray(row)} />;
        }
      },
      {
        id: 'name',
        label: 'Nombre',
        width: '10%',
        orderById: 'name'
      },
      {
        id: 'private',
        label: 'Privado',
        width: '5%',
        orderById: 'private',
        hideSortIcon: true,
        noSort: true,
        render: (row: Filter<T>) => {
          return (
            <Grid container justify="center">
              {row.private ? <Check fontSize="small" /> : <Close fontSize="small" />}
            </Grid>
          );
        }
      },
      {
        id: 'preset',
        label: 'Predeterminado',
        width: '5%',
        orderById: 'preset',
        hideSortIcon: true,
        noSort: true,
        render: (row: Filter<T>) => {
          return (
            <Grid container justify="center">
              {row.preset ? <Check fontSize="small" /> : <Close fontSize="small" />}
            </Grid>
          );
        }
      },
      {
        id: 'last_update',
        label: 'Actualizado',
        orderById: 'last_update',
        width: '20%',
        hideSortIcon: true,
        noSort: true,
        render: (row: Filter<T>) => {
          return (
            <Grid container justify="center">
              {row.last_update || '-'}
            </Grid>
          );
        }
      },
      {
        id: 'account.username',
        label: 'Usuario',
        orderById: 'account.username',
        width: '10%',
        render: (row: Filter<T>) => {
          return row.account.username;
        }
      },
      {
        id: '',
        label: '',
        width: '10%',
        hideSortIcon: true,
        noSort: true,
        render: (row: Filter<T>) => {
          return (
            <Grid direction="row">
              <IconButton onClick={() => onDelete(row)} disabled={validateAccount(row)}>
                <Delete fontSize="small" />
              </IconButton>
              <IconButton onClick={() => onEditRow(row)} disabled={validateAccount(row)}>
                <Edit fontSize="small" />
              </IconButton>
            </Grid>
          );
        }
      }
    ],
    [validateAccount, onDelete, onEditRow, menuArray]
  );
};
