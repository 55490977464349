import { useCallback, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router-dom';
import { LibbyObject } from '../../../../../types';
import { City, Order_item, Product } from '../../../../../interfaces/business';

interface UseSaveOrderLogicProps {
  libby: LibbyObject;
  mockedCity: City;
}

export const useSaveOrderLogic = ({ libby, mockedCity }: UseSaveOrderLogicProps) => {
  const history = useHistory();
  const [orderItems, setOrderItems] = useState<Order_item[]>([]);
  const [saving, setSaving] = useState(false);

  const addItem = useCallback(
    (product: Product) => {
      setOrderItems((prev) => {
        const skuIndex = prev.findIndex((orderItem) => orderItem.sku === product.sku);
        const newState = cloneDeep(prev);
        if (skuIndex >= 0) {
          newState[skuIndex] = { ...newState[skuIndex], quantity: newState[skuIndex].quantity + 1 };
        } else {
          const orderItem = {
            order_item_id: '12',
            owner_item_id: `budget - ${product.sku} - ${new Date().getTime()}`,
            name: product.name,
            sku: product.sku,
            quantity: 1,
            unit_price: product.price.toString(),
            subtotal: product.price.toString(),
            product
          };
          newState.push(orderItem);
        }
        return newState;
      });
    },
    [setOrderItems]
  );

  const removeItem = useCallback(
    (orderItem: Order_item) => {
      setOrderItems((prev) => {
        let newState = cloneDeep(prev);
        const skuIndex = prev.findIndex((item) => item.sku === orderItem.sku);
        if (skuIndex < 0) return prev;

        if (newState[skuIndex].quantity === 1) {
          newState = newState.filter((_, i) => i !== skuIndex);
        } else {
          newState[skuIndex] = { ...newState[skuIndex], quantity: newState[skuIndex].quantity - 1 };
        }

        return newState;
      });
    },
    [setOrderItems]
  );

  const onSave = useCallback(
    async (param: any) => {
      try {
        setSaving(true);
        const [orderSource] = await libby.ster_order_source.query().equals('order_source_id', 14).run();
        const [orderState] = await libby.order_state.query().equals('order_state_id', 29).run();
        // const [courierService] = await libby.courier_order.query().equals('courier_id', param.shipment_type).run();
        const [orderDocumentType] = await libby.ster_order_document_type.query().equals('order_document_type_id', param.document_type).run();
        // TODO: no se porque tengo que ponerle el id para que chino haga el insert
        const orderBuyer = {
          order_buyer_id: 49608,
          last_name: param.last_name,
          first_name: param.first_name,
          email: param.email,
          phone_number: param.phone_number,
          street: param.street,
          street_number: param.street_number,
          floor: '',
          department: '',
          zip: param.zip,
          extra_address: '',
          document: param.document,
          comments: '',
          document_type: orderDocumentType,
          city: mockedCity
        };
        const orderShipmentAddress = {
          street: orderBuyer.street,
          street_number: orderBuyer.street_number,
          floor: orderBuyer.floor,
          department: orderBuyer.department,
          extra_address: orderBuyer.extra_address,
          comments: orderBuyer.comments,
          zip: orderBuyer.zip,
          city_: orderBuyer.city
        };
        const orderShipmentReceiver = {
          first_name: orderBuyer.first_name,
          last_name: orderBuyer.last_name,
          email: orderBuyer.email,
          phone_number: orderBuyer.phone_number,
          document: orderBuyer.document,
          extra_address: orderBuyer.extra_address,
          comments: orderBuyer.comments,
          zip: orderBuyer.zip,
          city_: orderBuyer.city,
          document_type: orderBuyer.document_type
        };
        // TODO: nose porque tengo que ponerle el id para que chino haga el insert!!!!
        const orderShipment = {
          order_shipment_id: 4385,
          tracking: '',
          type: '',
          updated_at: new Date(),
          deliver_estimate: new Date(),
          service: { courier_service_id: param.shipment_type },
          receiver: orderShipmentAddress,
          address: orderShipmentReceiver,
          state: { order_shipment_state_id: '3', name: 'preparation' }
        };
        // TODO: guardo el shipment primero pq chino quiere hacer el insert cuando se lo paso directo en el objeto de la orden
        const osh = await libby.ster_order_shipment.save(orderShipment);

        // TODO: mismo aqui, no se porque le tengo  que  pasar el id de un registro random para que chino haga el insert
        const order = {
          order_id: 50490,
          owner_id: `budget - ${new Date().getTime()}`,
          amount: orderItems.reduce((prev, current) => prev + Number(current.unit_price), 0).toString(),
          tax: '0',
          shipping_cost: '0',
          total: orderItems.reduce((prev, current) => prev + Number(current.unit_price), 0).toString(),
          updated_at: new Date(),
          returned_reason: '',
          print: false,
          created_at: new Date(),
          metadata: {},
          buyer: orderBuyer,
          shipment: osh,
          source: orderSource,
          state: orderState,
          // TODO: arreglar esto
          // updated_by: {}
          items: orderItems
        };
        const rs = await libby.ster_order_table.save({ ...order });
        history.push(`/orders/detail/${rs.order_id}`);
      } catch (e) {
        console.log('e', e);
      } finally {
        setSaving(false);
      }
    },
    [orderItems, mockedCity, libby, history, setSaving]
  );

  const editItem = useCallback(
    (orderItem: Order_item) => {
      setOrderItems((prev) => {
        const skuIndex = prev.findIndex((item) => item.sku === orderItem.sku);
        const newState = cloneDeep(prev);
        if (skuIndex >= 0) {
          newState[skuIndex] = orderItem;
        }

        return newState;
      });
    },
    [setOrderItems]
  );

  return { addItem, removeItem, orderItems, onSave, saving, editItem };
};
