import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Typography, Paper, Grid, Button } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from '../../../../../../services/translation';

import { percentageComplete, percentageColor } from '../../../../../../utils';
import { LibbyObject } from '../../../../../../types';
import { CollectStatusPercentage, CollectState, DISPATCH_ITEM_STATE, DISPATCH_ITEM_STATE_NAME } from '../../../../../../const';

import { Collect, Collect_item } from '../../../../../../interfaces/business/dispatch';

import { reducerQuantity } from '../../../../utils/quantity';
import { useCurrentUser } from '../../../../../../hooks';
import { Order_item } from '../../../../../../interfaces/business';

const useStyles = makeStyles((theme: Theme) => ({
  mobileCollectHeaderBack: {
    minHeight: 141,
    backgroundColor: theme.palette.grey[600],
    position: 'relative',
    overflow: 'hidden'
  },
  percentBox: {
    position: 'relative',
    top: 1,
    minHeight: 145,
    width: '100%',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  mobileCollectHeader: {
    padding: '18px 12px 12px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    minHeight: 135,
    boxShadow: 'none',
    width: '100%',
    position: 'absolute',
    top: '-1px'
  },
  headerContent: {
    maxWidth: '75%'
  },
  collectName: {
    fontSize: 24
  },
  topCollectName: {
    fontSize: 12,
    color: theme.palette.grey[500],
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  bottomCollectName: {
    fontSize: 11,
    color: theme.palette.grey[500]
  },
  bottomOfAll: {
    fontSize: 12,
    color: theme.palette.grey[500],
    marginTop: 15
  },
  pickerButton: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    padding: 0,
    lineHeight: 'inherit'
  }
}));

type MobileCollectDetailsHeaderProps = {
  libby: LibbyObject;
  id: string;
  data: Collect;
  loading: boolean;
};

const MobileCollectDetailsHeaderRaw = ({ libby, id, data, loading }: MobileCollectDetailsHeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const userAssigned = useCurrentUser({
    assignedUser: String(data?.account?.account_id)
  });
  const dispatchName = data?.dispatch?.name && !loading ? data.dispatch.name : '';
  const assignedTo = data?.account?.username && !loading ? data.account.username : '';
  const cartName = data?.cart?.name && !loading ? data.cart.name : '';
  const collectItems = useMemo(() => data?.items?.flatMap((order) => order) || [], [data]);

  const rest = collectItems.filter((item) => item.collectItemState.collect_item_state_id === '2').length || 0;

  const products = useMemo(
    () =>
      data?.items
        ? data?.items?.flatMap((item: Collect_item) =>
            item.dispatch_item.order.items?.reduce((ordersItems: Order_item[], orderItem: Order_item) => {
              const copyOrdersItems = [...ordersItems];
              copyOrdersItems.push(orderItem);
              return copyOrdersItems;
            }, [])
          )
        : [],
    [data]
  );

  const productsWithQuantity = useMemo(() => products.reduce(reducerQuantity, 0), [products]);

  const complete = collectItems ? percentageComplete(rest, collectItems.length) : '0';
  const color = complete && percentageColor(Number(complete));

  const productPick = useMemo(() => {
    let count = 0;
    collectItems.forEach((item) => {
      if (item.items_product.length) count += item.items_product.length;
    });

    return count;
  }, [collectItems]);

  const handlePickUp = useCallback(async () => {
    try {
      const collect = { ...data };
      const items = collect?.items.map((item) => {
        const itemsNew = { ...item };
        itemsNew.dispatch_item = {
          ...itemsNew.dispatch_item,
          dispatch_item_state: {
            dispatch_item_state_id: DISPATCH_ITEM_STATE.COLLECTING,
            name: DISPATCH_ITEM_STATE_NAME[DISPATCH_ITEM_STATE.COLLECTING]
          }
        };
        return itemsNew;
      });

      const itemsValidation = collect.items.filter((item: Collect_item) => item.dispatch_item.dispatch_item_state.dispatch_item_state_id !== DISPATCH_ITEM_STATE.COLLECTING);

      if (collect.collectState.collect_state_id !== CollectState.COLLECTING && itemsValidation.length) {
        collect.items = items;
        await libby.ster_dispatch_collect_close.aspect('update_collect_state_and_dispatch_state').save({
          ...collect,
          collectState: { collect_state_id: CollectState.COLLECTING }
        });
      }
      history.push(`/collect/collect-started/${id}`);
    } catch (error) {
      enqueueSnackbar(t(`Something is wrong: ${error}`), { variant: 'error' });
    }
  }, [libby.ster_dispatch_collect_close, id, data, history, enqueueSnackbar, t]);

  return (
    <Paper className={classes.mobileCollectHeaderBack}>
      <Paper
        className={classes.percentBox}
        style={{
          left: `-${100 - Number(complete)}%`,
          backgroundColor: `${color}`
        }}
      />
      <Paper className={classes.mobileCollectHeader}>
        <Grid container>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" className={classes.headerContent}>
              <Grid item xs>
                <Typography className={classes.topCollectName}>{t(dispatchName)}</Typography>
                <Typography className={classes.collectName} variant="h1" color="initial">
                  {`${t('Collect')} #${id}`}
                </Typography>
                <Typography className={classes.bottomCollectName}>{`${t('Assigned To')}: ${t(assignedTo)} | ${cartName}`}</Typography>
                <Typography className={classes.bottomCollectName}>{`${t('Status')}: ${t(data?.collectState?.name ? data?.collectState?.name : '')} `}</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.bottomOfAll}>{`${rest}/${collectItems?.length || '0'} ${t('Orders')} | ${productPick}/${productsWithQuantity} ${t('Products')}`}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              {userAssigned && (
                <Button onClick={handlePickUp} className={classes.pickerButton}>
                  {complete !== CollectStatusPercentage.GREEN && t('Start')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

export const MobileCollectDetailsHeader = DatabaseConnector(MobileCollectDetailsHeaderRaw)('ster_dispatch_collect', 'ster_dispatch_collect_close', 'ster_dispatch_item');
