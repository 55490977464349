import { useCallback, useState } from 'react';
import { LibbyObject } from '../../../types';

type useSearchStockType = {
  libby: LibbyObject;
  product_id: string;
};

export const useSearchStock = ({ libby, product_id }: useSearchStockType) => {
  const [dataStock, setstock] = useState();

  const searchStock = useCallback(async () => {
    const dataStockEnabled = await libby.ster_product_stock.query().limit(0, 1).equals('product.product_id', product_id).run();
    if (dataStockEnabled) {
      setstock(dataStockEnabled);
    }
  }, [product_id, libby]);

  return { dataStock, searchStock };
};
