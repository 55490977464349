import React, { useCallback, useMemo, useState } from 'react';
import { Box, Checkbox, Collapse, FormControlLabel, FormGroup, Grid, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InfoTable from '../../../../routes/components/InfoTable';
import LoadingData from '../../../../routes/components/LoadingData';
import { useFilterManageTableColumns } from './useFilterManageTableColumns';
import { useFilterLibbyFetch } from '../../../../business/Filter';
import { useTranslation } from '../../../../services/translation';
import { useTableSortConfig } from '../../../../commons/hooks/useTableSortConfig';
import useDebounce from '../../../../hooks/useDebounce';
import { FilterManagerModalProps } from '../../types';
import { userInfoType } from '../../../../types';
import { useGlobalContext } from '../../../../hooks';
import { ACCOUNT_ROLE } from '../../../../const';

const searchFilters = ['name', 'screen.name', 'filter_id', 'screen.screen_id'];

export const FilterManagerModal = <T,>({ onFilter, newFilter, showTable, validName, component, onEdit, setToSave, onGoBack, setShowTable, onChecboxchange, onNameChange }: FilterManagerModalProps<T>) => {
  const { t } = useTranslation();

  const { userInfo }: { userInfo: userInfoType } = useGlobalContext();

  const [search, setSearch] = useState<string>('');

  const { orderBy, onSortChange, direction } = useTableSortConfig('name');

  const searchDebounced = useDebounce(search, 700);

  const processedFilter = useMemo(
    () => ({
      admin: userInfo?.groups?.find((item) => item.id === ACCOUNT_ROLE.ADMINISTRATOR)
        ? []
        : [
            {
              path: 'account_id',
              value: userInfo?.id || '',
              method: 'equals'
            },
            {
              path: 'subquery',
              value: 0,
              subGroup: {
                otherAccount: [{ path: 'account_id', value: userInfo?.id, method: 'notEquals' }],
                privateOtherAccount: [{ path: 'private', value: false }]
              }
            }
          ],
      screen: [{ path: 'screen', value: component.screen_id }],
      search: !searchDebounced
        ? []
        : searchFilters.map((path) => ({
            path,
            value: search,
            method: 'includes'
          }))
    }),
    [search, searchDebounced, userInfo, component.screen_id]
  );

  const {
    data = [],
    working,
    fetchMore,
    reFetch
  } = useFilterLibbyFetch({
    filter: processedFilter,
    orderBy,
    direction
  });

  const columns = useFilterManageTableColumns<T>({ onFilter, setShowTable, reFetch, setToSave, onGoBack, onEdit });

  const formatedToRows = useMemo(() => data.map(({ filter_id, ...rest }) => ({ id: filter_id as string, ...rest, filter_id })), [data]);

  const dataFilter = useMemo(() => newFilter, [newFilter]);

  const validatePreset = useMemo(() => data.filter((item) => item.preset && item.account.account_id.toString() === userInfo?.id?.toString()), [data, userInfo]);

  const disable = newFilter.filter_id ? (data.find((item) => item.filter_id === newFilter.filter_id)?.preset ? false : validatePreset.length > 0) : validatePreset.length > 0;

  const onChangeSearch = useCallback((event: any) => {
    if (event?.target?.value || event?.target?.value === '') {
      setSearch(event.target?.value);
    }
  }, []);

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Collapse in={showTable} timeout="auto">
          <Grid item container xs={12}>
            <Box mb={2} width="100%">
              <Grid container justify="flex-end">
                <Grid item xs={4}>
                  <TextField
                    id="generic-search"
                    placeholder={t('Search')}
                    type="search"
                    name="search"
                    value={search}
                    onChange={onChangeSearch}
                    fullWidth
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      endAdornment: <SearchIcon />
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <InfoTable working={working} columns={columns} rows={formatedToRows} onBottomScroll={fetchMore} rowIdKey="order_id" onSortChange={onSortChange} orderBy={orderBy} direction={direction} />
            <Grid item container justify="center">
              <LoadingData label={`${t('Loaded registers')}`} working={working} cant={formatedToRows.length} />
            </Grid>
          </Grid>
        </Collapse>
        <Collapse in={!showTable} timeout="auto">
          <Grid container direction="row" spacing={3}>
            <Grid item xs={6}>
              <TextField
                label={t('Name')}
                name="name"
                value={dataFilter.name}
                onChange={(e) => onNameChange(e.target.value)}
                variant="outlined"
                type="text"
                error={!!(dataFilter.name === '' ? '' : validName ? '' : t('The name already exists'))}
                helperText={dataFilter.name === '' ? '' : validName ? '' : t('The name already exists')}
              />
            </Grid>
            <Grid item xs={6}>
              <FormGroup row onChange={(e) => onChecboxchange(e.target)}>
                <span>{`* ${t('by default')} ${t('Public')}`}</span>
                <FormControlLabel control={<Checkbox size="small" checked={dataFilter.private} />} value={dataFilter.private} name="private" label={t('Private')} />
                <FormControlLabel control={<Checkbox size="small" checked={dataFilter.preset} disabled={disable} />} value={dataFilter.preset} name="preset" label={t('Preset')} />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid />
        </Collapse>
      </Grid>
    </Grid>
  );
};
