import { Moment } from 'moment';
import { Filter } from '../../../../../../types';
import { formatDate } from '../../../../../../utils';

type MakeFilterProps = {
  search?: string;
  startDate?: Moment | null;
  endDate?: Moment | null;
  effectiveness?: number;
  marketplace?: any[];
  company?: any[];
  courier?: any[];
  canal?: any[];
};

export const makeFilter = ({ search, startDate, endDate, effectiveness, marketplace, company, courier, canal }: MakeFilterProps) => {
  const filter: Filter = {};
  if (search) {
    const searchArray = search.split(' ').reduce((accumulator: Filter, item, index) => {
      accumulator[index] = [
        {
          path: 'name',
          value: search,
          method: 'includes'
        }
      ];

      return accumulator;
    }, {});
    filter.search = [...searchArray[0]];
  }

  if (startDate) {
    filter.startDate = [
      {
        path: 'start_date',
        value: formatDate(startDate.toDate(), true, false, 'YYYY-MM-DD HH:mm:ss')
      }
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'end_date',
        value: formatDate(endDate.toDate(), true, false, 'YYYY-MM-DD HH:mm:ss')
      }
    ];
  }
  if (effectiveness) {
    filter.effectiveness = [
      {
        path: 'effectiveness',
        value: effectiveness
      }
    ];
  }

  if (marketplace?.length) {
    filter.marketplace = marketplace?.map((marketplaceValue) => ({
      path: 'marketplace_id',
      value: marketplaceValue?.value
    }));
  }

  if (company?.length) {
    filter.company = company?.map((companyValue) => ({
      path: 'company_id',
      value: companyValue?.value
    }));
  }

  if (courier?.length) {
    filter.courier = courier?.map((courierValue) => ({
      path: 'courier_id',
      value: courierValue?.value
    }));
  }

  if (canal?.length) {
    filter.canal = canal?.map((canalValue) => ({
      path: 'canal_id',
      value: canalValue?.value
    }));
  }

  return filter;
};
