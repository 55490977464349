import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { Grid, Paper, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField, useForm } from 'react-final-form-hooks';
import { ScreenAligned } from '../../../../../components/ScreenAligned/ScreenAligned';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../../utils/materialHelpers';
// TODO:import { BugetCondition } from './BudgetCondition';
// TODO:import { BudgetProduct } from './BudgetProduct';
import { AnyObject } from '../../../../../types';
import { BudgetDetailsProps, RowsType } from '../../../types';
// import { BudgetProduct } from './BudgetProduct';
import { useLibbyFetch } from '../../../../../hooks';
import { BudgetUser } from '../../../interfaces/BudgetUser';
import { useTranslation } from '../../../../../services/translation';
import { ProductSearch } from './ProductSearch';
import { useSaveOrderLogic } from '../hook/useSaveOrderLogic';
import { City, Product } from '../../../../../interfaces/business';
import { BudgetItems } from './BudgetItems';
import { GeneralSelect } from '../../../../../components/GeneralSelect';
import { SelectProvince } from '../../../../../components/SelectProvince';
import { AutocompleteCity } from '../../../../../components/AutocompleteCity';

const useStyles = makeStyles((theme: AnyObject) => ({
  search: {
    width: '15vw'
  },
  container: {
    position: 'relative',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  },
  btn: {
    border: 'none',
    margin: 20,
    width: 120,
    height: 30,
    borderRadius: 6,
    textTransform: 'uppercase',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    cursor: 'pointer',
    color: '#fff',
    backgroundSize: '200%',
    transition: '0.4s',
    '&:hover': {
      backgroundPosition: 'right'
    }
  },
  btn1: {
    backgroundImage: 'linear-gradient(45deg, #FFC312, #EE5A24, #00A8FF)'
  },
  btn2: {
    backgroundImage: 'linear-gradient(45deg, #e74c3c, #8e44ad, #f1c40f)'
  },
  root: {
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'rotate(0deg)!important'
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: '10px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  containerInputs: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  inputs: {
    margin: theme.spacing(1),
    width: '30ch'
  },
  errorForm: {
    color: '#FF5179',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  error: {
    fontSize: '12px'
  },
  provinceCityInput: {
    '& label': {
      color: '#999999'
    },
    '& input': {
      color: 'black'
    },
    '& .MuiFormHelperText-root': {
      color: '#FF5179'
    },
    width: '30ch',
    margin: theme.spacing(1)
  },
  title: {
    fontFamily: '"Oswald"',
    verticalAlign: 'baseline',
    textTransform: 'uppercase',
    fontWeight: 200,
    fontSize: '15px',
    color: '#4B4B4B',
    textAlign: 'start'
  }
}));

const initFormBudget = {
  first_name: '',
  last_name: '',
  document: '',
  document_type: '',
  phone_number: '',
  email: '',
  state: '',
  city: '',
  street: '',
  zip: '',
  shipment_type: '',
  payment_method: ''
};

export const BudgetForm = ({ title, libby }: BudgetDetailsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [budgetUsers, setBudgetUsers] = useState<BudgetUser[]>([]);
  const [user, setUser] = useState<BudgetUser | undefined | null>();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  const { addItem, orderItems, removeItem, onSave, saving, editItem } = useSaveOrderLogic({ libby, mockedCity: (budgetUsers && budgetUsers[0]?.city) as City });

  const { data } = useLibbyFetch(libby, {
    daoName: 'ster_order_buyer'
  });
  useEffect(() => {
    setBudgetUsers(data as BudgetUser[]);
  }, [data]);

  const onSubmit = useCallback(
    async (param) => {
      await onSave(param);
    },
    [onSave]
  );

  const { form, handleSubmit } = useForm({
    initialValues: initFormBudget,
    onSubmit
  });

  const first_name = useField('first_name', form);
  const last_name = useField('last_name', form);
  const document = useField('document', form);
  const document_type = useField('document_type', form);
  const phone_number = useField('phone_number', form);
  const email = useField('email', form);
  const state = useField('state', form);
  const city = useField('city', form);
  const street = useField('street', form);
  const zip = useField('zip', form);
  const shipment_type = useField('shipment_type', form);
  const payment_method = useField('payment_method', form);

  const errorState = getErrorAndHelperText(state);
  const errorCity = getErrorAndHelperText(city);

  const updateForm = useCallback(
    (values) => {
      if (values.document !== document.input.value) {
        form.reset(values);
      }
    },
    [document.input.value, form]
  );

  useEffect(() => {
    if (user) {
      const formValues = {
        first_name: user?.first_name,
        last_name: user?.last_name,
        document: user?.document,
        document_type: user?.document_type?.document_type_id,
        phone_number: user?.phone_number,
        email: user?.email,
        state: user?.city?.state?.name,
        city: user?.city?.name,
        street: user?.street,
        zip: user?.zip,
        shipment_type: '',
        payment_method: ''
      };

      updateForm(formValues);
    } else if (user === null) {
      form.reset(initFormBudget);
    }
  }, [city.input, document.input, first_name.input, last_name.input, phone_number.input, street.input, zip.input, state.input, email.input, document_type.input, user, form, updateForm]);

  const orderItemsRow = useMemo<RowsType[]>(() => orderItems.map((orderItem) => ({ ...orderItem, id: orderItem.order_item_id })), [orderItems]);

  return (
    <ScreenAligned title={title} additionalTitle={null} showData={!!data}>
      <Grid container spacing={3}>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid item container>
                <Grid xs={12}>
                  <Typography variant="h1" className={classes.title}>{`${t('Client')}`}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    onChange={(event, value) => setUser(value)}
                    options={budgetUsers}
                    getOptionLabel={(item) => `${item.first_name} ${item.last_name} ${item.document}`}
                    renderOption={(item) => {
                      return (
                        <div>
                          <div>{`${item.first_name} ${item.last_name}`}</div>
                          <span>DNI: {item.document}</span>
                        </div>
                      );
                    }}
                    popupIcon={<SearchIcon />}
                    style={{ width: 450, margin: '8px' }}
                    renderInput={(params) => <TextField {...params} label="Buscar" variant="standard" />}
                  />
                </Grid>
                <Grid item xs={12} className={classes.containerInputs}>
                  <TextField
                    {...textFieldProps(`${t('First Name')}`)}
                    value={first_name.input.value}
                    required
                    variant="standard"
                    onChange={first_name.input.onChange}
                    InputProps={{ readOnly: !!user }}
                    InputLabelProps={{ shrink: true, ...getErrorAndHelperText(first_name) }}
                    className={classes.inputs}
                  />
                  <TextField
                    {...textFieldProps(`${t('Last Name')}`)}
                    required
                    variant="standard"
                    value={last_name.input.value}
                    onChange={last_name.input.onChange}
                    InputProps={{ readOnly: !!user }}
                    InputLabelProps={{ shrink: true, ...getErrorAndHelperText(last_name) }}
                    className={classes.inputs}
                  />
                  <Grid item style={{ margin: '8px', width: '30ch' }}>
                    <GeneralSelect
                      label={`${t('Document Type')}`}
                      optionAll={false}
                      data={[
                        { id: '1', value: 'DNI' },
                        { id: '2', value: 'CUIT' },
                        { id: '3', value: 'CUIL' }
                      ]}
                      working={false}
                      inputProps={{
                        onChange: document_type.input.onChange,
                        value: document_type.input.value || '',
                        ...getErrorAndHelperText(document_type)
                      }}
                    />
                  </Grid>
                  <TextField
                    {...textFieldProps(`${t('Document')}`)}
                    required
                    variant="standard"
                    value={document.input.value}
                    onChange={document.input.onChange}
                    InputProps={{ readOnly: !!user }}
                    InputLabelProps={{ shrink: true, ...getErrorAndHelperText(document) }}
                    className={classes.inputs}
                  />
                  <TextField
                    {...textFieldProps(`${t('Phone Number')}`)}
                    required
                    variant="standard"
                    value={phone_number.input.value}
                    onChange={phone_number.input.onChange}
                    InputProps={{ readOnly: !!user }}
                    InputLabelProps={{ shrink: true, ...getErrorAndHelperText(phone_number) }}
                    className={classes.inputs}
                  />
                  <TextField
                    {...textFieldProps(`${t('Email')}`)}
                    required
                    variant="standard"
                    value={email.input.value}
                    onChange={email.input.onChange}
                    InputProps={{ readOnly: !!user }}
                    InputLabelProps={{ shrink: true, ...getErrorAndHelperText(email) }}
                    className={classes.inputs}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid item container>
                <Grid xs={12}>
                  <Typography variant="h1" className={classes.title}>{`${t('Shipment')}`}</Typography>
                </Grid>
                <Grid item xs={3} style={{ margin: '8px' }}>
                  <GeneralSelect
                    label={`${t('Shipment Type')}`}
                    optionAll={false}
                    data={[
                      { id: '11', value: 'Transporte' },
                      { id: '13', value: 'Retiro en planta' },
                      { id: '2', value: 'Andreani' }
                    ]}
                    working={false}
                    inputProps={{
                      onChange: shipment_type.input.onChange,
                      value: shipment_type.input.value || '',
                      ...getErrorAndHelperText(shipment_type)
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.containerInputs}>
                  <SelectProvince
                    name="state"
                    inputProps={{
                      onChange: state.input.onChange,
                      value: state.input.value,
                      classes: classes.provinceCityInput,
                      error: errorState,
                      onBlur: state.input.onBlur
                    }}
                  />
                  <AutocompleteCity
                    name="city"
                    inputProps={{
                      province: state.input.value,
                      onChange: city.input.onChange,
                      value: city.input.value,
                      classes: classes.provinceCityInput,
                      error: errorCity,
                      onBlur: city.input.onBlur
                    }}
                  />
                  <TextField
                    {...textFieldProps(`${t('Street')}`)}
                    required
                    variant="standard"
                    value={street.input.value}
                    onChange={street.input.onChange}
                    InputLabelProps={{ shrink: true, ...getErrorAndHelperText(street) }}
                    className={classes.inputs}
                  />
                  <TextField
                    {...textFieldProps(`${t('Zip')}`)}
                    required
                    variant="standard"
                    value={zip.input.value}
                    onChange={(event) => zip.input.onChange(event)}
                    InputLabelProps={{ shrink: true, ...getErrorAndHelperText(zip) }}
                    className={classes.inputs}
                    type="number"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid item container>
                <Grid xs={12}>
                  <Typography variant="h1" className={classes.title}>{`${t('Payment Method')}`}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <GeneralSelect
                    label={`${t('Method')}`}
                    optionAll={false}
                    data={[
                      { id: '1', value: 'Contado' },
                      { id: '15', value: 'Frávega Marketplace' },
                      { id: '18', value: 'Cuenta Corriente' },
                      { id: '19', value: '60 días FF' },
                      { id: '23', value: 'CH a 30 días c/entrega' },
                      { id: '24', value: 'CH a 15 días c/entrega' },
                      { id: '25', value: '40 días' },
                      { id: '26', value: 'CH a 30 días / 7 días c/entrega' },
                      { id: '27', value: '90 días FF' },
                      { id: '28', value: '30 días FF' },
                      { id: '29', value: 'CH a 45 días c/entrega' },
                      { id: '30', value: 'Anticipado' },
                      { id: '31', value: 'CH a 45 días c/ entrega' },
                      { id: '33', value: 'CH 60/ 90 días c/ entrega' },
                      { id: '34', value: 'CH a 60 días c/ entrega' },
                      { id: '35', value: 'CH a 60/ 90 días c/ entrega' },
                      { id: '36', value: 'CH a 30/ 60 días c/ entrega' },
                      { id: '37', value: 'CH a 30 días c/ entrega' },
                      { id: '41', value: 'CH 90 días c/ entrega' },
                      { id: '42', value: '30 días Factura de Crédito' },
                      { id: '43', value: 'Ch c/entrega 30 días FF' },
                      { id: '44', value: '30 días Fecha de Entrega' },
                      { id: '45', value: 'CH 150 días FF' },
                      { id: '46', value: 'CH a 75 días c/entrega' },
                      { id: '48', value: 'CH a 90/ 120 días c/ entrega' },
                      { id: '49', value: '7 días FF' },
                      { id: '50', value: 'CH a 45/ 60 días c/ entrega' },
                      { id: '51', value: 'CH a 30/ 60/ 90 días c/ entrega' },
                      { id: '52', value: '21 Días' },
                      { id: '53', value: 'Fin de Mes Siguiente' },
                      { id: '54', value: '30% Ahora, Balance 60 Días' },
                      { id: '56', value: '10/1/22' },
                      { id: '58', value: '13 días FF' },
                      { id: '59', value: '18' },
                      { id: '60', value: '18 DIAS FF' },
                      { id: '61', value: '15 dias FF' },
                      { id: '62', value: '30/60/90 Días' },
                      { id: '64', value: '11 DIAS FF' },
                      { id: '66', value: 'CH a 30 / 45 días c/ entrega' },
                      { id: '71', value: 'Según Pliego' },
                      { id: '12', value: 'Cheque contra entrega' },
                      { id: '8', value: '50% contado, saldo 30 días' },
                      { id: '13', value: 'CH c/entrega 30 y 60 días' },
                      { id: '47', value: 'CH c/entrega 60 y 90 días' },
                      { id: '55', value: 'eCH c/entrega 30/ 60/ 90 días' },
                      { id: '9', value: '10 días' },
                      { id: '2', value: '15 días' },
                      { id: '63', value: '60 días' },
                      { id: '3', value: 'CH c/entrega 30 días' },
                      { id: '4', value: 'CH c/entrega 45 días' },
                      { id: '14', value: 'CH c/entrega 60 días' },
                      { id: '10', value: 'CH c/entrega 90 días' }
                    ]}
                    working={false}
                    inputProps={{
                      onChange: payment_method.input.onChange,
                      value: payment_method.input.value || '',
                      ...getErrorAndHelperText(payment_method)
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <ProductSearch onProductAdded={(e: any) => addItem(e as Product)} />
          </Grid>

          <Grid item xs={12}>
            <BudgetItems items={orderItemsRow} removeItem={removeItem} editItem={editItem} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3} justify="flex-end">
              <Grid item xs={4}>
                <Paper className={classes.paper}>Subtotal</Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.container}>
                <Button color="primary" type="submit" onClick={() => setUser(null)} style={{ marginRight: 23 }}>
                  {t('Cancel')}
                </Button>
                <Button color="primary" type="submit" variant="contained" disabled={saving}>
                  {saving ? `${t('Processing')}...` : t('Save')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </ScreenAligned>
  );
};
