import React, { useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useSnackbar } from 'notistack';
import { TagManagerTable } from './components/TagManagerTable';
import { AnyObject, LibbyObject } from '../../../../types/types';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { addProducts } from '../../functions/AddProducts';
import { ButtonDetailsType } from '../../../../types/Button';
import { ButtonComponent } from '../../../../components/Button';
import { Order, Product } from '../../../../interfaces/business';
import { ORDER_STATE } from '../../../../const';
import { useModalWarning } from '../../../../hooks';
import { useTranslation } from '../../../../services/translation';
import { useStateOrder } from '../../../Orders/routes/OrdersList/hook/useStateOrder';
import CustomModal from '../../../../services/customFormDialog';
import { ReceivingConfirmationForm } from './components/ReceivingConfirmationForm';
import { formInitialValues, ReceivingConfirmationData, validate as validateReceivingConfirmation } from './utils/model';
import { CANCEL_TYPE, cancelOrderInOdoo } from '../../../Orders/utils';

const ReceivingConfirmationModalService = CustomModal(ReceivingConfirmationForm);

type RowsType = {
  id: string;
  [k: string]: any;
};
type HeadCellsType = {
  id: string;
  label: string;
  translate?: boolean;
  render?: (row: any, item: any, t: (str: string) => string) => React.ReactNode;
  [k: string]: any;
};

export interface TagManagerListProps {
  working: boolean;
  filter?: {
    source?: Array<{ value: string }>;
    marketplace?: Array<{ value: string }>;
  };
  rows: Array<RowsType>;
  columns: Array<HeadCellsType>;
  setFilter: (makeFilter: object) => void;
  checked: AnyObject;
  fetchMore: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  title: TitleBreadcrumbs[];
  updateDataWithProducts: (dataUpdate: Product, id?: string | undefined) => void;
  libby: LibbyObject;
  updateDataField: (order: Order) => void;
}

export const TagMangerListRaw = ({ rows, columns, setFilter, working, checked, fetchMore, onSortChange, orderBy, direction, filter, title, updateDataWithProducts, libby, updateDataField }: TagManagerListProps) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslation();

  const { onChangeUpdateState } = useStateOrder({
    libby
  });

  const { enqueueSnackbar } = useSnackbar();

  const redirectPrint = useCallback(async () => {
    history.push(`${match.path}/pdf`);
  }, [history, match]);

  const handlePrint = useCallback(async () => {
    addProducts({ orders: checked.all, updateDataWithProducts, redirectPrint });
  }, [checked, updateDataWithProducts, redirectPrint]);

  const dataAllReceiver = useMemo(() => {
    const dataAllSelect = checked.all as Order[];

    return dataAllSelect.filter((order: Order) => order.state.order_state_id === ORDER_STATE.WAITING_FOR_RETURN.toString());
  }, [checked.all]);

  const { showModal } = useModalWarning({
    title: 'Receive orders',
    confirmText: 'Yes',
    oneButton: false
  });

  const onReceivingConfirmation = useCallback(async (): Promise<ReceivingConfirmationData> => {
    try {
      const response = await ReceivingConfirmationModalService.show({
        confirmText: t('Confirm'),
        cancelText: t('Cancel'),
        title: t('Confirm'),
        validate: validateReceivingConfirmation,
        formInitialValues
      });
      return response as ReceivingConfirmationData;
    } catch (error) {
      if (error !== false) {
        enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
      }
      return {} as ReceivingConfirmationData;
    }
  }, [enqueueSnackbar, t]);

  const handleReceive = useCallback(async () => {
    const receivingConfirmationData: ReceivingConfirmationData = await onReceivingConfirmation();
    const dataOrderId = dataAllReceiver.reduce((result: string[], element: Order) => {
      result.push(element.order_id);
      return result;
    }, []);

    try {
      if (receivingConfirmationData.location) {
        const validate = await showModal({
          newContent: t('The selected orders will change the status to "Returned". Do you want to continue?').replace('$$$', dataOrderId.join(','))
        });

        if (validate) {
          for (let i = 0; i < dataAllReceiver.length; i++) {
            const orderReceiver: Order = dataAllReceiver[i];
            /* eslint-disable-next-line */
            await cancelOrderInOdoo({ id_order: orderReceiver.order_id, type: CANCEL_TYPE.toReturn, create_rma: !!receivingConfirmationData.rma, location_id: Number(receivingConfirmationData?.location) });
            /* eslint-disable-next-line */
            await onChangeUpdateState({
              updateDataField,
              data: orderReceiver,
              validation: false,
              typeUpdateState: 'received',
              state_id: ORDER_STATE.RETURNED.toString()
            });
          }
        }
      }
    } catch (error) {
      if (error) {
        enqueueSnackbar(t('Error modifying order status: $$$$').replace('$$$$', error), {
          variant: 'error'
        });
      }
    }
  }, [showModal, t, dataAllReceiver, onChangeUpdateState, updateDataField, enqueueSnackbar, onReceivingConfirmation]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'tagmanagerreceive',
        onClick: () => {
          handleReceive();
        },
        title: 'Receive',
        disabled: !dataAllReceiver.length
      },
      {
        id: 'tagmanagerprint',
        onClick: () => {
          handlePrint();
        },
        title: 'Print',
        disabled: checked.all.length <= 0 || working,
        variant: 'contained'
      }
    ],
    [checked, handlePrint, working, dataAllReceiver, handleReceive]
  );

  return (
    <ScreenAligned
      title={title}
      additionalTitle={
        <>
          {buttons.map((dataButton: ButtonDetailsType) => (
            <ButtonComponent
              key={dataButton.id}
              title={dataButton.title}
              className={dataButton.className}
              variant={dataButton.variant}
              color={dataButton.color}
              onClick={dataButton.onClick}
              type={dataButton.type}
              disabled={dataButton.disabled}
              loading={dataButton.loading}
            />
          ))}
        </>
      }
    >
      <TagManagerTable filter={filter} rows={rows} columns={columns} setFilter={setFilter} working={working} fetchMore={fetchMore} direction={direction} orderBy={orderBy} onSortChange={onSortChange} />
    </ScreenAligned>
  );
};

export const TagMangerList = DatabaseConnector(TagMangerListRaw)('admin_account', 'ster_order_table');
