import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { GeneralSelect } from '../../../components/GeneralSelect';
import { AnyObject, LibbyObject } from '../../../types/types';
import { useLibbyFetch, useLibbyCall } from '../../../hooks';
import { Cart } from '../../../interfaces/business/dispatch/Cart';
import { makeFilter } from '../utils/makeFilter';
import { Collect_state } from '../../../interfaces/business/dispatch/Collect_state';

type SelectWithCollectProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  valueInit: Cart;
  disabled: boolean;
  collect_id: string;
  assigned_id: string;
  collect_state_id: string[];
  idData: string;
  nameData: string;
  daoName: string;
  label: string;
  sort?: boolean;
  sortBy?: string;
};

export const SelectWithCollectRaw = ({ libby, inputProps = {}, optionAll = false, valueInit, disabled, assigned_id, collect_state_id, idData, nameData, daoName, label, sort, sortBy, ...styles }: SelectWithCollectProps) => {
  const { data: dataCollectStateArray } = useLibbyCall(libby, {
    daoName: 'ster_dispatch_collect_state',
    methodName: 'getAll'
  });

  const dataCollectStateString = useMemo(() => dataCollectStateArray.map((dataCollectState: Collect_state) => dataCollectState.collect_state_id), [dataCollectStateArray]);

  const collect_state = useMemo(() => (collect_state_id.length ? collect_state_id : dataCollectStateString), [collect_state_id, dataCollectStateString]);

  const filter = useMemo(() => makeFilter({ assigned_id, collect_state_id: collect_state }), [assigned_id, collect_state]);

  const paramsFetch = useMemo(
    () => ({
      daoName,
      aspect: 'list_delete_data_collect',
      filter,
      limit: 10000
    }),
    [filter, daoName]
  );
  const { data: dataRaw, working }: { data: AnyObject; working: boolean } = useLibbyFetch(libby, paramsFetch);

  const dataSend = useMemo(
    () =>
      dataRaw.map((data: any) => ({
        id: data[idData],
        value: data[nameData].toLowerCase()
      })),
    [dataRaw, idData, nameData]
  );

  return <GeneralSelect working={working} label={label} optionAll={optionAll} data={dataSend} inputProps={inputProps} disabled={disabled} {...styles} sort={sort} sortBy={sortBy} />;
};

export const SelectWithCollect = DatabaseConnector(SelectWithCollectRaw)('ster_dispatch_cart_with_collect', 'ster_dispatch_list', 'ster_dispatch_collect_state');
