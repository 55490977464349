import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { TextFieldProps } from '@material-ui/core/TextField';
import { getValidate, formInitialValues } from '../../AddAccount/utils';
import { SelectRole } from '../../components/SelectRole';
import { AccountProviderContext } from '../../../../../context/AccountProvider';
import { useTranslation } from '../../../../../services/translation';
import { LibbyObject } from '../../../../../types/types';
import DeleteModal from '../../../../../services/confirmDialog';
import EditModal from '../../../../../services/formDialog';

type UseAccountModalsProps = {
  libby: LibbyObject;
  reFetch: Function;
  updateData?: (data: object) => void;
};

export const useAccountModals = ({ libby, reFetch, updateData }: UseAccountModalsProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const accountProvider = useContext(AccountProviderContext);

  const historyRefetch = useCallback(() => {
    history.push('/accounts');
  }, [history]);

  const createAccountDisableModal = useCallback(
    async (account) => {
      account.creation_date = account.creation_date || new Date();
      const confirm = await DeleteModal.show({
        title: account.account_enabled ? t('Disable Account') : t('Enable Account'),
        content: t(account.account_enabled ? 'Do you want to disable the account of the user $$$$?' : 'Do you want to enable the account of the user $$$$?').replace('$$$$', account.username),
        confirmText: t('Yes'),
        cancelText: t('No')
      });
      if (confirm) {
        try {
          const data = await libby.admin_account.save({ ...account, account_enabled: !account.account_enabled });
          if (updateData) {
            updateData(data);
          }

          enqueueSnackbar('Disabled User', { variant: 'success' });
        } catch (e) {
          enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        } finally {
          reFetch();
          historyRefetch();
        }
      } else {
        historyRefetch();
      }
    },
    [enqueueSnackbar, libby.admin_account, updateData, historyRefetch, reFetch, t]
  );

  const createAccountEditModal = useCallback(
    async (account, inputType, inputName, handleClose) => {
      let successOutput = '';
      const paramsModal = {
        inputType,
        inputName,
        validate: getValidate([inputType]),
        formInitialValues,
        confirmText: t('Yes'),
        cancelText: t('No'),
        title: '',
        content: '',
        labelContent: '',
        select: ({ inputProps }: { inputProps: TextFieldProps }) => <SelectRole accountInfo={accountProvider} inputProps={inputProps} />
      };
      if (inputName === 'password') {
        paramsModal.title = t('Change Password');
        paramsModal.content = t('Do you want to change the account password of the user $$$$?');
        paramsModal.labelContent = t('password');
        successOutput = t('Updated Password');
      } else if (inputName === 'username') {
        paramsModal.title = t('Change Username');
        paramsModal.content = t('Do you want to change the account name of the user $$$$?');
        paramsModal.labelContent = t('account name');
        successOutput = t('Updated Username');
      } else if (inputName === 'account_role') {
        paramsModal.inputType = 'select';
        paramsModal.title = t('Change Role');
        paramsModal.content = t('Do you want to change the account role of the user $$$$?');
        successOutput = t('Updated Role');
      }
      paramsModal.content = paramsModal.content.replace('$$$$', account.username);
      try {
        const updateAccount: object | any = await EditModal.show(paramsModal);
        if (updateAccount) {
          if (inputName === 'account_role') {
            updateAccount.role = {
              account_role_id: updateAccount.account_role
            };
          }
          try {
            setLoading(true);
            const data = await libby.admin_account.save({ ...account, ...updateAccount });
            if (updateData) {
              updateData(data);
            }
            enqueueSnackbar(successOutput, { variant: 'success' });
          } catch (e) {
            enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
          } finally {
            setLoading(false);
            if (updateData) {
              historyRefetch();
            }
            if (handleClose) {
              handleClose();
            }
            reFetch();
          }
        }
      } catch (e) {
        if (updateData) {
          historyRefetch();
        }
        if (handleClose) {
          handleClose();
        }
        // cancel edit
      }
    },
    [accountProvider, enqueueSnackbar, updateData, libby.admin_account, historyRefetch, reFetch, t]
  );

  return {
    createAccountDisableModal,
    createAccountEditModal,
    loading
  };
};
