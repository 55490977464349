import React, { useMemo, useState, useCallback } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useParams } from 'react-router-dom';
import { AnyObject, LibbyObject } from '../../../../types/types';
import { useLibbyFetch } from '../../../../hooks/useLibbyFetch';
import { makeFilter } from '../../../Orders/routes/OrdersList/utils/makeFilter';
import { DispatchOrderListTable } from './components/DispatchOrderListTable';
import { useDispatchesDetail } from '../../hook';
import { useOrderStateEnabled } from './hook/useOrderStateEnabled';
import { Filter } from '../../../../types';

type DispatchOrderListProps = {
  libby: LibbyObject;
  path: string;
  reFetch: () => void;
};

const DispatchOrderListRaw = ({ libby, path, reFetch }: DispatchOrderListProps) => {
  const { id, courier } = useParams();
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('order_id');
  const [orderByAux, setOrderByAux] = useState('priority');
  const [directionAux, setDirectionAux] = useState<'asc' | 'desc'>('desc');

  const orderStatusInit = useOrderStateEnabled();

  const [filter, setFilter] = useState(
    makeFilter({
      orderStatus: orderStatusInit,
      source: courier
    })
  );

  const handleRequestSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    if (newOrderBy !== 'priority') {
      setOrderBy(newOrderBy);
      setDirection(newDirection);
    }
    setOrderByAux(newOrderBy);
    setDirectionAux(newDirection);
  };

  const paramsFetch = useMemo(
    () => ({
      daoName: 'ster_order_for_dispatch',
      aspect: 'delete_order_in_items',
      filter,
      orderBy,
      direction
    }),
    [filter, orderBy, direction]
  );

  const onChangeFilter = useCallback(
    (value: Filter) => {
      if (JSON.stringify(filter) !== JSON.stringify(value)) {
        setFilter(value);
      }
    },
    [setFilter, filter]
  );

  const { data, working, fetchMore } = useLibbyFetch(libby, paramsFetch);

  const dataUpdate = useMemo(
    () =>
      data.map((dataAdd: AnyObject) => ({
        ...dataAdd,
        priority: Math.floor(Math.random() * (3 + 1 - 1) + 1)
      })),
    [data]
  );

  const { addItemInDispatch } = useDispatchesDetail({
    libby,
    id,
    reFetch
  });

  return (
    <DispatchOrderListTable
      id={id}
      courier={courier}
      data={dataUpdate}
      path={path}
      filter={filter}
      onFilterChange={onChangeFilter}
      working={working}
      fetchMore={fetchMore}
      onSortChange={handleRequestSort}
      orderBy={orderByAux}
      direction={directionAux}
      addItem={addItemInDispatch}
      reFetch={reFetch}
    />
  );
};

export const DispatchOrderList = DatabaseConnector(DispatchOrderListRaw)('ster_order_for_dispatch', 'ster_dispatch_details', 'ster_dispatch_update', 'ster_dispatch_details_update', 'ster_dispatch_upload_documents_manifest');
