export enum ORDER_COURIER {
  FLEX = 1,
  ANDREANI = 2,
  ENVIO_PACK = 3,
  FRAVEGA_SHIPMENTS = 4,
  CORREO_COMPRAS = 5
}

export const OrderCourier: { [k: string]: string } = {
  1: 'Fravega shipments',
  2: 'Flex',
  3: 'Andreani',
  4: 'EnvioPack',
  5: 'Correo compras'
};
