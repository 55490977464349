import { LibbyFetchDAO } from '../LibbyFetchDAO';

export type FilterBarSelection = {
  goal_id: string;
};

export class GoalFieldEnabledDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_goal_field_enabled', 'goal_id');
  }

  async getAllGoalFieldEnabled({ goal_id }: FilterBarSelection) {
    let ls = this.query();
    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;

    if (goal_id) {
      if (needsAnd) ls = ls.and();
      ls = ls.equals('goal_id', goal_id);
      needsAnd = true;
    }
    return ls.run();
  }
}
