import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { Order_item } from '../../../../../interfaces/business';
import { PaginatedTable } from '../../../../../components/PaginatedTable';
import { useTranslation } from '../../../../../services/translation';
import { RowsType } from '../../../types';
import CustomModal from '../../../../../services/customFormDialog';
import { EditProductBudgetDialog } from '../../../../../components/EditPoductBudgetDialog';

interface BudgetItemsProps {
  items: RowsType[];
  removeItem: (item: Order_item) => void;
  editItem: (item: Order_item) => void;
}

const EditProductBudgetDialogModal = CustomModal(EditProductBudgetDialog);

export const BudgetItems = ({ items, removeItem, editItem }: BudgetItemsProps) => {
  const { t } = useTranslation();

  const showModal = useCallback(
    async (row) => {
      try {
        const confirm = await EditProductBudgetDialogModal.show({
          title: 'Edit Budget Product',
          formInitialValues: row,
          confirmText: 'Save',
          cancelText: 'Cancel'
        });

        if (confirm) {
          editItem(confirm as Order_item);
        }
      } catch (error) {
        // nothing
      }
    },
    [editItem]
  );

  const columns = [
    {
      id: 'sku',
      label: 'SKU'
    },
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'quantity',
      label: 'Quantity'
    },
    {
      id: 'unit_price',
      label: 'Unit Price'
    },
    {
      id: 'subtotal',
      label: 'Subtotal'
    },
    {
      id: 'stock',
      label: 'Stock'
    },
    {
      id: 'margen',
      label: 'Margen'
    },
    {
      id: 'actions',
      label: 'Actions',
      render: (row: any) => {
        return (
          <>
            <Button
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                showModal(row);
              }}
              color="primary"
              style={{ marginRight: 15 }}
            >
              {t('Edit')}
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                removeItem(row);
              }}
              color="primary"
            >
              {t('Delete')}
            </Button>
          </>
        );
      }
    }
  ];

  return <PaginatedTable headCells={columns} rows={items} rowIdKey="sku" />;
};
