import React, { useCallback } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from '../../../../../services/translation';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints } from '../../../../../const/gridBreakpoints';

import { DataGenerateAutocompleteType } from '../../../../../utils/generateAutocomplete';
import { FilterBarSelectionReportingObjectiveSales } from '../type';
import { SelectGoal } from '../../../../../components/SelectGoal';
import { useGoalListDateLibbyCall } from '../../../../../business/goal';
import { SelectGoalDate } from '../../../../../components/SelectGoalDate';
import { GoalDate } from '../../../../../interfaces/business/goal/GoalDate';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  initFetch: FilterBarSelectionReportingObjectiveSales;
};

const SearchFiltersRaw = ({ onFilterChange, initFetch }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const { data: dataAllDate } = useGoalListDateLibbyCall({
    methodName: 'getAllListDate'
  });

  const handleChangeCanal = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: DataGenerateAutocompleteType[]) => {
      const newFilter: FilterBarSelectionReportingObjectiveSales = {
        ...initFetch,
        canal: value
      };
      onFilterChange(newFilter);
    },
    [initFetch, onFilterChange]
  );

  const handleChangeCategory = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: DataGenerateAutocompleteType[]) => {
      const newFilter: FilterBarSelectionReportingObjectiveSales = {
        ...initFetch,
        category: value
      };
      onFilterChange(newFilter);
    },
    [initFetch, onFilterChange]
  );

  const handleChangeDate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const dataAllDateWithType = dataAllDate as GoalDate[];
      const dataSelectDate = dataAllDateWithType?.find((dataDate: GoalDate) => dataDate.goal_date_id === value);

      if (dataSelectDate) {
        const newFilter: FilterBarSelectionReportingObjectiveSales = {
          ...initFetch,
          goal_date: event.target.value,
          from: moment().quarter(dataSelectDate.quarter).year(dataSelectDate.year).startOf('quarter').local(),
          to: moment().quarter(dataSelectDate.quarter).year(dataSelectDate.year).endOf('quarter').local()
        };
        onFilterChange(newFilter);
      }
    },
    [initFetch, onFilterChange, dataAllDate]
  );

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <Typography variant="h4">{t('Target date')}</Typography>
          <Box flexDirection="column" display="flex" my={1}>
            <Box>
              <Typography variant="h5">
                {t('Start Date')} : {initFetch?.from && moment(initFetch.from).format('YYYY-M-D')}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h5">
                {t('End Date')}: {initFetch?.to && moment(initFetch.to).format('YYYY-M-D')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      }
    >
      <Grid item {...gridBreakpoints}>
        <SelectGoal
          multiple
          disabled={!!initFetch?.category?.length}
          from={initFetch.from}
          to={initFetch.to}
          label="marketplace"
          marketplace
          inputProps={{
            value: initFetch.canal,
            onChange: handleChangeCanal
          }}
        />
      </Grid>
      <Grid item {...gridBreakpoints}>
        <SelectGoal
          multiple
          from={initFetch.from}
          to={initFetch.to}
          label="product"
          disabled={!!initFetch?.canal?.length}
          inputProps={{
            value: initFetch.category,
            onChange: handleChangeCategory
          }}
        />
      </Grid>
      <Grid item {...gridBreakpoints}>
        <SelectGoalDate
          inputProps={{
            value: initFetch?.goal_date,
            onChange: handleChangeDate
          }}
        />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
