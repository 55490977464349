import React, { useEffect, useMemo } from 'react';
import { AnyObject } from '../../types/types';
import { useTranslation } from '../../services/translation';
import { SelectAutocomplete } from '../SelectAutocomplete';
import { useGoalListDateLibbyCall } from '../../business';
import { GoalDate } from '../../interfaces/business/goal/GoalDate';

type SelectGoalTypeProps = {
  multiple?: boolean;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label?: string;
};

export const SelectGoalDate = ({ multiple = false, inputProps = {}, optionAll = false, disabled = false, sort, label = 'Quarter' }: SelectGoalTypeProps) => {
  const { t } = useTranslation();
  const { data: dataAll, working } = useGoalListDateLibbyCall({
    methodName: 'getAllListDate'
  });

  const data = useMemo(() => {
    const dataGoal = dataAll as GoalDate[];
    return dataGoal?.length
      ? dataGoal.map(({ name, goal_date_id }: GoalDate, index: number) => ({
          id: goal_date_id,
          value: t(name),
          index
        }))
      : [];
  }, [dataAll, t]);

  useEffect(() => {
    const dataGoal = dataAll as GoalDate[];
    if (!inputProps.value && !!dataGoal?.length && !optionAll) {
      const event = {
        target: {
          value: dataGoal?.[0]?.goal_date_id
        }
      };
      inputProps.onChange(event);
    }
  }, [inputProps, dataAll, optionAll]);

  return <SelectAutocomplete id="goal_date_id" label={label} data={data} working={working} multiple={multiple} inputProps={inputProps} optionAll={optionAll} disabled={disabled} sort={sort} sortBy="index" />;
};
