import React, { useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import { TitleBreadcrumbs } from '../../interfaces';
import { useTranslation } from '../../services/translation';

const useStyles = makeStyles({
  label: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    font: 'inherit'
  }
});

interface MakeCellProps extends IconButtonProps {
  label: string;
  icon: React.ComponentType;
  path?: string;
  params?: { goBack: string | TitleBreadcrumbs[] };
  disabled?: boolean;
  search?: string;
}

export const MakeCellRaw = ({ label = '', icon: Icon, onClick, path = '', params, search, disabled = false }: MakeCellProps) => {
  const classes = useStyles();

  const newTo = useMemo(
    () => ({
      pathname: path,
      state: params,
      search
    }),
    [path, params, search]
  );

  const CustomLink = (props: LinkProps) => <Link {...props} to={newTo} />;

  const iconButtonProps: Object = {
    onClick: onClick || undefined,
    component: path ? CustomLink : 'span'
  };

  return (
    <label htmlFor="icon-button-file" className={classes.label}>
      <IconButton onClick={onClick} {...iconButtonProps} disabled={disabled}>
        <Icon />
      </IconButton>
      {label}
    </label>
  );
};

interface MakeCellChildrenProps extends IconButtonProps {
  label: string;
  children: JSX.Element[] | JSX.Element;
  directionLabel?: 'row' | 'row-reverse';
}

export const MakeCellRawChildren = ({ label = '', children, directionLabel = 'row' }: MakeCellChildrenProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <label htmlFor="icon-button-file" className={classes.label}>
      <Box flexDirection={directionLabel} display="flex" justifyContent="center" alignItems="center">
        {children}
        {t(label)}
      </Box>
    </label>
  );
};

export const MakeCellChildren = React.memo(MakeCellRawChildren);

export const MakeCell = React.memo(MakeCellRaw);
