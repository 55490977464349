import React, { useState, useCallback, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { TextField, Grid } from '@material-ui/core';
import { useTranslation } from '../../../../../services/translation';
import { makeFilter } from '../utils/makeFilter';
import useDebounce from '../../../../../hooks/useDebounce';
import { FilterBar } from '../../../../../components/FilterBar';

type SearchFiltersProps = {
  onFilterChange: Function;
  // eslint-disable-next-line react/no-unused-prop-types
  onCreateNewState?: Function;
  // eslint-disable-next-line react/no-unused-prop-types
  libby: any;
  // eslint-disable-next-line react/no-unused-prop-types
  working: boolean;
};

const SearchFiltersRaw = ({ onFilterChange }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const [nameSearch, setNameSearch] = useState<string>('');

  const citySearchDebounced = useDebounce(nameSearch, 500);

  useEffect(() => {
    const filters = makeFilter({
      name: citySearchDebounced
    });
    onFilterChange(filters);
  }, [citySearchDebounced, onFilterChange]);

  const handleChangeState = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNameSearch(event.target.value);
  }, []);

  return (
    <FilterBar
      defaultFilter={
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            id="standard-search"
            type="search"
            placeholder={t('Search')}
            value={nameSearch}
            onChange={handleChangeState}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
    />
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
