export const stockColumns = [
  {
    id: 'sku',
    label: 'Sku',
    orderById: 'sku'
  },
  {
    id: 'name',
    label: 'Products Name',
    orderById: 'name'
  },
  {
    id: 'stock_odoo',
    label: 'Odoo',
    orderById: 'stock_odoo'
  },
  {
    id: 'stock_enova',
    label: 'Enova',
    orderById: 'stock_enova'
  },
  {
    id: 'stock_novastore',
    label: 'Novastore',
    orderById: 'stock_novastore'
  },
  {
    id: 'stock_detecnologia',
    label: 'Detecnologia',
    orderById: 'stock_detecnologia'
  },
  {
    id: 'edit',
    label: 'Edit',
    noSort: true
  }
];
