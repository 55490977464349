import { BillingML, BillingMP, Order_reporting_billing, Row_order_reporting_billing } from '../../../interfaces/business/orders/Order_reporting_reporting_billings_ml';

export const columns = [
  {
    id: 'id',
    label: 'Order id',
    style: {
      minWidth: '125px'
    },
    render: ({ data: { order_id } }: Order_reporting_billing) => {
      return order_id;
    },
    noSort: true
  },
  {
    id: 'so_number',
    label: 'Number so',
    style: {
      minWidth: '140px'
    },
    render: ({ data: { so_number } }: Order_reporting_billing) => {
      return so_number;
    },
    noSort: true
  },
  {
    id: 'owner_id',
    label: 'Owner id',
    style: {
      minWidth: '140px'
    },
    render: ({ data: { owner_id } }: Order_reporting_billing) => {
      return owner_id;
    },
    noSort: true
  },
  {
    id: 'marketplace',
    label: 'Gateway',
    style: {
      minWidth: '140px'
    },
    render: ({ marketplace_type }: Row_order_reporting_billing) => {
      return marketplace_type;
    },
    noSort: true
  },
  {
    id: 'quantity_novatech_meli',
    label: 'Total Amount',
    style: {
      minWidth: '140px'
    },
    render: ({ billing, marketplace_type }: Row_order_reporting_billing) => {
      const billingMp = billing as BillingMP;
      const billingML = billing as BillingML;
      return marketplace_type === 'ML' ? billingML.sale_info.transaction_amount : billingMp.operation_info.transaction_amount;
    },
    noSort: true
  },

  {
    id: 'Change',
    label: 'Change',
    style: {
      minWidth: '140px'
    },
    render: ({
      billing: {
        charge_info: { detail_amount }
      }
    }: Row_order_reporting_billing) => {
      return detail_amount;
    },
    noSort: true
  },
  {
    id: 'discount',
    label: 'Discount',
    style: {
      minWidth: '140px'
    },
    render: ({ billing, marketplace_type }: Row_order_reporting_billing) => {
      const billingML = billing as BillingML;
      return marketplace_type === 'ML' ? billingML.discount_info.discount_amount : 0;
    },
    noSort: true
  },
  {
    id: 'quantity_novatech_meli',
    label: 'Percepcion',
    style: {
      minWidth: '140px'
    },
    render: ({ billing, marketplace_type }: Row_order_reporting_billing) => {
      const billingMp = billing as BillingMP;
      return marketplace_type === 'ML' ? 0 : billingMp.perception_info.taxable_amount;
    },
    noSort: true
  },
  {
    id: 'quantity_novatech_meli',
    label: 'Shipping',
    style: {
      minWidth: '140px'
    },
    render: ({ billing, marketplace_type }: Row_order_reporting_billing) => {
      const billingML = billing as BillingML;
      return marketplace_type === 'ML' ? billingML.shipping_info.receiver_shipping_cost || 0 : 0;
    },
    noSort: true
  }
];
