import React, { useCallback, useEffect, useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AnyObject } from '../../types/types';
import { GeneralSelect } from '../GeneralSelect';
import { useTranslation } from '../../services/translation';
import { DataGenerateSelectType } from '../../utils/generateSelect';
import { useReportingBillingPeriodsMlLibbyCall } from '../../business/orders/order/ReportingBillingPeriodsMl';
import { Billing_period } from '../../interfaces/business/orders/Order_reporting_billings_periods_ml';

type SelectBillingPeriodsMlProps = {
  multiple?: boolean;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  sort?: boolean | 'asc' | 'desc';
  label?: string;
  marketplace_id?: any;
};

export const SelectBillingPeriodsMl = ({ marketplace_id, multiple = false, inputProps = {}, optionAll = false, sort, label, ...styles }: SelectBillingPeriodsMlProps) => {
  const { t } = useTranslation();

  const {
    data: dataAll,
    working,
    recall
  } = useReportingBillingPeriodsMlLibbyCall({
    methodName: 'getAllWithMarketplace',
    noAutoCall: true
  });

  useEffect(() => {
    if (marketplace_id?.length) {
      recall?.(marketplace_id);
    }
  }, [recall, marketplace_id]);

  const data = useMemo(() => {
    const dataPeriods = dataAll as Billing_period[];
    return dataPeriods?.length
      ? dataPeriods.map(({ expiration_date, period: { date_from, date_to } }: Billing_period) => ({
          id: expiration_date.toString(),
          value: t(`From: $$$   -   To: $$$$`).replace('$$$', date_from.toString()).replace('$$$$', date_to.toString())
        }))
      : [];
  }, [dataAll, t]);

  const onChangeAutoComplete = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((marketplace: DataGenerateSelectType) => ({
          value: marketplace.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={!marketplace_id?.length}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          renderInput={(params) => <TextField {...params} variant="standard" label={t(`All periods`)} placeholder="" />}
        />
      ) : (
        <GeneralSelect disabled={!marketplace_id?.length} working={working} label="Periods" optionAll={optionAll} data={data} inputProps={inputProps} {...styles} sort={sort} />
      )}
    </>
  );
};
