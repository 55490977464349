import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AnyObject, InputProps, LibbyObject } from '../../types';
import { useLibbyCall } from '../../hooks';
import { GeneralSelect } from '../GeneralSelect';
import { GeneralAutocomplete } from '../GeneralAutocomplete';

type SelectDispatchProps = {
  inputProps: InputProps;
  [k: string]: any;
  libby: LibbyObject;
  label?: string;
  sort?: boolean | 'asc' | 'desc';
  multiple: boolean;
  disabled: boolean;
};

export const SelectCompanyRaw = ({ multiple = false, libby, inputProps, label = 'Company', disabled = false, sort, ...styles }: SelectDispatchProps) => {
  const { data: dataRaw, working = true } = useLibbyCall(libby, {
    daoName: 'ster_company',
    methodName: 'getAllCompany'
  });

  const data = useMemo(
    () =>
      dataRaw.map(({ company_id, name }: AnyObject) => ({
        id: company_id.toString(),
        value: name
      })),
    [dataRaw]
  );

  return (
    <>
      {multiple && inputProps.valueArray ? (
        <GeneralAutocomplete value={inputProps.valueArray} onChange={inputProps.onChange} working={working} label={label} disabled={disabled} data={data} id="select-company" />
      ) : (
        <GeneralSelect optionAll working={working} label={label} data={data} inputProps={{ ...inputProps, variant: 'outlined' }} {...styles} sort={sort} />
      )}
    </>
  );
};

export const SelectCompany = DatabaseConnector(SelectCompanyRaw)('ster_company');
