import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useReportingDeliveryControlLibbyFetch } from '../../../../../../business/orders/order/ReportingDeliveryControl';
import { useReportingDeliveryControlGroupLibbyCall } from '../../../../../../business/orders/order/ReportingDeliveryControlGroup';
import { ORDER_SOURCE_TYPE } from '../../../../../../const';
import { useBreadcrumbs, useFilerParamsId } from '../../../../../../hooks';
import { Order_reporting_delivery_control_group, Order_reporting_delivery_control_reporting } from '../../../../../../interfaces/business';
import { useTranslation } from '../../../../../../services/translation';
import { RowsType } from '../../../../../../types';
import { ButtonDetailsType } from '../../../../../../types/Button';
import { makeFilter } from '../utils/makeFilter';
import { KpiData } from '../../../../../components/Kpi';

const allOrderSourceType = [
  {
    id: 1,
    name: 'Online',
    source_type: [ORDER_SOURCE_TYPE.LICITACIONES, ORDER_SOURCE_TYPE.ONLINE]
  },
  {
    id: 2,
    name: 'Offline',
    source_type: [ORDER_SOURCE_TYPE.OFFLINE]
  }
];

interface RowGroup {
  id: number;
  title: string;
  data: RowsType[];
}

type AllQuantity = {
  quantity: number;
  quantity_effectiveness: number;
};

export const useReportingLogisticList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const title = useBreadcrumbs('Delivery control');
  const { t } = useTranslation();
  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'order_id_22',
    daoName: 'ster_reporting_delivery_control',
    init: makeFilter({ startDate: moment().startOf('month').subtract(1, 'months'), endDate: moment().endOf('month').subtract(1, 'months') })
  });

  const { data: allData, fetchMore, working } = useReportingDeliveryControlLibbyFetch(paramsFetch);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'non-working-days',
        onClick: () =>
          history.push({
            pathname: `${match.path}/non-working-day`,
            state: {
              goBack: title
            }
          }),
        title: t('Non-working days'),
        variant: 'contained'
      },
      {
        id: 'updatedAddOrder',
        onClick: () =>
          history.push({
            pathname: `${match.path}/sla`,
            state: {
              goBack: title
            }
          }),
        title: t('Settings SLA'),
        variant: 'contained'
      }
    ],
    [title, history, t, match.path]
  );

  const rows: RowsType[] = useMemo(() => {
    const dataOrder = allData as Order_reporting_delivery_control_reporting[];

    return dataOrder.length
      ? dataOrder.map((dataReporting: Order_reporting_delivery_control_reporting) => ({
          id: dataReporting.order_id_22,
          ...dataReporting
        }))
      : [];
  }, [allData]);

  const {
    data: allDataReportingDeliveryControlGroup = [],
    working: workingReportingDeliveryControlGroup,
    recall
  } = useReportingDeliveryControlGroupLibbyCall({ noAutoCall: true, methodName: 'getAllReportingDeliveryControlGroupByDate' });

  useEffect(() => {
    recall?.({
      from: filter.startDate?.[0]?.value,
      to: filter.endDate?.[0]?.value,
      search: filter.search?.[0]?.value,
      effectiveness: filter.effectiveness?.[0]?.value,
      company: filter.company?.[0]?.value,
      marketplace: filter.marketplace,
      courier: filter.courier,
      canal: filter.canal
    });
  }, [filter, recall]);

  const rowsGroup = useMemo(() => {
    const dataOrderGroup = allDataReportingDeliveryControlGroup as Order_reporting_delivery_control_group[];
    const dataOrderAll = dataOrderGroup?.[0]?.reporting_group || [];
    return allOrderSourceType.reduce((allMarketplaceGroupSourceType: RowGroup[], orderSourceType) => {
      const copyAllMarketplaceGroupSourceType = [...allMarketplaceGroupSourceType];

      const allDataGroupSourceType = dataOrderAll?.length ? dataOrderAll?.filter(({ order_source_type_id }) => orderSourceType.source_type.includes(parseInt(order_source_type_id, 10))) : [];
      const allTotalOrderByGroupSourceType = allDataGroupSourceType.reduce((quantityDataOrderGroup, dataOrderSourceType) => parseInt(dataOrderSourceType.quantity, 10) + quantityDataOrderGroup, 0);

      const data = allDataGroupSourceType.map((orderGroup) => ({
        id: orderGroup.marketplace_id,
        ...orderGroup,
        quantity_percentage: (parseInt(orderGroup.quantity, 10) * 100) / allTotalOrderByGroupSourceType,
        effectiveness: (orderGroup.quantity_effectiveness * 100) / parseInt(orderGroup.quantity, 10)
      }));

      copyAllMarketplaceGroupSourceType.push({
        id: orderSourceType.id,
        title: orderSourceType.name,
        data
      });

      return copyAllMarketplaceGroupSourceType;
    }, []);
  }, [allDataReportingDeliveryControlGroup]);

  const allQuantityOrderSource = useMemo(() => {
    return rowsGroup.length
      ? rowsGroup.reduce((quantity: any, rowGroup: RowGroup) => {
          const copyQuantity = [...quantity];
          const quantityByData = rowGroup.data.reduce(
            (quantityData: AllQuantity, data: RowsType) => ({
              quantity: quantityData.quantity + parseInt(data.quantity, 10),
              quantity_effectiveness: quantityData.quantity_effectiveness + parseInt(data.quantity_effectiveness, 10)
            }),
            {
              quantity: 0,
              quantity_effectiveness: 0
            }
          );
          copyQuantity.push({
            id: rowGroup.id,
            quantity: quantityByData
          });
          return copyQuantity;
        }, [])
      : [];
  }, [rowsGroup]);

  const quantityAllData = useMemo(() => {
    const dataOrderGroup = allDataReportingDeliveryControlGroup as Order_reporting_delivery_control_group[];
    const dataKpiAll = dataOrderGroup?.[0]?.reporting_kpi || {
      quantity: 0,
      quantity_day: 0
    };

    return {
      quantity_day: dataKpiAll.quantity_day,
      quantity: dataKpiAll.quantity
    };
  }, [allDataReportingDeliveryControlGroup]);

  const allKpi: KpiData[] = useMemo(() => {
    const {
      quantity: { quantity_effectiveness: quantityEffectivenessOnline, quantity: quantityOnline }
    } = allQuantityOrderSource.find(({ id }) => id === ORDER_SOURCE_TYPE.ONLINE);
    const {
      quantity: { quantity_effectiveness: quantityEffectivenessOffline, quantity: quantityOffline }
    } = allQuantityOrderSource.find(({ id }) => id === ORDER_SOURCE_TYPE.OFFLINE);

    return [
      {
        data: [
          {
            title: 'Working days',
            value: quantityAllData.quantity_day
          },
          {
            title: 'Average orders per day',
            value: `${quantityAllData.quantity_day > 0 ? Math.ceil(quantityAllData.quantity / quantityAllData.quantity_day) : 0}`
          },
          {
            title: 'Number of online orders',
            value: quantityOnline
          },
          {
            title: 'Number of offline orders',
            value: quantityOffline
          },
          {
            title: 'Effectiveness online',
            value: `${quantityOnline > 0 ? ((quantityEffectivenessOnline * 100) / quantityOnline).toFixed(2) : 0}%`
          },
          {
            title: 'Effectiveness offline',
            value: `${quantityOffline > 0 ? ((quantityEffectivenessOffline * 100) / quantityOffline).toFixed(2) : 0}%`
          }
        ]
      }
    ];
  }, [allQuantityOrderSource, quantityAllData]);

  return { title, buttons, working, direction, orderBy, handleRequestSort, setFilter, filter, rows, fetchMore, workingReportingDeliveryControlGroup, rowsGroup, allKpi, quantityAllData };
};
