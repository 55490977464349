import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { IconButton, Badge, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Notifications } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useLibbyCall, useLibbyFetch } from '../../hooks';
import { LibbyObject } from '../../types';
import GlobalContext from '../MenuDrawer/context';
import { useTranslation } from '../../services/translation';
import { useAlertModal } from '../Alerts/AlertModal';
import { ACCOUNT_ROLE } from '../../const';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiBadge-badge': {
      top: '15%',
      right: '20%'
    }
  }
}));

const InboxOrderProblemButtonRaw = ({ libby }: { libby: LibbyObject }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const paramsFetch = useMemo(() => ({ daoName: 'ster_inbox_order_custom' }), []);
  const paramsFetchSter = useMemo(() => ({ daoName: 'ster_ster_config', methodName: 'getSterConfigFailure' }), []);
  const { data, reFetch } = useLibbyFetch(libby, paramsFetch);
  const { data: dataSter } = useLibbyCall(libby, paramsFetchSter);
  const { isLogged, userInfo } = useContext(GlobalContext);
  const { validateAlert } = useAlertModal({ libby });
  const history = useHistory();

  useEffect(() => {
    reFetch();
  }, [isLogged, reFetch]);

  const isAdmin = useMemo(() => userInfo?.groups?.[0]?.id === ACCOUNT_ROLE.ADMINISTRATOR, [userInfo]);
  const handleAlert = useCallback(async () => {
    if (isAdmin) {
      await validateAlert();
    } else {
      history.push('/inbox-orders');
    }
  }, [history, isAdmin, validateAlert]);
  const isDataSterPlural = useMemo(() => (dataSter.length > 1 ? 'es' : ''), [dataSter]);
  const isDataPlural = useMemo(() => (data.length > 1 ? 's' : ''), [data]);
  const tooltipTitle = useMemo(
    () => (isAdmin ? `${dataSter.length} ${t(`process${isDataSterPlural} with problems`)}` : `${data.length} ${t(`order${isDataPlural} with problems`)}`),
    [isDataPlural, isDataSterPlural, isAdmin, dataSter, data, t]
  );

  return data && data.length ? (
    <Tooltip title={tooltipTitle}>
      <Badge className={classes.root} badgeContent={isAdmin ? dataSter.length : data.length} color="error">
        <IconButton onClick={handleAlert}>
          <Notifications fontSize="large" color="primary" />
        </IconButton>
      </Badge>
    </Tooltip>
  ) : (
    <></>
  );
};

export const InboxOrderProblemButton = DatabaseConnector(InboxOrderProblemButtonRaw)('ster_inbox_order_custom', 'ster_ster_config');
