import { Column } from '../../../../components/InfoTable';

export const columnsReportingGoalSaleSettingFieldAdd: Array<Column> = [
  {
    id: 'name',
    label: 'Name',
    style: {
      whiteSpace: 'nowrap'
    }
  }
];

export const columnsReportingGoalSaleSettingFieldProductsAdd: Array<Column> = [
  {
    id: 'sku',
    label: 'Sku'
  }
];
