import React, { useEffect } from 'react';
import { useTranslation } from '../../../../../services/translation';
import LoadingData from '../../../../components/LoadingData';
import { SearchFilters } from './SearchFilters';
import { AnyObject } from '../../../../../types/types';
import InfoTable from '../../../../components/InfoTable';

type RowsType = {
  id: string;
  [k: string]: any;
};

type HeadCellsType = {
  id: string;
  label: string;
  translate?: boolean;
  render?: (row: any, item: any, t: (str: string) => string) => React.ReactNode;
  [k: string]: any;
};

type OrderTableProps = {
  setFilter: (makeFilter: object) => void;
  filter: AnyObject;
  working: boolean;
  rows: Array<RowsType>;
  columns: Array<HeadCellsType>;
  fetchMore: () => void;
  reFetch?: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  onRowClick?: (row: RowsType) => void;
  canalOffLine?: boolean;
  resetFilter: () => void;
};

export const OrderTable = ({ columns, rows, working, filter, setFilter, fetchMore, reFetch, onSortChange, orderBy, direction, onRowClick, canalOffLine = false, resetFilter }: OrderTableProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (reFetch) reFetch();
  }, [reFetch]);

  return (
    <>
      <SearchFilters onFilterChange={setFilter} initFetch={filter} canalOffLine={canalOffLine} resetFilter={resetFilter} />
      <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} rowIdKey="order_id" onRowClick={onRowClick} onSortChange={onSortChange} orderBy={orderBy} direction={direction} />

      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
    </>
  );
};
