import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class GoalListDateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_list_date', 'product_id');
  }

  getAllListDate() {
    return this.query().limit(0, 10000).run();
  }
}
