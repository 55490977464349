import React, { ReactNode, useState, useCallback, useMemo } from 'react';
import { Grid, Box, Collapse, Typography, IconButton } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Search from '@material-ui/icons/Search';
import { useSnackbar } from 'notistack';
import { useTranslation } from '../../../services/translation';
import { ScreenComponent } from '../types';
import { FilterManagerModal } from '../components/FilterManagerModal/FilterManagerModal';
import { FilterManagerDialogComponent } from '../components/FilterManageDialogComponent/FilterManageDialog';
import { useFilterDAO } from '../../../business/Filter';
import { Filter } from '../../../interfaces/business';
import { userInfoType } from '../../../types';
import { useGlobalContext } from '../../../hooks';

interface MakeFilterBarProps<T = any> {
  onFilter: (filter: Filter<T>) => void;
  initExpanded?: boolean;
  filter: Filter<T>;
  working?: boolean;
  defaultFilter?: ReactNode;
  handleReset?: () => void;
  withSaved?: boolean;
  initialFilter: Filter<T>;
}

export const makeFilterBar = <T,>(component?: ScreenComponent<T>) => {
  const Comp = component?.render;
  return ({ initExpanded = false, filter, initialFilter, working = false, defaultFilter, withSaved = false, handleReset, onFilter }: MakeFilterBarProps<T>) => {
    const [expanded, setExpanded] = useState(initExpanded);
    const [isSave, setIsSave] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const filterDAO = useFilterDAO();

    const { t } = useTranslation();

    const { userInfo }: { userInfo: userInfoType } = useGlobalContext();

    const isNewFilter = useMemo(() => !filter.filter_id, [filter]);

    const handleActionButton = useCallback(async () => {
      if (isSave) {
        setOpenModal(true);
      } else {
        const { filter_id, ...rest } = filter;
        onFilter({ ...initialFilter, ...rest });
        setIsSave(true);
      }
    }, [isSave, filter, initialFilter, onFilter]);

    const openFilters = useCallback(() => {
      setOpenModal(true);
      setIsSave(false);
    }, []);

    const onConfirm = useCallback(
      async (value: any) => {
        if (isSave) {
          try {
            const dataToSave: Omit<Filter<T>, 'filter_id' | 'last_update'> = {
              ...value,
              account: { account_id: userInfo?.id || '', username: userInfo?.userName || '' },
              screen: { screen_id: component?.screen_id || '', name: '' },
              metadata: filter.metadata
            };
            await filterDAO.save(dataToSave);
            onFilter({ ...filter, ...dataToSave });
            enqueueSnackbar(`${t('Successful operation')}`, {
              variant: 'success'
            });
            setOpenModal(false);
            setIsSave(false);
          } catch (error) {
            enqueueSnackbar(`${t('An error occurred')}: ${error}`, {
              variant: 'error'
            });
          }
        }
      },
      [filterDAO, isSave, userInfo, filter, enqueueSnackbar, t, onFilter]
    );

    const onCancel = useCallback(() => {
      setOpenModal(false);
      setTimeout(() => {
        setIsSave(false);
      }, 10);
    }, []);

    const onGoBack = useCallback((value?: boolean) => {
      setOpenModal(false);
      setTimeout(() => setIsSave(value || false), 10);
    }, []);

    return (
      <Grid container>
        <Box mb={2} width="100%" display="flex">
          <Grid item xs={6} container alignContent="center">
            <Grid item>
              <Grid container>
                <Typography align="center">{`Filtro: ${filter.name}`}</Typography>
              </Grid>
            </Grid>
            {defaultFilter && defaultFilter}
          </Grid>
          <Grid item xs={6} container direction="row" justify="flex-end" alignItems="flex-end">
            <Grid item>
              <Collapse in={expanded} timeout="auto">
                {withSaved && (
                  <>
                    <IconButton onClick={handleActionButton}>
                      <Typography variant="h5">{isSave ? t('Save') : t('Create filter')}</Typography>
                    </IconButton>

                    <IconButton onClick={openFilters}>
                      <Search />
                    </IconButton>
                  </>
                )}
                {!!handleReset && (
                  <IconButton onClick={handleReset}>
                    <Typography variant="h5">{t('Clear Filters')}</Typography>
                  </IconButton>
                )}
              </Collapse>
            </Grid>

            {!!Comp && (
              <Grid item>
                <IconButton onClick={() => setExpanded(!expanded)}>
                  <Typography variant="h5">{t('filters')}</Typography>
                  {expanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Box>

        {!!Comp && <Comp {...{ expanded, filter, working, onFilter }} />}

        {component && withSaved && (
          <FilterManagerDialogComponent
            maxWidth={isSave ? 'sm' : 'md'}
            open={openModal}
            onCancel={onCancel}
            onConfirm={onConfirm}
            onGoBack={onGoBack}
            title={t(isSave ? (!isNewFilter ? 'Edit filter' : 'New filter') : 'Filter management')}
            cancelText={t('Cancel')}
            confirmText={t('Save')}
            goBackText={t('Go back')}
            props={{ filter, onFilter, component, toSave: isSave, setToSave: setIsSave, onGoBack, newValue: isNewFilter }}
            render={FilterManagerModal}
          />
        )}
      </Grid>
    );
  };
};
