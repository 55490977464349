import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useFilerParamsId, useLibbyFetch } from '../../../hooks';
import confirmDialog from '../../../services/confirmDialog';
import { useTranslation } from '../../../services/translation';
import { useMessageConfigAlertType } from '../types/types';
import { FormDialog } from '../components/DialogEditMessageConfig';
import CustomModal from '../../../services/customFormDialog';
import { formInitialValues, validate } from '../model/model';
import { Message_config_alert_template } from '../../../interfaces/business/message/Message_config_alert_with_template';

const EditDialogModal = CustomModal(FormDialog);

export const useMessageConfigAlert = ({ libby }: useMessageConfigAlertType) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isValidNewMessage, setIsValidNewMessage] = useState(false);

  const { orderBy, direction, paramsFetch, handleRequestSort } = useFilerParamsId({
    orderInit: 'message_config_alert_id',
    daoName: 'ster_message_config_alert',
    aspect: 'message_config_alert_list'
  });

  const { data: messageConfigAlert, working, addCreate, removeData, updateData } = useLibbyFetch(libby, paramsFetch);

  const onConfirmCreate = useCallback(async () => {
    const confirm: any = await EditDialogModal.show({
      title: t('Create Message Alert Settings'),
      confirmText: `${t('Save')}`,
      cancelText: t('Cancel'),
      formInitialValues,
      validate,
      isValidNewMessage
    });
    if (confirm) {
      const { alert, template } = confirm;
      try {
        setIsValidNewMessage(!isValidNewMessage);
        const newMessageConfigSaved = await libby.ster_message_config_alert_update.aspect('message_config_alert_list').save({
          alert,
          template
        });

        addCreate(newMessageConfigSaved);
        enqueueSnackbar(`${t('Successfully created message configuration')} ${newMessageConfigSaved.message_config_alert_id}`, { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(`${t('Error creating message configuration')}`, {
          variant: 'error'
        });
      }
    }
  }, [addCreate, enqueueSnackbar, libby, t, isValidNewMessage, setIsValidNewMessage]);

  const deleteMessageConfig = useCallback(
    async (message_config_alert_id: string) => {
      const confirm = await confirmDialog.show({
        title: t('Delete Message Alerts Settings'),
        content: t('Are you sure of perform this action?'),
        confirmText: `${t('Delete')}`,
        cancelText: t('Cancel')
      });
      if (confirm) {
        try {
          await libby.ster_message_config_alert_update.aspect('message_config_alert_list').remove({ message_config_alert_id });
          removeData(message_config_alert_id, 'message_config_alert_id');
          enqueueSnackbar(t('Message deleted alert settings'), { variant: 'success' });
        } catch (e) {
          enqueueSnackbar(`${t('Error deleting message configuration')}`, {
            variant: 'error'
          });
        }
      }
    },
    [enqueueSnackbar, removeData, t, libby]
  );

  const openMessageConfigEdit = useCallback(
    async ({ alert: { alert_id }, template: { message_template_id }, message_config_alert_id }: Message_config_alert_template) => {
      const formInitialValuesEdit = {
        alert: alert_id,
        template: message_template_id
      };

      try {
        const confirmEdit: any = await EditDialogModal.show({
          title: 'Edit Message Alert Settings',
          confirmText: 'Save',
          formInitialValues: formInitialValuesEdit
        });
        if (confirmEdit) {
          const { alert, template } = confirmEdit;
          const newMessageConfig: Message_config_alert_template = await libby.ster_message_config_alert_update.aspect('message_config_alert_list').save({
            message_config_alert_id,
            alert: {
              alert_id: alert
            },
            template: {
              message_template_id: template
            }
          });
          updateData(newMessageConfig, 'message_config_alert_id');
          enqueueSnackbar(`${t('Message configuration $$$$ saved').replace('$$$$', message_config_alert_id)}`, { variant: 'success' });
        }
      } catch (e) {
        if (e)
          enqueueSnackbar(`${t('Error deleting message configuration')}: ${e.message}`, {
            variant: 'error'
          });
      }
    },
    [libby, enqueueSnackbar, t, updateData]
  );

  return {
    onConfirmCreate,
    messageConfig: messageConfigAlert as Message_config_alert_template[],
    working,
    deleteMessageConfig,
    openMessageConfigEdit,
    handleRequestSort,
    orderBy,
    direction
  };
};
