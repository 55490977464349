import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useFilerParamsId, useLibbyFetch } from '../../../hooks';
import { Courier } from '../../../interfaces/business';
import { LibbyObject } from '../../../types';
import CustomModal from '../../../services/customFormDialog';
import { CourierForm } from '../routes/CouriersList/components';
import { useTranslation } from '../../../services/translation';
import { formInitialValues, validate } from '../utils';

interface useCourierLogicProps {
  libby: LibbyObject;
}

const CreateCourierModal = CustomModal(CourierForm);

export const useCourierLogic = ({ libby }: useCourierLogicProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { orderBy, direction, paramsFetch, handleRequestSort } = useFilerParamsId({
    orderInit: 'courier_id',
    daoName: 'courier_order'
  });

  const { data, working, fetchMore, updateData, addCreate } = useLibbyFetch(libby, paramsFetch);

  const onCreateCourier = useCallback(
    async (currentCourier?: Courier) => {
      try {
        const formData = (await CreateCourierModal.show({
          confirmText: t(currentCourier ? 'Update' : 'Create'),
          cancelText: t('Cancel'),
          title: t(currentCourier ? 'Update Courier' : 'Create Courier'),
          validate,
          formInitialValues: currentCourier || formInitialValues
        })) as Courier;
        if (!formData) return;
        const courier = await libby.courier_order.save({
          ...(currentCourier && { ...currentCourier }),
          ...formData
        });
        if (currentCourier?.courier_id) {
          updateData(courier, 'courier_id');
        } else {
          addCreate(courier);
        }
        enqueueSnackbar(t('Courier saved'), { variant: 'success' });
      } catch (error) {
        if (error !== false) {
          enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        }
      }
    },
    [t, libby.courier_order, addCreate, updateData, enqueueSnackbar]
  );

  return {
    data,
    working,
    fetchMore,
    handleRequestSort,
    orderBy,
    direction,
    onCreateCourier
  };
};
