import { Theme, makeStyles } from '@material-ui/core/styles';
import { mainlight, red } from '../../../../../theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  pictureLarge: {
    width: '100%',
    height: '200px',
    objectFit: 'contain',
    objectPosition: 'center'
  },
  check: {
    background: theme.palette.grey[600],
    width: 14,
    height: 14,
    borderRadius: '50%',
    position: 'relative',
    left: '-13px'
  },
  checkApprove: {
    background: theme.palette.common.white,
    width: 14,
    height: 14,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    position: 'relative',
    left: '-13px'
  },
  approveIcon: {
    width: 16,
    height: 16,
    color: theme.palette.primary.main,
    position: 'relative',
    left: '-2px',
    top: '-2px'
  },
  approveIconHidden: {
    display: 'none'
  },
  lineApprove: {
    background: theme.palette.primary.main,
    width: 3,
    height: 80
  },
  line: {
    background: theme.palette.grey[600],
    width: 3,
    height: 80
  },
  collectProductInfoBox: {
    display: 'grid',
    padding: '0 5px 10px 10px',
    height: '21rem'
  },
  nameSKU: {
    padding: '0px 5px',
    borderLeft: `2px solid ${theme.palette.grey[700]}`,
    display: 'grid',
    gridTemplateColumns: '5px 1fr',
    position: 'relative'
  },
  nameSKUApprove: {
    padding: '0px 5px',
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    display: 'grid',
    gridTemplateColumns: '5px 1fr',
    position: 'relative'
  },
  cartBox: {
    padding: '0px 5px',
    display: 'grid',
    gridTemplateColumns: '5px 1fr',
    position: 'relative',
    left: 2
  },
  skuAndSerial: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    margin: '10px 0'
  },
  location: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0'
  },
  textField: {
    margin: '10px 0',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none'
    },
    '& input': {
      background: theme.palette.common.white,
      padding: '6px 1px'
    },
    '& input:focus': {
      background: theme.palette.grey[50]
    }
  },
  serialNumber: {
    background: mainlight,
    color: theme.palette.primary.main,
    padding: '5px 15px',
    borderRadius: 5,
    textAlign: 'center'
  },
  serialNumberApproved: {
    background: theme.palette.grey[200],
    color: theme.palette.grey[400],
    padding: '5px 15px',
    borderRadius: 5,
    textAlign: 'center'
  },
  sku: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 11
  },
  skuApproved: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 11,
    color: theme.palette.grey[400]
  },
  cartBoxText: {
    position: 'relative',
    top: '-4px',
    left: 1
  },
  titleIcon: {
    width: 16,
    height: 16,
    background: red,
    borderRadius: '50%',
    display: 'block'
  },
  modalTitle: {
    display: 'grid',
    gridTemplateColumns: '18px 1fr',
    alignItems: 'center'
  },
  iconStyle: {
    position: 'relative',
    top: '-2px',
    left: '-3px',
    color: theme.palette.common.white
  },
  titleText: {
    fontSize: 16,
    textTransform: 'capitalize'
  },
  tapButton: {
    background: 'transparent',
    border: 'none',
    textAlign: 'left'
  },
  pointer: {
    cursor: 'pointer'
  },
  label: {
    color: theme.palette.grey[400]
  },
  textCartDisable: {
    color: theme.palette.grey[400]
  },
  cardBox: {
    position: 'relative'
  },
  collected: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5
  },
  collectCircle: {
    width: 60,
    height: 60,
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: '50%'
  },
  itemProductChecked: {
    color: theme.palette.primary.main,
    fontSize: 60
  },
  whiteSpace: {
    width: 20
  },
  position: {
    position: 'relative',
    top: '-10px',
    left: 3,
    '& h6': {
      fontSize: 12
    }
  }
}));
