import React from 'react';
import { Grid } from '@material-ui/core';
import { Filter } from '../../../../types';
import { useInitFilter } from '../../../OrderReport/routes/OrderReportList/hook';
import { gridBreakpointsFields } from '../../../../const';
import { FilterBar } from '../../../../components/FilterBar';
import { makeFilter } from '../../utils';
import { SelectMpMl } from '../../../../components/SelectMlMp';
import { SelectBillingPeriodsMl } from '../../../../components/SelectBillingPeriodsMl';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: Filter;
};

const SearchFiltersRaw = ({ onFilterChange, filter }: SearchFiltersProps) => {
  const { search: marketplace_id, handleChangeFilter: handleChangeMarketplace } = useInitFilter({
    initFetch: filter.marketplace_id,
    init: '',
    onFilterChange,
    id: 'marketplace_id',
    makeFilter
  });

  const { search: billingPeriod, handleChangeFilter: handleChangeBillingPeriod } = useInitFilter({
    initFetch: filter.billing_period,
    init: '',
    onFilterChange,
    id: 'billing_period',
    makeFilter
  });

  return (
    <FilterBar
      defaultFilter={
        <Grid container spacing={4}>
          <Grid item {...gridBreakpointsFields}>
            <SelectMpMl
              inputProps={{
                value: marketplace_id,
                onChange: handleChangeMarketplace
              }}
            />
          </Grid>

          <Grid item {...gridBreakpointsFields}>
            <SelectBillingPeriodsMl
              marketplace_id={marketplace_id}
              inputProps={{
                value: billingPeriod,
                onChange: handleChangeBillingPeriod
              }}
              sort="desc"
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
