import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import React from 'react';
import { LibbyObject } from '../../types';
import { StockList } from './routes/StockList';

interface StockRawType {
  libby: LibbyObject;
}

const StockRaw = ({ libby }: StockRawType) => {
  return <StockList title="Stock" libby={libby} />;
};

export const Stock = DatabaseConnector(StockRaw)('ster_product_stock', 'ster_product_stock_odoo', 'ster_product_stock_odoo_update');
