import Moment from 'moment';
import { subFilter } from '../../../../types';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

type FilterBarSelection = {
  from?: Date;
  to?: Date;
  search?: string;
  effectiveness?: number;
  courier?: subFilter[];
  marketplace?: subFilter[];
  company?: subFilter[];
  canal?: subFilter[];
};

export class OrderReportingDeliveryControlGroupDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_reporting_delivery_control_group', 'order_id_22');
  }

  getAllReportingDeliveryControlGroup() {
    return this.query().limit(0, 10000).run();
  }

  async getAllReportingDeliveryControlGroupByDate(filter: FilterBarSelection) {
    let ls = this.query();
    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;

    if (filter.from && filter.to) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart().higherOrEquals('created_at', Moment(filter.from).format('YYYY-M-D HH:mm:ss')).lowerOrEquals('created_at', Moment(filter.to).format('YYYY-M-D HH:mm:ss')).groupEnd();
      needsAnd = true;
    }

    if (filter?.search && filter?.search?.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.includes('search', filter?.search);
      needsAnd = true;
    }

    if (filter?.effectiveness) {
      if (needsAnd) ls = ls.and();
      ls = ls.equals('effectiveness', filter?.effectiveness);
      needsAnd = true;
    }

    if (filter?.courier?.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.courier.forEach((courier) => {
        if (!first) ls = ls.or();
        ls = ls.equals('courier_id', courier.value);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }
    if (filter?.marketplace?.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.marketplace.forEach((marketplace) => {
        if (!first) ls = ls.or();
        ls = ls.equals('marketplace_id', marketplace.value);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }
    if (filter?.company?.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.company.forEach((company) => {
        if (!first) ls = ls.or();
        ls = ls.equals('company_id', company.value);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }

    if (filter?.canal?.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.canal.forEach((canal) => {
        if (!first) ls = ls.or();
        ls = ls.equals('canal_id', canal.value);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }
    return ls.run();
  }
}
