import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { useFilerParamsId, useLibbyCall } from '../../../hooks';
import { LibbyObject } from '../../../types';
import customModal from '../../../services/customFormDialog';
import { StockEditDialog } from '../components/StockEditDialog';
import { validate } from '../utils';
import { useTranslation } from '../../../services/translation';
import { ProductStockType } from '../../../interfaces/business';
import { ProductStock } from '../../../interfaces/business/product';

interface RowsType {
  id: string;
  edit: JSX.Element;
  product: string;
  description: string;
  odoo: number;
  enova: number | JSX.Element;
  novatech: number | JSX.Element;
  detecnologia: number | JSX.Element;
}

const StockDialog = customModal(StockEditDialog);

export const useStockLogic = (libby: LibbyObject) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { data, working } = useLibbyCall(libby, { daoName: 'ster_product_stock_odoo', methodName: 'getProducts' });

  const [allData, setAllData] = useState(data);

  useEffect(() => {
    setAllData(data);
  }, [data]);

  const updateData = useCallback(
    (dataUpdate: ProductStock) => {
      setAllData((prev: ProductStock[]) => {
        const copy: ProductStock[] = [...prev];
        const result = copy.findIndex((copyAllData: ProductStock) => {
          return copyAllData.sku === dataUpdate.sku;
        });
        copy[result] = dataUpdate;
        return copy;
      });
    },
    [setAllData]
  );

  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);

  const { filter, setFilter } = useFilerParamsId({
    orderInit: 'product_id',
    daoName: 'ster_product_stock_odoo'
  });

  const resetFilter = useCallback(() => {
    setFilter({});
  }, [setFilter]);

  const handleEditStock = useCallback(
    async (dataInner: ProductStockType) => {
      const formInitialValues = {
        stock_enova: dataInner.stock_enova,
        stock_novastore: dataInner.stock_novastore,
        stock_detecnologia: dataInner.stock_detecnologia
      };
      const confirm: any = await StockDialog.show({
        dataInner,
        formInitialValues,
        validate,
        title: t('Edit Stock'),
        confirmText: t('Save'),
        cancelText: t('Cancel'),
        reset
      });
      if (confirm) {
        const { stock_enova, stock_novastore, stock_detecnologia } = confirm;

        try {
          setLoading(true);
          const newProductStock: ProductStock[] = await libby.ster_product_stock_odoo_update.save({
            description: dataInner.description,
            product_id: dataInner.product_id,
            name: dataInner.name,
            sku: dataInner.sku,
            stock_odoo: dataInner.stock_odoo,
            stock_enova: stock_enova === 0 || stock_enova === '0' ? 0 : parseInt(stock_enova, 10) || null,
            stock_novastore: stock_novastore === 0 || stock_novastore === '0' ? 0 : parseInt(stock_novastore, 10) || null,
            stock_detecnologia: stock_detecnologia === 0 || stock_detecnologia === '0' ? 0 : parseInt(stock_detecnologia, 10) || null
          });
          if (newProductStock) {
            updateData(newProductStock[0]);
            enqueueSnackbar(`${dataInner.name} ${t('Stock Saved')}`, { variant: 'success' });
            setLoading(false);
          } else {
            enqueueSnackbar(t('Something went wrong'), { variant: 'error' });
            setLoading(false);
          }
        } catch (e) {
          setReset(!reset);
          enqueueSnackbar(t('Something went wrong'), { variant: 'error' });
          setLoading(false);
        }
      }
    },
    [t, reset, enqueueSnackbar, libby.ster_product_stock_odoo_update, updateData]
  );

  const dataNew: any = useMemo(() => {
    const valueName = filter?.name?.[0]?.value.toLowerCase();
    const valueSku = filter?.sku?.[0]?.value.toLowerCase();
    const valueSearch = filter?.search?.[0]?.value.toLowerCase();
    let dataReturn: ProductStockType[] = [...allData] as ProductStockType[];
    if (valueName) {
      const name = dataReturn.filter((dataFilter: ProductStockType) => {
        const nameSubFilter = dataFilter.name.toLowerCase();
        return nameSubFilter.includes(valueName);
      });
      dataReturn = name;
    }
    if (valueSku) {
      const sku = dataReturn.filter((dataFilter: ProductStockType) => {
        const skuSubFilter = dataFilter.sku.toLowerCase();
        return skuSubFilter.includes(valueSku);
      });
      dataReturn = sku;
    }
    if (valueSearch) {
      const search = dataReturn.filter((dataFilter: ProductStockType) => {
        return dataFilter.sku.toLowerCase().includes(valueSearch) || dataFilter.name.toLowerCase().includes(valueSearch);
      });
      dataReturn = search;
    }

    return dataReturn;
  }, [allData, filter]);

  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState('stock_odoo');

  const rows: RowsType[] = useMemo(() => {
    // const dataFilter = data.filter((row: ProductStockType) => (row.stock_novastore !== null && row.stock_enova !== null ? true : row.stock_novastore !== null ? true : row.stock_enova !== null));
    return dataNew.map((order: ProductStockType) => ({
      name: order.name,
      sku: order.sku,
      stock_odoo: order.stock_odoo,
      stock_enova: order.stock_enova === 0 ? 0 : order.stock_enova || 'N/A',
      stock_novastore: order.stock_novastore === 0 ? 0 : order.stock_novastore || 'N/A',
      stock_detecnologia: order.stock_detecnologia === 0 ? 0 : order.stock_detecnologia || 'N/A',
      edit: (
        <IconButton onClick={() => handleEditStock(order)} aria-label="delete">
          <EditIcon />
        </IconButton>
      )
    }));
  }, [dataNew, handleEditStock]);

  const sortNumber = useMemo(() => orderBy === 'stock_odoo' || orderBy === 'stock_enova' || orderBy === 'stock_novastore' || orderBy === 'stock_detecnologia', [orderBy]);
  const sortString = useMemo(() => orderBy === 'sku' || orderBy === 'name', [orderBy]);

  const dataShow = useMemo(() => {
    if (sortNumber && direction) {
      const newData = _.orderBy(rows, (o: any) => Number(o[orderBy]), [direction]);
      return newData;
    }
    if (sortString && direction) {
      const newData = _.orderBy(rows, (o: any) => o[orderBy], [direction]);
      return newData;
    }

    return rows;
  }, [rows, orderBy, direction, sortString, sortNumber]);

  const handleProductSort = (orderByInner: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(orderByInner);
    setDirection(newDirection);
  };

  return { data: allData, rows: dataShow, handleEditStock, handleProductSort, orderBy, direction, loading, filter, setFilter, resetFilter, working };
};
