import { MARKETPLACE_ML_MP } from '../../../../const';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderReportBillingPeriodMlDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_reporting_billing_period_ml', 'order_id');
  }

  getAll() {
    return this.query().limit(0, 20000).run();
  }

  async getAllWithMarketplace(marketplace_id?: string) {
    let ls = this.query();
    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;

    if (marketplace_id) {
      const marketplace_type_id = MARKETPLACE_ML_MP.find((marketplace) => marketplace.marketplace_id === marketplace_id)?.marketplace_type;
      if (needsAnd) ls = ls.and();
      ls = ls.equals('marketplace_id', marketplace_id).and().equals('marketplace_type_id', marketplace_type_id);
      needsAnd = true;
    }

    return ls.run();
  }
}
