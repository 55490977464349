import React, { useMemo, useCallback, useState, useEffect, ReactNode } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import { LibbyObject, AnyObject } from '../../../types/types';
import { useLibbyFetchById, useModalWarning } from '../../../hooks';
import { useTranslation } from '../../../services/translation';
import { MakeCell } from '../../components/MakeCell';
import DeleteModal from '../../../services/confirmDialog';
import { columnsDetail, columnsDetailInquiry } from '../utils/columns';
import { Dispatch, Dispatch_item } from '../../../interfaces/business';
import CustomModal from '../../../services/customFormDialog';
import { ORDER_STATE, DISPATCH_STATE, DISPATCH_ITEM_STATE, DISPATCH_ITEM_STATE_NAME } from '../../../const';
import { ItemsTable } from '../../components/ItemsTable';
import { SearchDialog } from '../../../components/SearchDialog';
import { useCheckAll } from '../../../hooks/useCheckAll';
import { Dispatch_item_custom } from '../../../interfaces/business/dispatch/custom/Dispatch_item_custom';
import { useDispatchesPrintRefer } from './useDispatchPrintRefer';
import { sendDispatch } from '../utils/sendDispatch';
import { Order } from '../../../interfaces';
import { red } from '../../../theme/colors';
import { filterDispatchItemsByState } from '../utils/filter';
import { reducerExtraDataMessage } from '../../../utils/reducerExtraDataMessage';
import { extraDataMessageType } from '../../../types';
import { openInNewTab } from '../../../utils';
import { getDispatchManifest } from '../utils/printDispatchManifest';

const SearchDialogModal = CustomModal(SearchDialog);

interface useDispatchesDetailProp {
  libby: LibbyObject;
  id: string;
  reFetch: () => void;
  handleUpdateDispatchList?: (dispatch: Dispatch, id: string) => void;
  resetFilter?: () => void;
  showModalCancelledShow?: boolean;
}

export type rowsDispatch = {
  id: string;
  select_order_id: boolean | JSX.Element;
  order_id: string;
  buyer: string;
  so_number: string | undefined;
  state: string;
  order_state_id: string;
  items: JSX.Element;
  total: string;
  updated_by: string;
  dispatch_item_state: string;
  dispatch_item_state_id: string;
  collect: string | undefined;
  delete: JSX.Element;
};

export type orderArrayItemsType = {
  id?: string;
  order_id?: string;
  order_state_id?: string;
  order_state_name?: string;
  document?: string;
  phone_number?: string;
  buyer?: string;
  itemsName?: string;
  itemsQuantity?: string;
  updated_by?: string;
  courier?: string;
  updated_at?: string;
  so_number?: string;
  dispatch_item_id?: string;
  delete?: ReactNode;
};

const availableStates = [DISPATCH_STATE.PENDING, DISPATCH_STATE.PREPARING, DISPATCH_STATE.READY];

export const useDispatchesDetail = ({ libby, id, reFetch, handleUpdateDispatchList, resetFilter, showModalCancelledShow = false }: useDispatchesDetailProp) => {
  const { working, data, recall } = useLibbyFetchById(libby, {
    daoName: 'ster_dispatch_details',
    aspect: 'list_dispatch_details',
    id
  });

  const history = useHistory();
  const [readOnlyData, setReadOnlyData] = useState(false);

  const [newData, setNewData] = useState(data || {});
  const [msg, setMsg] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [orderItems, setOrderItems] = useState<Dispatch_item_custom[]>([]);
  const { checked, columnAll, handleCheckId } = useCheckAll(orderItems, 'dispatch_item_id');

  const { showModal } = useModalWarning({
    title: 'Send order',
    confirmText: 'Ok'
  });

  const checkIfDispatchIsReady = useCallback(
    async (updatedData: Dispatch) => {
      let nextDispatchState = '';
      if (!updatedData.items.length) {
        nextDispatchState = DISPATCH_STATE.PENDING;
      }
      const completeDispatchItems = updatedData.items.filter((item: Dispatch_item) => item.dispatch_item_state.dispatch_item_state_id === DISPATCH_ITEM_STATE.PREPARED);
      if (completeDispatchItems.length && completeDispatchItems.length === updatedData.items.length) {
        nextDispatchState = DISPATCH_STATE.READY;
      }
      if (!nextDispatchState) return;
      try {
        const dispatch = await libby.ster_dispatch_details_update.aspect('list_dispatch_details').save({
          ...updatedData,
          dispatch_state: { dispatch_state_id: nextDispatchState }
        });
        setNewData({ ...newData, ...dispatch });
        setOrderItems(dispatch.items || []);
      } catch (err) {
        enqueueSnackbar(err.toString(), { variant: 'error' });
      }
    },
    [libby.ster_dispatch_details_update, enqueueSnackbar, setNewData, newData]
  );

  const handleOpenItemsModal = async (e: AnyObject, items: AnyObject) => {
    e.stopPropagation();
    try {
      await SearchDialogModal.show({
        title: 'Items',
        id: 'order_item_id',
        properties: ['name', 'sku'],
        label: 'Item/Sku',
        data: items,
        renderItem: (dataItems: AnyObject) => <ItemsTable items={dataItems.data} />
      });
    } catch (error) {
      // nothing
    }
  };

  const validateStatusOrderCancelled = useCallback((dataDispatch: Dispatch): number => {
    return filterDispatchItemsByState({
      dispatchItems: dataDispatch.items,
      orderState: [ORDER_STATE.READY_FOR_DELIVERY.toString(), ORDER_STATE.WAITING_FOR_PREPARATION.toString(), ORDER_STATE.PREPARED.toString()]
    }).length;
  }, []);

  const showModalCancelled = useCallback(
    (countCancelled: number) => {
      showModal({
        newContent: t('Please, delete all orders that are not in the state ready for delivery, waiting for preparation or prepared').replace('$$$$', countCancelled.toString())
      });
    },
    [showModal, t]
  );

  useEffect(() => {
    if (data && Object.entries(data).length > 0 && Object.entries(newData).length === 0) {
      setNewData(data);
      if (showModalCancelledShow && data.dispatch_state.dispatch_state_id.toString() !== DISPATCH_STATE.SENT.toString()) {
        const countCancelled = validateStatusOrderCancelled(data);
        if (countCancelled !== data.items.length) {
          showModalCancelled(countCancelled);
        }
      }

      setOrderItems(data.items);
      setReadOnlyData(!availableStates.includes(data?.dispatch_state.dispatch_state_id));
    }
  }, [data, newData, t, validateStatusOrderCancelled, showModalCancelled, showModalCancelledShow]);

  const { printDispatchNote, searchPrintRefer } = useDispatchesPrintRefer({
    dispatch: newData
  });

  const removeItemUpdate = useCallback(
    (order_id) => {
      const dispatchItemsUpdated = orderItems.filter((item: Dispatch_item) => item.order?.order_id !== order_id);
      setOrderItems(dispatchItemsUpdated);
      return dispatchItemsUpdated;
    },
    [orderItems]
  );

  // HIDE Manifest
  const confirmPrintRefer = useCallback(async () => {
    const confirm = await DeleteModal.show({
      title: t('Print manifest'),
      content: t('Do you want to print the manifest?'),
      confirmText: t('Yes'),
      cancelText: t('No')
    });
    if (confirm) {
      openInNewTab(getDispatchManifest(id));
    }
  }, [t, id]);

  const toFinishDispatch = useCallback(async () => {
    const { data: saveDetailsUpdateState, success, error } = await sendDispatch(newData, libby);

    if (!success || !saveDetailsUpdateState) {
      enqueueSnackbar(t(error as string), {
        variant: 'error'
      });
      return;
    }
    setNewData(saveDetailsUpdateState.dispatch);
    setOrderItems(saveDetailsUpdateState?.dispatch.items || []);

    history.push(`/dispatches/inquiry/detail/${newData.dispatch_id}`);

    enqueueSnackbar(t('Dispatch successfully sent'), {
      variant: 'success'
    });
    if (resetFilter) resetFilter();
    reFetch();
    confirmPrintRefer();
  }, [newData, libby, confirmPrintRefer, t, enqueueSnackbar, history, resetFilter, reFetch]);

  const addItemInDispatch = useCallback(
    async (newOrderSave: Order[]) => {
      const dispatchData = { ...newData };

      const dispatchItems = [...dispatchData.items];

      newOrderSave.forEach((order: Order) => dispatchItems.push({ order: { ...order } }));

      try {
        const addItems = await libby.ster_dispatch_details_update.aspect('list_dispatch_details').save({ ...dispatchData, items: dispatchItems });

        if (handleUpdateDispatchList) {
          handleUpdateDispatchList(addItems, 'dispatch_id');
        }
        enqueueSnackbar(t('Orders Added'), { variant: 'success' });
        history.push(`/dispatches/detail/${id}`);
      } catch (error) {
        enqueueSnackbar(`${t('Error adding orders in dispatch')}: ${error}`, {
          variant: 'error'
        });
      }
    },
    [newData, libby.ster_dispatch_details_update, handleUpdateDispatchList, t, enqueueSnackbar, history, id]
  );

  const updateOrAddItem = useCallback(
    (dispatchItem: Dispatch_item) => {
      const copyNewDataUpdateOrAddItem = { ...newData } as Dispatch;

      const indexDispatchItem = copyNewDataUpdateOrAddItem.items.findIndex((itemNewDataUpdateOrAddItem: Dispatch_item) => itemNewDataUpdateOrAddItem.dispatch_item_id === dispatchItem.dispatch_item_id);

      if (indexDispatchItem > -1) {
        copyNewDataUpdateOrAddItem.items[indexDispatchItem] = dispatchItem;
      } else {
        copyNewDataUpdateOrAddItem.items.push(dispatchItem);
      }
      setNewData(copyNewDataUpdateOrAddItem);
    },
    [newData, setNewData]
  );

  const addItemInOrderItems = useCallback(
    async (orderId) => {
      setMsg(t('Adding order: $$$$').replace('$$$$', orderId));

      try {
        const dispatchItemOrder = await libby.ster_dispatch_item_send.query().equals('order_id', orderId).equals('dispatch_id', id).run();
        let content = t(dispatchItemOrder?.message);

        if (dispatchItemOrder?.extraDataMessage?.length) {
          content = dispatchItemOrder?.extraDataMessage.reduce((contentReplace: string, dataReplace: extraDataMessageType) => reducerExtraDataMessage(contentReplace, dataReplace, t), t(dispatchItemOrder?.message), t);
        }

        if (dispatchItemOrder.error) {
          showModal({
            newContent: content
          });
        } else {
          updateOrAddItem(dispatchItemOrder.data);
          enqueueSnackbar(content, {
            variant: 'success'
          });
        }
      } catch (error) {
        enqueueSnackbar(`${t('An error occurred sending order $$$$').replace('$$$$', orderId)}: ${error}`, {
          variant: 'error'
        });
      }
      setMsg('');
    },
    [id, libby, t, showModal, updateOrAddItem, enqueueSnackbar]
  );

  const updateDataDetail = useCallback(
    (dataUpdate: AnyObject) => {
      setNewData((prev: AnyObject) => {
        const copy: AnyObject = { ...prev };
        if (copy?.dispatch_id?.length) {
          if (copy.courier_service.courier_service_id !== dataUpdate.courier_service.courier_service_id && copy.courier_service.courier.courier_id !== dataUpdate.courier_service.courier.courier_id) {
            copy.items = [];
            setOrderItems(copy.items);
          }
          return { ...copy, ...dataUpdate };
        }
        return copy;
      });
    },
    [setNewData]
  );

  const deleteItemInOrderItemsModal = useCallback(
    async (dispatch_item_id, order_id, dispatch_item_state_id) => {
      setMsg(t('Removing order: $$$$').replace('$$$$', order_id));
      const confirm = await DeleteModal.show({
        title: t('Order Delete'),
        content:
          dispatch_item_state_id !== DISPATCH_ITEM_STATE.ASSIGENED_TO_COLLECT && dispatch_item_state_id !== DISPATCH_ITEM_STATE.COLLECTING
            ? t('Do you want to delete the order of the dispatch $$$$?').replace('$$$$', order_id)
            : t('This order $$$ is already in $$$$ status, you are sure you want to remove it from dispatch?').replace('$$$', order_id).replace('$$$$', t(DISPATCH_ITEM_STATE_NAME[dispatch_item_state_id])),
        confirmText: t('Yes'),
        cancelText: t('No')
      });
      if (confirm) {
        try {
          if (dispatch_item_id) {
            await libby.ster_dispatch_item.aspect('remove_item').remove({ dispatch_item_id });
          }

          const updatedDispatchItems = removeItemUpdate(order_id);
          const updatedDispatch = { ...newData, items: updatedDispatchItems };
          setNewData(updatedDispatch);
          setOrderItems(updatedDispatch.items || []);
          checkIfDispatchIsReady(updatedDispatch);
          enqueueSnackbar(t('Order Delete'), { variant: 'success' });
        } catch (e) {
          enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        }
      }
    },
    [enqueueSnackbar, libby.ster_dispatch_item, t, removeItemUpdate, checkIfDispatchIsReady, setNewData, newData]
  );

  const rows: rowsDispatch[] = useMemo(() => {
    const detailsData = newData as Dispatch;

    return orderItems.map(({ dispatch_item_id, order: { order_id, so_number, buyer, items, total, state }, dispatch_item_state, updated_by, created_by, collect_items }) => ({
      id: dispatch_item_id,
      select_order_id:
        (state?.order_state_id === ORDER_STATE.WAITING_FOR_PREPARATION.toString() || state?.order_state_id === ORDER_STATE.READY_FOR_DELIVERY.toString() || detailsData.dispatch_state.dispatch_state_id === DISPATCH_STATE.READY) &&
        handleCheckId(dispatch_item_id),
      order_id,
      buyer: `${buyer?.first_name} ${buyer?.last_name}`,
      so_number,
      state: state?.name,
      order_state_id: state?.order_state_id,
      items: <MakeCell label={String(items?.length ?? '')} icon={LaunchIcon} onClick={(e) => handleOpenItemsModal(e, items ?? [])} />,
      total,
      updated_by: updated_by?.username ? updated_by.username : created_by?.username,
      dispatch_item_state: dispatch_item_state?.name,
      dispatch_item_state_id: dispatch_item_state?.dispatch_item_state_id,
      collect: collect_items?.[0]?.collect_id,
      delete: (
        <Grid container justify="center">
          <MakeCell label="" icon={DeleteIcon} onClick={() => deleteItemInOrderItemsModal(dispatch_item_id, order_id, dispatch_item_state?.dispatch_item_state_id.toString())} />
        </Grid>
      ),
      color_row: state?.order_state_id === ORDER_STATE.CANCELLED.toString() && red
    }));
  }, [orderItems, deleteItemInOrderItemsModal, handleCheckId, newData]);

  const columnsDetailsWithCheck = [columnAll, ...columnsDetail];
  return {
    orderItems,
    working,
    rows,
    addItemInOrderItems,
    deleteItemInOrderItemsModal,
    newData,
    columns: readOnlyData ? columnsDetailInquiry : columnsDetailsWithCheck,
    readOnlyData,
    updateDataDetail,
    msg,
    checked,
    recall,
    setNewData,
    setOrderItems,
    toFinishDispatch,

    checkIfDispatchIsReady,
    printDispatchNote,
    searchPrintRefer,
    addItemInDispatch
  };
};
