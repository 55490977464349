import React, { useMemo, useEffect } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { InputProps, LibbyObject } from '../../types';
import { useLibbyCall } from '../../hooks';
import { Company_marketplace } from '../../interfaces/business/marketplace/CompanyMarketplace';
import { GeneralAutocomplete } from '../GeneralAutocomplete';
import { DataGenerateAutocompleteType } from '../../utils/generateAutocomplete';
import { Marketplace } from '../../interfaces/business';

type SelectDispatchProps = {
  inputProps: InputProps;
  [k: string]: any;
  libby: LibbyObject;
  label?: string;
  company_id?: string;
  disabled: boolean;
  dataOptions?: Marketplace[];
};

export const SelectAllMarketplaceCompanyRaw = ({ libby, dataOptions, inputProps, label = 'Marketplace', company_id, disabled = false }: SelectDispatchProps) => {
  const {
    data: dataRaw,
    recall,
    working = true
  } = useLibbyCall(libby, {
    daoName: 'ster_company_marketplace',
    methodName: 'getAllCompanyById',
    noAutoCall: true
  });

  useEffect(() => {
    if (company_id?.length) {
      recall?.(company_id);
    }
  }, [recall, company_id]);

  const data: DataGenerateAutocompleteType[] = useMemo(() => dataRaw.map(({ marketplace: { marketplace_id, name } }: Company_marketplace) => ({ id: marketplace_id, value: name })), [dataRaw]);

  const dataOptionsMarketplace: DataGenerateAutocompleteType[] | undefined = useMemo(
    () => (dataOptions?.length ? dataOptions.map(({ marketplace_id, name }: Marketplace) => ({ id: marketplace_id, value: name })) : undefined),
    [dataOptions]
  );

  const dataOptionsAll: DataGenerateAutocompleteType[] = useMemo(() => dataOptionsMarketplace || data, [data, dataOptionsMarketplace]);

  return <GeneralAutocomplete value={inputProps.valueArray || []} onChange={inputProps.onChange} working={working} label={label} disabled={disabled} data={dataOptionsAll} id="select-marketplace" />;
};

export const SelectAllMarketplaceCompany = DatabaseConnector(SelectAllMarketplaceCompanyRaw)('ster_company_marketplace');
