import { AnyObject } from '../types/types';

export enum ORDER_REASON {
  CANCELLATION = 1,
  FRAUD = 3,
  REFUND = 2
}

export const OrderReason: AnyObject = {
  1: 'Cancellation',
  2: 'Refund',
  3: 'Fraud'
};
