import { Grid } from '@material-ui/core';
import React from 'react';
import { ScreenAligned } from '../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../services/translation';
import InfoTable from '../../components/InfoTable';
import LoadingData from '../../components/LoadingData';
import { useReportingMlLogic } from '../hook/useReportingMl';
import { columns } from '../utils/columns';
import { SearchFilters } from './components/SearchFilters';

export const ReportingBillingMlList = () => {
  const { t } = useTranslation();
  const { rows, working, setFilter, filter, fetchMore } = useReportingMlLogic();

  return (
    <ScreenAligned title="Billing Mercadolibre">
      <SearchFilters onFilterChange={setFilter} filter={filter} />
      <Grid container direction="row" justify="center" alignItems="center">
        <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} onSortChange={() => {}} direction="asc" rowIdKey="name" />
        <LoadingData label={`${t('Loaded billings')}`} working={working} cant={rows.length} />
      </Grid>
    </ScreenAligned>
  );
};
