import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useReportingMlDAO } from '../../../business/orders/order';
import { MARKETPLACE } from '../../../const';
import { useFilerParamsId } from '../../../hooks';
import { useInterval } from '../../../hooks/useIntervalPage';
import { Order_reporting_ml_all } from '../../../interfaces/business';
import { useTranslation } from '../../../services/translation';
import { ButtonDetailsType } from '../../../types/Button';
import useRows from './useRows';

export const useReportingMlLogic = () => {
  const [data_urbantech_ml, setDataUrbantechMeli] = useState<Order_reporting_ml_all | undefined>();
  const [data_novatech_ml, setDataNovatechMl] = useState<Order_reporting_ml_all | undefined>();
  const [data_tienda_oficial_ml, setDataTiendaOficialMl] = useState<Order_reporting_ml_all | undefined>();
  const [working_tienda_oficial_ml, setWorkingTiendaOficialMl] = useState(true);
  const [working_novatech_ml, setWorkingNovatechMl] = useState(true);
  const [working_urbantech_ml, setWorkingUrbantechMl] = useState(true);
  const reportingMLDAO = useReportingMlDAO();
  const [stateRefresh, setState] = useState(false);
  const { t } = useTranslation();

  const { setFilter, filter } = useFilerParamsId({
    orderInit: 'id',
    daoName: 'ster_reporting_ml'
  });
  const [refreshFilter, setResetFilter] = useState(filter);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.checked);
  };

  const work = useMemo(() => working_tienda_oficial_ml || working_urbantech_ml || working_novatech_ml, [working_tienda_oficial_ml, working_urbantech_ml, working_novatech_ml]);

  const delay = useMemo(() => (stateRefresh ? 60000 : null), [stateRefresh]);

  const init = useCallback(
    async (filterInit: any) => {
      setWorkingUrbantechMl(true);
      const dataUrbantechMl: any = await reportingMLDAO.getByDateOrderReportingMl(filterInit, MARKETPLACE.URBANTECH);
      setDataUrbantechMeli(dataUrbantechMl);
      setWorkingUrbantechMl(false);
      setWorkingTiendaOficialMl(true);
      const dataTiendaOficialMl = await reportingMLDAO.getByDateOrderReportingMl(filterInit, MARKETPLACE.TIENDA_OFICIAL_ML);
      setDataTiendaOficialMl(dataTiendaOficialMl);
      setWorkingTiendaOficialMl(false);
      setWorkingNovatechMl(true);
      const dataNovatechMl = await reportingMLDAO.getByDateOrderReportingMl(filterInit, MARKETPLACE.NOVATECH);
      setDataNovatechMl(dataNovatechMl);
      setWorkingNovatechMl(false);
    },
    [reportingMLDAO]
  );

  const refresh = useCallback(() => {
    setResetFilter(filter);
  }, [filter, setResetFilter]);

  useEffect(() => {
    init(refreshFilter);
  }, [refreshFilter, init]);

  const { time, resetTime } = useInterval(refresh, delay);

  const OnChangeApplyFilters = useCallback(() => {
    if (stateRefresh) {
      resetTime();
    } else {
      refresh();
    }
  }, [refresh, stateRefresh, resetTime]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'dispatchDetailAddOrder',
        onClick: OnChangeApplyFilters,
        title: `${t('Refresh')} ${stateRefresh ? `(${time} ${t('Seconds').toLowerCase()})` : ''}`,
        disabled: work,
        loading: stateRefresh ? work : false
      }
    ],
    [time, OnChangeApplyFilters, work, stateRefresh, t]
  );

  const { rows } = useRows({
    working: { working_tienda_oficial_ml, working_novatech_ml, working_urbantech_ml },
    data: { data_tienda_oficial_ml, data_novatech_ml, data_urbantech_ml }
  });

  return {
    working: false,
    rows,
    setFilter,
    filter,
    work,
    stateRefresh,
    handleChange,
    buttons
  };
};
