import { useEffect, useMemo } from 'react';
import { useReportingBillingMlLibbyCall } from '../../../business/orders/order';
import { MARKETPLACE_ML_MP } from '../../../const';
import { useFilerParamsId } from '../../../hooks';
import { Order_reporting_billing } from '../../../interfaces/business/orders/Order_reporting_reporting_billings_ml';
import { RowsType } from '../../../types';

export const useReportingMlLogic = () => {
  const { data, working, recall } = useReportingBillingMlLibbyCall<Order_reporting_billing[]>({
    methodName: 'getByPeriodMpReportingMl',
    noAutoCall: true
  });

  const { setFilter, filter } = useFilerParamsId({
    orderInit: 'id',
    daoName: 'ster_reporting_billing_ml'
  });

  /* const { data, fetchMore, working } = useReportingBillingMlLibbyFetch(paramsFetch);
   */

  useEffect(() => {
    if (filter?.marketplace_id && filter?.billing_period) recall?.(filter);
  }, [recall, filter]);

  const rows: RowsType[] = useMemo(() => {
    const marketplace_id = filter?.marketplace_id?.[0].value as string;

    const marketplace_type_id = MARKETPLACE_ML_MP.find((marketplace) => marketplace.marketplace_id === marketplace_id)?.marketplace_type;
    return data?.length
      ? data.reduce((result: RowsType[], billing: Order_reporting_billing) => {
          const copyResult = [...result];
          copyResult.push({
            ...billing,
            marketplace_type: marketplace_type_id
          });
          return copyResult;
        }, [])
      : [];
  }, [data, filter]);

  return {
    working,
    rows,
    setFilter,
    filter,
    data,
    fetchMore: () => {}
  };
};
