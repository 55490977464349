import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AnyObject, LibbyObject } from '../../types/types';
import { GeneralSelect } from '../../components/GeneralSelect';
import { useOrderState } from '../Orders/routes/OrdersList/hook/useOrderState';

type SelectImportTypeProps = {
  title: string;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  listStateInit: number[];
  sort?: boolean | 'asc' | 'desc';
};

export type OrderStateType = {
  order_state_id: string | number;
  name: string;
};

const idsByOffline = [4, 8, 9, 10, 15, 21, 22, 24, 25, 26, 27, 29];

export const SelectOrderStateRaw = ({ libby, inputProps = {}, optionAll = false, title, listStateInit = idsByOffline, sort, ...restProps }: SelectImportTypeProps) => {
  const { data, working } = useOrderState(libby, listStateInit);
  return <GeneralSelect working={working} label={title} optionAll={optionAll} data={data} inputProps={inputProps} {...restProps} sort={sort} />;
};

export const SelectOrderState = DatabaseConnector(SelectOrderStateRaw)('order_state');
