import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { BudgetTable } from './components';
import { BudgetListProps } from '../../interfaces';
import { useTranslation } from '../../../../services/translation';

const useStyles = makeStyles(() => ({
  search: {
    width: '80%'
  },
  container: {
    // display:'flow-root',
    position: 'relative',
    marginRight: '118px',
    left: '50%',
    top: '50%',
    // transform: 'translate(45%, 30%)',
    textAlign: 'center'
  },
  btn: {
    border: 'none',
    margin: 20,
    width: 120,
    height: 35,
    borderRadius: 6,
    textTransform: 'uppercase',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    cursor: 'pointer',
    color: '#fff',
    backgroundSize: '200%',
    transition: '0.4s',
    '&:hover': {
      backgroundPosition: 'right'
    }
  },
  btn1: {
    backgroundImage: 'linear-gradient(45deg, #FFC312, #EE5A24, #00A8FF)'
  },
  btn2: {
    backgroundImage: 'linear-gradient(45deg, #e74c3c, #8e44ad, #f1c40f)'
  },
  displaceBtn: {
    marginRight: '305px'
  }
}));

export const BudgetList = ({
  setFilter,
  resetFilter,
  rows,
  columns,
  working,
  filter,
  fetchMore,
  reFetch,
  onSortChange,
  orderBy,
  direction,
  title,
  allButtonsActions,
  onRowClick,
  canalOffLine = false,
  onNewBudget
}: BudgetListProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <ScreenAligned title={title} additionalTitle={allButtonsActions}>
      <div className={classes.container}>
        <Button color="primary" type="submit" variant="contained" onClick={onNewBudget}>
          {t('Budget')}
        </Button>
      </div>
      <BudgetTable
        canalOffLine={canalOffLine}
        columns={columns}
        rows={rows}
        working={working}
        filter={filter}
        setFilter={setFilter}
        fetchMore={fetchMore}
        onSortChange={onSortChange}
        orderBy={orderBy}
        direction={direction}
        onRowClick={onRowClick}
        reFetch={reFetch}
        resetFilter={resetFilter}
      />
    </ScreenAligned>
  );
};
