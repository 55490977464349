export enum MARKETPLACE {
  Fravega = '1',
  NOVATECH_STORE = '2',
  ICBC = '3',
  BNA = '4',
  BNA_UNIVERSITARIOS = '5',
  TARJETA_NARANJA = '6',
  PAMI = '7',
  CORREO_COMPRAS = '8',
  TIENDA_OFICIAL_ML = '11',
  NOVATECH = '12',
  ENOVA = '14',
  DETECNOLOGIA = '31',
  URBANTECH = '36',
  VENTA_TELEFONICA = '21'
}

export const Marketplace: { [k: string]: string } = {
  1: 'Fravega',
  2: 'NovatechStore',
  3: 'ICBC',
  4: 'BNA',
  5: 'BNA Universitarios',
  6: 'Tarjeta Naranja',
  7: 'PAMI',
  8: 'Correo Compras',
  9: 'Banco Provincia'
};

export const MARKETPLACE_ML_MP = [
  {
    name: 'Tienda Oficial ML',
    marketplace_id: MARKETPLACE.TIENDA_OFICIAL_ML,
    marketplace_type: 'MP'
  },
  {
    name: 'Novatech Meli',
    marketplace_id: MARKETPLACE.NOVATECH,
    marketplace_type: 'MP'
  },
  {
    name: 'Urbantech Meli',
    marketplace_id: MARKETPLACE.URBANTECH,
    marketplace_type: 'MP'
  } /* ,
  {
    name: 'Enova',
    marketplace_id: MARKETPLACE.ENOVA,
    marketplace_type: 'MP'
  },
  {
    name: 'Novatech',
    marketplace_id: MARKETPLACE.NOVATECH,
    marketplace_type: 'MP'
  },
  {
    name: 'Detecnologia',
    marketplace_id: MARKETPLACE.DETECNOLOGIA,
    marketplace_type: 'MP'
  } */
];
