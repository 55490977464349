import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useForm, useField } from 'react-final-form-hooks';
import { makeStyles } from '@material-ui/styles';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useLibbyFetch } from '../../../../../hooks';
import { useTranslation } from '../../../../../services/translation';
import { LibbyObject } from '../../../../../types';
import LoadingData from '../../../../components/LoadingData';
import CustomModal from '../../../../../services/customFormDialog';
import { ProductSelection } from './ProductSelection';
import { Product } from '../../../../../interfaces/business';

const useStyles = makeStyles(() => ({
  textFieldAutocomplete: {
    '& .MuiFormHelperText-root': {
      color: '#FF5179'
    }
  }
}));

type ProductSearchProps = {
  libby: LibbyObject;
  onProductAdded: (product: Product) => void;
};

const ProductSelectionModal = CustomModal(ProductSelection);

const ProductSearchRaw = ({ libby, onProductAdded }: ProductSearchProps) => {
  const classes = useStyles();
  const { data: products, working } = useLibbyFetch(libby, { daoName: 'ster_product' });
  const autoC = useRef(null);

  const { form } = useForm({
    initialValues: { search: '' },
    onSubmit: () => {}
  });
  const { t } = useTranslation();

  const search = useField('search', form);

  const options = useMemo(() => products.map((product) => ({ id: product.sku, value: `${product.sku} - ${product.name}` })), [products]);

  const verifyProductWithOdoo = useCallback(
    async (sku: string) => {
      // TODO: hacemos la consulta a odoo y si oddo nos trae mas de un producto, llamamos al modal
      console.log('sku', sku);
      // TODO: estos vendrian siendo los que retorna Odoo
      const productsFromOdoo = products.filter((_, i) => i <= 4);
      await ProductSelectionModal.show({
        products: productsFromOdoo,
        onRowClick: (pr: Product) => {
          onProductAdded(pr);
          try {
            // @ts-ignore
            const ele = autoC?.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
            if (ele) ele.click();
          } catch (error) {
            console.log(error.toString());
          }
        }
      });
    },
    [products, onProductAdded]
  );

  useEffect(() => {
    if (search.input.value) {
      verifyProductWithOdoo(search.input.value);
    }
  }, [search.input.value, verifyProductWithOdoo]);

  return !working ? (
    <>
      <Autocomplete
        style={{ display: 'inline-block', width: '100%' }}
        id="budget-product-search"
        options={options}
        autoSelect
        getOptionLabel={(option: { id: string; value: string }) => option.value}
        filterSelectedOptions
        renderInput={(params) => (
          <form noValidate>
            <TextField {...params} className={classes.textFieldAutocomplete} label={t('Product')} placeholder="" onBlur={search.input.onBlur} />
          </form>
        )}
        onChange={(e, newValue) => {
          search.input.onChange(newValue && newValue.id.toString());
        }}
        noOptionsText="sin opciones"
        clearOnBlur
        ref={autoC}
      />
    </>
  ) : (
    <LoadingData working />
  );
};

export const ProductSearch = DatabaseConnector(ProductSearchRaw)('ster_product');
