import { FormDialog } from '../../components/FormDialog';
import asService from '../../hoc/asService';

const options = {
  activeProp: 'open',
  resolveProp: 'onConfirm',
  rejectProp: 'onCancel',
  forceResolveOnReject: false,
  rejectValue: false
};
export default asService(options)(FormDialog);
