export enum COURIER {
  FRAVEGA_SHIPMENTS = '1',
  FLEX = '2',
  ANDREANI = '3',
  ENVIO_PACK = '4'
}

export const LINK_TRACKING_BY_COURIER: { [k: string]: string } = {
  1: 'https://www.seguituenvio.com',
  3: 'https://www.andreani.com/#!/informacionEnvio',
  4: 'https://www.seguituenvio.com'
};
