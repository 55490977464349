import { API_URL } from '../../../config';

export interface SerialNumber {
  SKU: string;
  SN: string[];
}

export interface OddoOrder {
  order_id: number;
  so: string;
  serial_numbers: SerialNumber[];
}

export interface Oddo {
  orders: OddoOrder[];
}

export const sendDataToOddo = async (data: Oddo) => {
  const response = await fetch(`${API_URL}/public/odoo/remitir`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' }
  });
  const body = await response.json();
  const {
    result: { status, message }
  } = body;
  if (status === 400) throw message;
};
