import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ReportingBillingMlList } from './routes';

export const ReportingBillingMl = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`}>
        <ReportingBillingMlList />
      </Route>
    </Switch>
  );
};
