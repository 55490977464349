import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useField, useForm, FormConfig } from 'react-final-form-hooks';
import { ValidationErrors } from 'final-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../utils/materialHelpers';
import { useTranslation } from '../../../services/translation';
import { AnyObject } from '../../../types';
import { ProductStockType } from '../../../interfaces/business';

const useStyles = makeStyles({
  title: {
    fontFamily: 'Oswald'
  },
  error: {
    fontSize: '12px'
  }
});

interface StockEditDialofTypes extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  onConfirm: Function;
  title: string;
  content: React.ReactNode;
  labelContent: string;
  cancelText: string;
  confirmText: string;
  customTheme: string | null;
  inputType: string;
  validate: (values: any) => ValidationErrors | Promise<ValidationErrors> | undefined;
  formInitialValues: AnyObject;
  dataInner: ProductStockType;
  reset: boolean;
}

export const StockEditDialog = ({ dataInner, open = false, onCancel = () => {}, onConfirm = () => {}, title, cancelText, confirmText, customTheme = null, validate, formInitialValues, reset }: StockEditDialofTypes) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  const classes = useStyles();
  const [cancel, setCancel] = useState(false);

  const onSubmit = useCallback(
    async ({ stock_enova, stock_novastore, stock_detecnologia }) => {
      onConfirm({
        stock_enova,
        stock_novastore,
        stock_detecnologia
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  useEffect(() => {
    form.reset();
  }, [reset, form, cancel]);

  const stock_enova = useField('stock_enova', form);
  const stock_novastore = useField('stock_novastore', form);
  const stock_detecnologia = useField('stock_detecnologia', form);

  const errors = useMemo(() => getErrorAndHelperText(stock_novastore).error || getErrorAndHelperText(stock_enova).error, [stock_novastore, stock_enova, getErrorAndHelperText]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <Typography className={classes.title} variant="h3">
          {title}
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="body1">
            <strong>{`${t('Product Name')}:`}</strong>
          </Typography>
          <Typography variant="body1">{dataInner?.name}</Typography>
          <br />
          <Typography variant="body1">
            <strong>{`${t('Sku')}:`}</strong>
          </Typography>
          <Typography>{dataInner?.sku}</Typography>
          <br />
          <Typography variant="body1">
            <strong>{`Odoo stock: `}</strong>
            {dataInner?.stock_odoo}
          </Typography>
          {stock_enova.input.value !== null || stock_enova.input.value === 0 ? (
            <TextField
              {...textFieldProps(`${t('Enova')}`)}
              onChange={stock_enova.input.onChange}
              value={stock_enova.input.value}
              variant="standard"
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              onBlur={stock_enova.input.onBlur}
              {...getErrorAndHelperText(stock_enova)}
            />
          ) : (
            <TextField fullWidth disabled label="Enova" />
          )}
          {stock_novastore.input.value !== null || stock_novastore.input.value === 0 ? (
            <TextField
              {...textFieldProps(`${t('Novastore')}`)}
              onChange={stock_novastore.input.onChange}
              value={stock_novastore.input.value}
              variant="standard"
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              onBlur={stock_novastore.input.onBlur}
              {...getErrorAndHelperText(stock_novastore)}
            />
          ) : (
            <TextField fullWidth disabled label="Novatech" />
          )}
          {stock_detecnologia.input.value !== null || stock_detecnologia.input.value === 0 ? (
            <TextField
              {...textFieldProps(`${t('Detecnologia')}`)}
              onChange={stock_detecnologia.input.onChange}
              value={stock_detecnologia.input.value}
              variant="standard"
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              onBlur={stock_detecnologia.input.onBlur}
              {...getErrorAndHelperText(stock_novastore)}
            />
          ) : (
            <TextField fullWidth disabled label="Detecnologia" />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onCancel();
              setCancel(!cancel);
            }}
          >
            {cancelText}
          </Button>
          {errors ? (
            <Button disabled>{confirmText}</Button>
          ) : (
            <Button color="primary" type="submit" autoFocus>
              {confirmText}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
