import React, { useState, useCallback, useEffect, useImperativeHandle, forwardRef } from 'react';
import moment, { Moment } from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import { TextField, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { TextFieldProps } from '@material-ui/core/TextField';
import { useTranslation } from '../../../../../services/translation';
import { makeFilter } from '../utils/makeFilter';
import 'moment/locale/es';
import { SelectImportType } from '../../components/SelectImportType';
import { SelectImportStatus } from '../../components/SelectImportStatus';
import { AnyObject, LibbyObject } from '../../../../../types/types';
import { DateClose } from '../../../../../components/DateClose';
import { FilterBar } from '../../../../../components/FilterBar';
import { formInitialValues, validate } from '../../utils/model';
import EditModal from '../../../../../services/formDialog';
import { gridBreakpoints, gridBreakpointsFields } from '../../../../../const/gridBreakpoints';
import useDebounce from '../../../../../hooks/useDebounce';
import { replaceNonNumericCharacters } from '../../../../../functions';

const initialStartDate = null;
const initialEndDate = null;

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  initFetch: AnyObject;
  libby: LibbyObject;
  pushImportDetail: Function;
  addCreate: (data: object) => void;
  importationTargetId: string | number;
};

type RefType = unknown | SearchFiltersProps | any;

const SearchFiltersRaw = ({ initFetch, onFilterChange, libby, pushImportDetail, addCreate, importationTargetId }: SearchFiltersProps, ref: RefType) => {
  const { t } = useTranslation();
  const [symbolsArr] = useState(['e', 'E', '+', '-', '.', ',', '``']);

  const { enqueueSnackbar } = useSnackbar();
  const [newImportId, setNewImportId] = useState(initFetch.newImportId ? initFetch.newImportId[0].value : 0);
  const [importId, setImportId] = useState<string>(initFetch.importId && initFetch.importId.length > 0 ? initFetch.importId[0].value : '');

  const importIdDebounced = useDebounce(importId, 500);

  const [importStatus, setImportStatus] = useState<string>(initFetch.importStatus && initFetch.importStatus.length > 0 ? initFetch.importStatus[0].value : '0');
  const [importType, setImportType] = useState(initFetch.importType && initFetch.importType.length > 0 ? initFetch.importType[0].value : '0');
  const [startDate, setStartDate] = useState<Moment | null>(initFetch.startDate && initFetch.startDate.length > 0 ? moment(initFetch.startDate[0].value) : initialStartDate);
  const [endDate, setEndDate] = useState<Moment | null>(initFetch.endDate && initFetch.endDate.length > 0 ? moment(initFetch.endDate[0].value) : initialEndDate);

  useEffect(() => {
    onFilterChange(
      makeFilter({
        importId: importIdDebounced,
        importType,
        importStatus,
        startDate,
        endDate
      })
    );
  }, [onFilterChange, importIdDebounced, importType, importStatus, startDate, endDate]);

  const handleReset = useCallback(() => {
    setImportId('');
    setImportStatus('0');
    setImportType('0');
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
    onFilterChange(
      makeFilter({
        importId: '',
        importStatus: '0',
        importType: '0',
        startDate: initialStartDate,
        endDate: initialEndDate
      })
    );
  }, [onFilterChange]);

  const handleSaveNewImport = useCallback(async () => {
    const paramsModal = {
      inputType: 'select',
      select: ({ inputProps }: { inputProps: TextFieldProps }) => <SelectImportType importationTargetId={importationTargetId} inputProps={inputProps} optionAll customAll="Select type of import" />,
      inputName: 'import_type',
      confirmText: t('Create'),
      cancelText: t('Cancel'),
      content: '',
      title: t('Import type selection'),
      labelContent: t('Import type'),
      validate,
      formInitialValues
    };

    let newImportType: number;
    try {
      const { import_type = 0 }: AnyObject = (await EditModal.show(paramsModal)) as AnyObject;
      newImportType = import_type;
    } catch (e) {
      newImportType = 0;
    }

    if (newImportType > 0) {
      try {
        const dataNew = await libby.app_importation.save({
          importation_type: {
            importation_type_id: Number(newImportType)
          }
        });
        addCreate(dataNew);
        enqueueSnackbar(t('Import created'), { variant: 'success' });
        setNewImportId(dataNew.importation_id);
      } catch (e) {
        enqueueSnackbar(`${t('Something is wrong')}, ${t('Please try again later')}`, { variant: 'error' });
      }
    }
  }, [t, libby.app_importation, addCreate, enqueueSnackbar, importationTargetId]);

  useEffect(() => {
    if (!libby.working && newImportId > 0) {
      pushImportDetail(newImportId);
    }
  }, [libby.working, newImportId, pushImportDetail]);

  const handleChangeType = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setImportType(event.target.value);
  }, []);

  const handleChangeStatus = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setImportStatus(event.target.value);
  }, []);

  useImperativeHandle(ref, () => ({
    async saveNewImport() {
      await handleSaveNewImport();
    }
  }));

  return (
    <FilterBar
      spacing={6}
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="import-id"
            placeholder={t('Search by ID')}
            type="search"
            value={importId}
            onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
            onChange={(event) => setImportId(replaceNonNumericCharacters(event.target.value))}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
      handleReset={handleReset}
    >
      <Grid item {...gridBreakpointsFields}>
        <SelectImportStatus inputProps={{ value: importStatus, onChange: handleChangeStatus }} optionAll sort="asc" />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectImportType inputProps={{ value: importType, onChange: handleChangeType }} optionAll importationTargetId={importationTargetId} sort="asc" />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="Start Date" value={startDate} onChange={setStartDate} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="End Date" value={endDate} minValue={startDate} onChange={setEndDate} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(forwardRef(SearchFiltersRaw));
