import React, { useEffect, useMemo } from 'react';
import { SimpleSelect } from '../../../components/Select';
import { AnyObject } from '../../../types';
import { makeEntityLibbyCall } from '../hooks';

export interface EntitySelectProps {
  value: string | string[];
  handleChange: (value: any) => void;
  title?: string;
  placeholder?: string;
  labelKey?: string;
  valueKey?: string;
  name?: string;
  filter?: AnyObject;
  multiple?: boolean;
  disabled?: boolean;
  customStyleTitle?: any;
  orderBy?: string;
  direction?: string;
  onOptionsChange?: (options: any) => void;
  transformOptions?: (options: AnyObject[]) => AnyObject[];
  aspect?: string;
  methodName?: string;
  params?: any;
}

export interface EntitySelectDefaultProps {
  labelKey: string;
  valueKey: string;
  title: string;
  placeholder: string;
  name: string;
  orderBy?: string;
  direction?: string;
}

const dummyFilter = {};

export const withSimpleSelect = (daoName: string, defaultProps: EntitySelectDefaultProps) => {
  const useHook = makeEntityLibbyCall(daoName);
  return (props: EntitySelectProps) => {
    const { filter = dummyFilter, orderBy, direction = 'asc', onOptionsChange, transformOptions, aspect, methodName, params, ...restProps } = props;

    // TODO: improve this
    const {
      working,
      data = [],
      recall
    } = useHook({
      methodName: methodName || 'fetch',
      params: [],
      noAutoCall: true,
      aspect
    });
    useEffect(() => {
      if (recall) {
        const newParams = params || {
          filter,
          limit: 200000,
          orderBy,
          direction
        };
        recall(newParams);
      }
    }, [filter, recall, direction, orderBy, params]);
    useEffect(() => {
      if (onOptionsChange) {
        onOptionsChange(data);
      }
    }, [data, onOptionsChange]);
    const content = useMemo(() => {
      if (!transformOptions) {
        return data;
      }
      return transformOptions(data);
    }, [data, transformOptions]);
    return <SimpleSelect content={content} loading={working} {...defaultProps} {...restProps} />;
  };
};
