import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useModalWarning } from '../../../../../hooks';
import { Order } from '../../../../../interfaces/business';
import { useTranslation } from '../../../../../services/translation';
import { LibbyObject } from '../../../../../types';
import { ORDER_DOCUMENT_TYPE, ORDER_REASON, ORDER_STATE } from '../../../../../const';
import CustomModal from '../../../../../services/customFormDialog';
import { UpdatedStateWithReferForm } from '../components/UpdatedStateWithReferModal';
import { ORDER_DOCUMENT_SOURCE } from '../../../../../const/OrderSources';
import { OrderReasonSelect } from '../../../../components';
import { OrderReasonDialogComponent } from '../../../../../components/OrderReasonDialog';

type useStateOrderType = {
  libby: LibbyObject;
};

export type onChangeUpdateStateType = {
  data: Order;
  updateDataField: (order: Order, field?: keyof typeof order) => void;
  title?: string;
  content?: string;
  validation?: boolean;
  typeUpdateState?: string;
  state_id: string;
};

export type onChangeUpdateStateAllType = {
  data: Order[];
  updateDataField: (order: Order, field?: keyof typeof order) => void;
  title?: string;
  content?: string;
  typeUpdateState?: string;
  state_id: string;
};

const UpdateStateWithReferModal = CustomModal(UpdatedStateWithReferForm);

const OrderReasonModal = CustomModal(OrderReasonDialogComponent);

export const useStateOrder = ({ libby }: useStateOrderType) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { showModal } = useModalWarning({
    title: 'You have orders in a canceled state',
    content: 'Please, delete all orders($$$$) with canceled status',
    confirmText: 'Yes',
    oneButton: false
  });

  const onChangeUpdateState = useCallback(
    async ({ updateDataField, data, title, content, validation = true, typeUpdateState = 'canceled', state_id }: onChangeUpdateStateType) => {
      try {
        const isCancelOrder = state_id === ORDER_STATE.CANCELLED.toString();
        const isFraudOrder = state_id === ORDER_STATE.CANCELLATION_FOR_FRAUD.toString();

        const reason_id = isCancelOrder
          ? ((await OrderReasonModal.show({
              title: t('Reason for cancellation'),
              render: OrderReasonSelect
            })) as string)
          : isFraudOrder
          ? ORDER_REASON.FRAUD.toString()
          : data.order_cancel_reason.order_cancel_reason_id;

        const validate = validation
          ? await showModal({
              newContent: content,
              newTitle: title
            })
          : true;

        const isFraudReason = data.order_cancel_reason.order_cancel_reason_id.toString() === ORDER_REASON.FRAUD.toString();

        const toUpdateData = isFraudReason
          ? {
              order_id: data.order_id,
              order_cancel_reason: (data.order_cancel_reason.order_cancel_reason_id = Number(reason_id)),
              state: {
                order_state_id: (data.state.order_state_id = ORDER_STATE.CANCELLATION_FOR_FRAUD.toString())
              }
            }
          : {
              order_id: data.order_id,
              state: {
                order_state_id: (data.state.order_state_id = state_id)
              }
            };

        if (validate) {
          const dataUpdate =
            isCancelOrder || isFraudOrder
              ? await libby.ster_order_cancelled.save({
                  id_order: data.order_id,
                  reason_id
                })
              : await libby.ster_order_table.aspect('order_state').save(toUpdateData);

          updateDataField(
            {
              ...dataUpdate
            },
            'state'
          );
          enqueueSnackbar(t(`Order $$$ ${typeUpdateState}`).replace('$$$', data.order_id), {
            variant: 'success'
          });
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`Error ${error}`, { variant: 'error' });
        }
      }
    },
    [showModal, libby, enqueueSnackbar, t]
  );

  const onChangeUpdateStateAll = useCallback(
    async ({ updateDataField, data, title, content, state_id, typeUpdateState }: onChangeUpdateStateAllType): Promise<boolean> => {
      try {
        const validateAll = await showModal({
          newContent: content,
          newTitle: title
        });

        if (validateAll) {
          await Promise.all(
            data.map(async (file) => {
              await onChangeUpdateState({
                updateDataField,
                data: file,
                state_id,
                validation: false,
                typeUpdateState
              });
            })
          );
          return true;
        }
        return false;
      } catch (error) {
        if (error) {
          enqueueSnackbar(`Error ${error}`, { variant: 'error' });
        }
        return false;
      }
    },
    [showModal, enqueueSnackbar, onChangeUpdateState]
  );

  const onChangeUpdateStateAllWithRefer = useCallback(
    async ({ data, title, content }: onChangeUpdateStateAllType) => {
      try {
        const validate: any = await UpdateStateWithReferModal.show({
          content,
          title,
          data
        });

        if (validate) {
          /* eslint-disable no-await-in-loop */
          for (let index = 0; index < validate.files.length; index++) {
            const order_id = validate.files[index].id;
            try {
              await libby.ster_order_document_generate.save({
                file: validate.files[index],
                order_id,
                order_document_type_id: ORDER_DOCUMENT_TYPE.RECIBO,
                order_document_source_id: ORDER_DOCUMENT_SOURCE.COURIER,
                documentNumber: order_id
              });
              enqueueSnackbar('Receipt of order $$$ successfully saved'.replace('$$$', order_id), {
                variant: 'success'
              });
            } catch (error) {
              enqueueSnackbar(`${t('Error saving receipt for order $$$').replace('$$$', order_id)}: ${error.message}`, { variant: 'error' });
            }
          }
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`Error ${error}`, { variant: 'error' });
        }
      }
    },
    [enqueueSnackbar, libby, t]
  );

  return {
    onChangeUpdateState,
    onChangeUpdateStateAll,
    onChangeUpdateStateAllWithRefer
  };
};
