import React, { useCallback, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { Grid, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useField, useForm } from 'react-final-form-hooks';
import { useTranslation } from '../../services/translation';
import { textFieldProps, makeGetErrorAndHelperText } from '../../utils/materialHelpers';
import { useStyles } from './style/style';
import { EditProductBudgetFormType } from './types/types';

export const EditProductBudgetDialog = ({ title, cancelText, confirmText, customTheme = null, open = false, onCancel = () => {}, onConfirm = () => {}, formInitialValues }: EditProductBudgetFormType) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  const classes = useStyles();

  const onSubmit = useCallback(
    async ({ order_item_id, owner_item_id, name, sku, quantity, unit_price, marketplace_product_, product, products_c }) => {
      const orderItem = {
        order_item_id,
        owner_item_id,
        name,
        sku,
        quantity: parseInt(quantity, 10),
        unit_price,
        subtotal: (parseInt(quantity, 10) * parseInt(unit_price, 10)).toString(),
        marketplace_product_,
        product,
        products_c
      };

      onConfirm(orderItem);
    },
    [onConfirm]
  );

  const { form, handleSubmit } = useForm({
    initialValues: formInitialValues,
    onSubmit
  });

  const quantity = useField('quantity', form);
  const unit_price = useField('unit_price', form);

  const errors = useMemo(() => getErrorAndHelperText(quantity) || getErrorAndHelperText(unit_price), [getErrorAndHelperText, quantity, unit_price]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={classes.root}>
      <DialogTitle className={classes.modalTitle} id="alert-dialog-title">
        <Typography className={classes.title} variant="h3">
          {t(title || '')}
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText className={classes.modalContent} id="alert-dialog-description">
            SKU: {formInitialValues?.sku || ''}
          </DialogContentText>
          <Grid xs={12}>
            <TextField
              required
              {...textFieldProps(`${t('Quantity')}`)}
              onChange={quantity.input.onChange}
              value={quantity.input.value}
              variant="standard"
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ inputProps: { min: 1 } }}
              onBlur={quantity.input.onBlur}
              {...getErrorAndHelperText(quantity)}
            />
            <TextField
              required
              {...textFieldProps(`${t('Unit Price')}`)}
              onChange={unit_price.input.onChange}
              value={unit_price.input.value}
              variant="standard"
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ inputProps: { min: Math.floor(formInitialValues?.unit_price) * 0.9, max: formInitialValues?.unit_price * 1.1 } }}
              onBlur={unit_price.input.onBlur}
              {...getErrorAndHelperText(unit_price)}
            />
          </Grid>
        </DialogContent>
        <DialogActions className={classes.buttonText}>
          <Button
            onClick={() => {
              onCancel();
            }}
            color="primary"
          >
            {cancelText}
          </Button>
          {errors.error || unit_price.input.value > Math.floor(formInitialValues?.unit_price) * 1.1 || unit_price.input.value < Math.floor(formInitialValues?.unit_price) * 0.9 || parseInt(quantity.input.value, 10) <= 0 ? (
            <Button disabled color="primary" type="submit" autoFocus>
              {confirmText}
            </Button>
          ) : (
            <Button color="primary" type="submit" autoFocus>
              {confirmText}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
