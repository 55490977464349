import React, { ReactNode, useCallback, useMemo } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import 'moment/locale/es';
import moment from 'moment';
import { Grid, TextField, GridProps } from '@material-ui/core';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints } from '../../../../../const';
import { useTranslation } from '../../../../../services/translation';
import { useInitFilter, useInitFilterDebounced, useInitFilterArray } from '../../../../OrderReport/routes/OrderReportList/hook';
import { SelectOrderCourier } from '../../../../components/SelectOrderCourier';
import { SelectAccount } from '../../../components/SelectAccount';
import { SelectWithCollect, SelectAllStatus } from '../../../components';
import { DateClose } from '../../../../../components/DateClose';
import { SearchFiltersProps, Filter } from '../../../../../types';
import { makeFilter } from '../utils/makeFilter';
import { useInitFilterCollect } from '../../../hook';

type SearchFilterItemProps = {
  children: ReactNode;
  allGrid?: boolean;
};

const gridBreakpointsAll: GridProps = {
  xs: 12,
  sm: 6,
  md: 9,
  lg: 4
};

const SearchFilterItem = ({ children = null, allGrid = false }: SearchFilterItemProps) => {
  const gridBreakpointsInit = allGrid ? gridBreakpointsAll : gridBreakpoints;
  return (
    <Grid item {...gridBreakpointsInit}>
      {children}
    </Grid>
  );
};

const SearchFiltersRaw = ({ onFilterChange, initFetch }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const { initFilter, dispatchId, rolAdminLogistics, userId } = useInitFilterCollect();

  const { search, handleChange } = useInitFilterDebounced({
    initFetch: initFetch.search,
    init: '',
    onFilterChange,
    id: 'search',
    makeFilter
  });

  const { search: assigned, handleChangeFilter: handleChangeAssigned } = useInitFilter({
    initFetch: initFetch.assigned,
    init: '',
    onFilterChange,
    id: 'assigned',
    makeFilter
  });

  const { search: dispatch, handleChangeFilter: handleChangeDispatch } = useInitFilter({
    initFetch: initFetch.dispatch,
    init: '',
    onFilterChange,
    id: 'dispatch',
    makeFilter
  });

  const { search: cart, handleChangeFilter: handleChangeCart } = useInitFilter({
    initFetch: initFetch.cart,
    init: '',
    onFilterChange,
    id: 'cart',
    makeFilter
  });

  const { search: courier, handleChangeFilter: handleChangeCourier } = useInitFilter({
    initFetch: initFetch.courier,
    init: '',
    onFilterChange,
    id: 'courier',
    makeFilter
  });

  const { search: state, handleChangeFilter: setState } = useInitFilterArray({
    initFetch: initFetch.state,
    onFilterChange,
    id: 'state',
    makeFilter
  });

  const handleChangeState = useCallback(
    (value: Filter[]) => {
      setState(value);
    },
    [setState]
  );

  const { search: startDate, handleChangeFilterString: setStartDate } = useInitFilter({
    initFetch: initFetch.startDate,
    onFilterChange,
    init: null,
    id: 'startDate',
    date: true,
    makeFilter
  });

  const { search: endDate, handleChangeFilterString: setEndDate } = useInitFilter({
    initFetch: initFetch.endDate,
    onFilterChange,
    id: 'endDate',
    date: true,
    init: null,
    makeFilter
  });

  const handleReset = useCallback(() => {
    onFilterChange(initFilter);
  }, [onFilterChange, initFilter]);

  const stateSearch = useMemo(() => (initFetch?.state?.length ? initFetch?.state.map((stateValue) => stateValue.value) : []), [initFetch]);

  return (
    <FilterBar
      defaultFilter={
        <SearchFilterItem>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </SearchFilterItem>
      }
      handleReset={handleReset}
    >
      {rolAdminLogistics && (
        <SearchFilterItem>
          <SelectAccount
            label="Assigned to"
            inputProps={{
              value: assigned,
              onChange: handleChangeAssigned
            }}
            sort="asc"
          />
        </SearchFilterItem>
      )}
      {!dispatchId && (
        <SearchFilterItem>
          <SelectWithCollect
            inputProps={{
              value: dispatch,
              onChange: handleChangeDispatch
            }}
            assigned_id={!rolAdminLogistics && userId}
            collect_state_id={stateSearch}
            idData="dispatch_id"
            nameData="name"
            daoName="ster_dispatch_list"
            label="Dispatch"
            sort="asc"
          />
        </SearchFilterItem>
      )}
      <SearchFilterItem>
        <SelectWithCollect
          inputProps={{
            value: cart,
            onChange: handleChangeCart
          }}
          assigned_id={!rolAdminLogistics && userId}
          collect_state_id={stateSearch}
          idData="cart_id"
          nameData="name"
          daoName="ster_dispatch_cart_with_collect"
          label="Cart"
          sortBy="id"
          sort="asc"
        />
      </SearchFilterItem>

      <SearchFilterItem>
        <SelectOrderCourier inputProps={{ value: courier, onChange: handleChangeCourier }} optionAll sort="asc" />
      </SearchFilterItem>

      <SearchFilterItem>
        <DateClose name="Start Date" value={moment.isMoment(startDate) ? startDate : null} onChange={setStartDate} />
      </SearchFilterItem>
      <SearchFilterItem>
        <DateClose name="End Date" value={moment.isMoment(endDate) ? endDate : null} minValue={moment.isMoment(startDate) ? startDate : null} onChange={setEndDate} />
      </SearchFilterItem>
      <SearchFilterItem allGrid>
        <SelectAllStatus onChange={handleChangeState} allState={state} />
      </SearchFilterItem>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
