import { useMemo } from 'react';
import moment from 'moment';
import { useFilerParamsId, useLibbyFetch } from '../../../hooks';
import { AnyObject } from '../../../types';
import { columns } from '../utils';
import { useTranslation } from '../../../services/translation';

interface dataRowType {
  created_at: string;
  document: string;
  name: string | null;
  order_id: string;
  sku: string;
  so_number: string | null;
  marketplace: string;
  first_name: string;
  last_name: string;
  description: string;
  buyer_document: string;
  total: bigint;
  state: string;
  updated_at: string;
  id: string;
}

export const ReportingByTransportLogic = (libby: AnyObject) => {
  const { t } = useTranslation();

  const { orderBy, direction, handleRequestSort, paramsFetch, setFilter, filter } = useFilerParamsId({
    orderInit: 'document_date',
    directionInit: 'desc',
    daoName: 'ster_reporting_by_transport'
  });

  const { data, working, fetchMore } = useLibbyFetch(libby, paramsFetch);

  const rows = useMemo(() => {
    const dataRows: any = data.reduce((allDataRows: any, order: any) => {
      const copyAllDataRows = [...allDataRows];
      const { order_id, so_number, state, updated_at, created_at, document_date, first_name, last_name, document_number, sku, marketplace, description, buyer_document, total } = order;
      copyAllDataRows.push({
        order_id,
        id: order_id,
        so_number,
        state: t(state),
        marketplace,
        total,
        updated_at: moment(updated_at).format('YYYY-MM-DD HH:mm'),
        created_at: moment(created_at).format('YYYY-MM-DD HH:mm'),
        document_date: moment(document_date).format('YYYY-MM-DD HH:mm'),
        name: first_name === last_name ? first_name : `${first_name} ${last_name}`,
        buyer_document,
        document_number,
        sku,
        description
      });
      return copyAllDataRows;
    }, []);
    return dataRows;
  }, [data, t]);

  return {
    rows,
    columns,
    setFilter,
    filter,
    paramsFetch,
    orderBy,
    direction,
    handleRequestSort,
    working,
    fetchMore
  };
};
