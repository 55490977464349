import { Column } from '../../../../../components/InfoTable';

export const columnsReportingLogisticSla: Array<Column> = [
  {
    id: 'actions',
    label: '',
    noSort: true,
    width: 30
  },
  {
    id: 'marketplace_sla_id',
    label: 'Marketplace sla id',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'marketplace_sla_id'
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'marketplace.name'
  },
  {
    id: 'sla',
    label: 'SLA',
    orderById: 'sla'
  }
];
