import { Box } from '@material-ui/core';
import React from 'react';
import { Order_reporting_delivery_control_reporting } from '../../../../../../interfaces/business';
import { formatDate } from '../../../../../../utils';
import { minutesToString } from '../../../../../../utils/frecuendyHours';
import { Column } from '../../../../../components/InfoTable';

export const columnsReportingDeliveryControl: Array<Column> = [
  {
    id: 'order_id_22',
    label: 'Order id',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'so',
    label: 'So'
  },
  {
    id: 'day',
    label: 'Date - waiting for preparation',
    orderById: 'day',
    render: ({ t_waiting_preparation_2 }: Order_reporting_delivery_control_reporting) => <Box>{formatDate(t_waiting_preparation_2 ?? '', false, false)}</Box>
  },
  {
    id: 'hour',
    label: 'Hour - waiting for preparation',
    orderById: 'hour',
    render: ({ t_waiting_preparation_2 }: Order_reporting_delivery_control_reporting) => <Box>{formatDate(t_waiting_preparation_2 ?? '', true, false, 'HH:mm')}</Box>
  },
  {
    id: 'date',
    label: 'Date - out for delivery',
    orderById: 'date',
    render: ({ t_out_for_delivery_2 }: Order_reporting_delivery_control_reporting) => <Box>{formatDate(t_out_for_delivery_2 ?? '', false, false)}</Box>
  },
  {
    id: 'hour',
    label: 'Hour - out for delivery',
    orderById: 'hour',
    render: ({ t_out_for_delivery_2 }: Order_reporting_delivery_control_reporting) => <Box>{formatDate(t_out_for_delivery_2 ?? '', true, false, 'HH:mm')}</Box>
  },
  {
    id: 'delta2',
    label: 'Total hours',
    orderById: 'delta2',
    render: ({ delta2 }: Order_reporting_delivery_control_reporting) => <Box>{minutesToString(delta2)}</Box>
  },
  {
    id: 'effectiveness',
    label: 'Effectiveness',
    orderById: 'effectiveness',
    render: ({ effectiveness }: any) => <Box>{effectiveness === '0' ? 'Ok' : minutesToString(parseInt(effectiveness, 10))}</Box>
  }
];
