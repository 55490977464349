import React, { useCallback, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AnyObject, LibbyObject } from '../../types/types';
import { makeMapLabelValues } from '../utils/mappers';
import { useTranslation } from '../../services/translation';
import { InformationSection } from '../../components/InformationSection';
import { formatMoney, formatDate, translate } from '../../utils';
import { ItemsTable } from './ItemsTable';
import { DocumentsTable } from './DocumentsTable';
import { gridBreakpointsOrderDetails } from '../../const/gridBreakpoints';
import { Courier_service, Order_shipment, Order_shipment_receiver, Order_shipment_state, Order_shipment_address } from '../../interfaces/business';
import { useSendOrderEdit } from '../Orders/routes/OrderDetail/hook/useSendOrderEdit';
import { useGetStates } from '../Orders/routes/OrderDetail/hook';
import { ACCOUNT_ROLE, LINK_TRACKING_BY_COURIER, MARKETPLACE, ORDER_STATE } from '../../const';
import { useRolesUser } from '../../hooks';

const useStyles = makeStyles({
  containInfo: {
    paddingTop: '15px'
  },
  EditButtonDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px'
  },
  formFields: {
    padding: '10px',
    width: '100%'
  },
  loading: {
    marginRight: '30px',
    marginTop: '5px'
  }
});

const basicInformationMap = [
  {
    key: 'updated_at',
    label: 'Last Update',
    formatter: (value: any) => formatDate(value, true, false)
  },
  {
    key: 'source',
    label: 'Marketplace',
    formatter: (value: any) => value?.marketplace?.name
  },
  {
    key: 'owner_id',
    label: 'Owner ID'
  },
  {
    key: 'state',
    label: 'State',
    formatter: (value: any) => translate(value?.name)
  },
  {
    key: 'order_cancel_reason',
    label: 'Reason for cancellation',
    formatter: (value: any) => {
      return value?.order_cancel_reason_id !== '-1' ? translate(value?.name) : '-';
    }
  },
  {
    key: 'amount',
    label: 'Amount',
    formatter: (value: any) => formatMoney(value)
  },
  {
    key: 'tax',
    label: 'Tax',
    formatter: (value: any) => formatMoney(value)
  },
  {
    key: 'total',
    label: 'Total',
    formatter: (value: any) => formatMoney(value)
  },
  {
    key: 'shipment_cost',
    label: 'Shipment Cost',
    formatter: (value: any, data: any) => formatMoney(value || data?.shipping_cost)
  }
];

const basicInformationMapWithPaymentGateway = [
  ...basicInformationMap,
  {
    key: 'payment_gateway',
    label: 'GW of payments',
    enableNull: true,
    formatter: (value: any) => translate(value?.payment_gateway_id ? value.name : 'Does not have GW of payments')
  }
];

export const buyerDirection = (
  street: string | null,
  street_number: string | null,
  cityName: string | null,
  floor: string | null,
  department: string | null,
  extra_address: string | null,
  comments: string | null,
  cityState: string | null
) => `${street || ''} ${street_number || ''}, ${cityName || ''}, ${department?.length && department !== '0' ? `${floor && floor > '0' ? `piso ${floor}` : ''} dpto ${department},` : ''}
     ${extra_address?.length ? `${extra_address},` : ''}
      ${comments?.length ? `${comments},` : ''}${cityState || ''}`;

const buyerMap = [
  {
    key: 'first_name',
    label: 'First Name'
  },
  {
    key: 'last_name',
    label: 'Last Name'
  },
  {
    key: 'document_type',
    label: 'Document Type',
    formatter: (value: any) => value?.name
  },
  {
    key: 'document',
    label: 'Document'
  },
  {
    key: 'email',
    label: 'Email'
  },
  {
    key: 'phone_number',
    label: 'Phone Number'
  },
  {
    key: 'address',
    label: 'Address',
    formatter: (value: any, data: any) => buyerDirection(data?.street, data?.street_number, data?.city?.name, data?.floor, data?.department, data?.extra_address, data?.comments, data?.city?.state?.name)
  },
  {
    key: 'zip',
    label: 'Zip Code'
  }
];

const receiver = (value: Order_shipment_receiver) => {
  let receiverName = '';

  if (value?.first_name || value?.last_name) {
    receiverName += `${value?.first_name} ${value?.last_name || ''} |`;
  }

  if (value?.document_type?.name || value?.document) {
    receiverName += `${value?.document_type?.name} ${value?.document} |`;
  }

  if (value?.email) {
    receiverName += `${value?.email} |`;
  }

  if (value?.comments) {
    receiverName += value?.comments;
  }

  return receiverName;
};

const shipmentMap = [
  {
    key: 'type',
    label: 'Type',
    formatter: (value: any) => translate(value)
  },
  {
    key: 'service',
    label: 'Service',
    formatter: (service: Courier_service) => translate(service?.name)
  },
  {
    key: 'courier',
    label: 'Courier',
    formatter: (value: any, data: Order_shipment) => translate(data?.service?.courier?.name)
  },

  {
    key: 'address',
    label: 'Shipment Address',
    formatter: (value: any) => buyerDirection(value?.street, value?.street_number, value?.city?.name, value?.floor, value?.department, value?.extra_address, value?.comments, value?.city?.state?.name)
  },
  {
    key: 'address',
    label: 'Zip',
    formatter: (value: Order_shipment_address) => value.zip || 'N/A'
  },
  {
    key: 'comments',
    label: 'Comments',
    formatter: (value: any, data: any) => data?.address?.comments || '-'
  },
  {
    key: 'receiver',
    label: 'Receiver',
    formatter: (value: Order_shipment_receiver) => receiver(value)
  },
  {
    key: 'state',
    label: 'State',
    formatter: (state: Order_shipment_state) => translate(state?.name)
  },
  {
    key: 'tracking',
    label: 'Tracking',
    link: (tracking: string, { service }: Order_shipment) => {
      const courier_id = service?.courier?.courier_id;
      const link = LINK_TRACKING_BY_COURIER[courier_id];
      return link ? `${link}/${tracking}` : null;
    },
    formatter: (value: any) => value || ''
  },
  {
    key: 'updated_at',
    label: 'Last Update',
    formatter: (value: any) => formatDate(value, true, false)
  }
];

interface OrderDetailDataInterface {
  data: AnyObject;
  libby: LibbyObject;
}

const MARKETPLACE_PAYMENT_GATEWAY_ENABLED = [MARKETPLACE.ENOVA, MARKETPLACE.NOVATECH_STORE, MARKETPLACE.DETECNOLOGIA, MARKETPLACE.VENTA_TELEFONICA];

export const OrderDetailDataRaw = ({ data, libby }: OrderDetailDataInterface) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const mapperLabelValues = makeMapLabelValues(t);

  const isPaymentGateway = useMemo(() => MARKETPLACE_PAYMENT_GATEWAY_ENABLED.includes(data.source?.marketplace?.marketplace_id?.toString()), [data]);
  const basicMapper = mapperLabelValues(isPaymentGateway ? basicInformationMapWithPaymentGateway : basicInformationMap);

  const buyerMapper = mapperLabelValues(buyerMap);
  const shipmentMapper = mapperLabelValues(shipmentMap);
  const location = useLocation();

  const verificatePathname = useMemo(() => location.pathname.split('/'), [location.pathname]);

  const roleActiveUser = useRolesUser({
    rolesEnabled: [ACCOUNT_ROLE.HEAD_MANAGEMENT, ACCOUNT_ROLE.ADMINISTRATOR, ACCOUNT_ROLE.MANAGEMENT]
  });

  const { states } = useGetStates({ libby });

  const handleGetCity = useCallback(
    async (city: string, province: string) => {
      const cityFetch = await libby?.ster_city.query().equals('name', city).equals('state.name', province).run();
      return cityFetch;
    },
    [libby]
  );

  const { handleModal, loading } = useSendOrderEdit({
    data,
    libby,
    states,
    handleGetCity
  });

  return (
    <>
      {roleActiveUser ? (
        data.state.order_state_id === ORDER_STATE.OUT_FOR_DELIVERY || data.print ? (
          <Grid className={classes.EditButtonDiv} xs={12}>
            <Button disabled variant="contained" color="primary">
              {t('Edit')}
            </Button>
          </Grid>
        ) : (
          <Grid className={classes.EditButtonDiv} xs={12}>
            {loading && <CircularProgress className={classes.loading} color="primary" size={24} />}
            {verificatePathname[1] === 'orders' && (
              <Button onClick={() => handleModal()} variant="contained" color="primary">
                {t('Edit')}
              </Button>
            )}
          </Grid>
        )
      ) : (
        ''
      )}
      <Grid className={classes.containInfo}>
        <Grid container spacing={1} justify="space-between">
          <InformationSection title={t('Basic Information')} containerProps={gridBreakpointsOrderDetails} noPadding>
            {basicMapper(data)}
          </InformationSection>
          {data.buyer && (
            <InformationSection title={t('Buyer')} containerProps={gridBreakpointsOrderDetails} noPadding>
              {buyerMapper(data.buyer)}
            </InformationSection>
          )}
          {data.shipment && (
            <InformationSection title={t('Shipment')} containerProps={gridBreakpointsOrderDetails} noPadding>
              {shipmentMapper(data.shipment)}
            </InformationSection>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.containInfo}>
        {data.items && (
          <InformationSection title={t('Items')} noPadding>
            <ItemsTable items={data.items} />
          </InformationSection>
        )}
      </Grid>
      <Grid className={classes.containInfo}>
        {data.documents && (
          <InformationSection title={t('Documents')} noPadding>
            <DocumentsTable documents={data.documents} />
          </InformationSection>
        )}
      </Grid>
    </>
  );
};

export const OrderDetailData = DatabaseConnector(OrderDetailDataRaw)('ster_order_table', 'ster_city', 'ster_state', 'ster_order_table_update');
