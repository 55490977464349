import React from 'react';
import { AnyObject } from '../../types/types';
import { GeneralSelect } from '../GeneralSelect';
import { DataGenerateAutocompleteType } from '../../utils/generateAutocomplete';
import { GeneralAutocomplete } from '../GeneralAutocomplete';

type SelectImportTypeProps = {
  multiple?: boolean;
  inputProps?: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label: string;
  data: DataGenerateAutocompleteType[];
  working: boolean;
  id: string;
  sortBy?: string;
};

export const SelectAutocomplete = ({ id, data, working, multiple = false, inputProps = {}, optionAll = false, disabled = false, sort, sortBy, label, ...styles }: SelectImportTypeProps) => (
  <>
    {multiple ? (
      <GeneralAutocomplete id={id} value={inputProps.valueArray} onChange={inputProps.onChange} working={working} label={label} disabled={disabled} data={data} />
    ) : (
      <GeneralSelect disabled={disabled} working={working} label={label} optionAll={optionAll} data={data} inputProps={inputProps} {...styles} sort={sort} sortBy={sortBy} />
    )}
  </>
);
