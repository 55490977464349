import React from 'react';
import { ScreenAligned } from '../../../components/ScreenAligned/ScreenAligned';
import { LibbyObject } from '../../../types';
import InfoTable from '../../components/InfoTable';
import { useStockLogic } from '../hook/useStockLogic';
import { stockColumns } from '../utils';
import { SearchFilters } from '../components';
import LoadingData from '../../components/LoadingData';
import { useTranslation } from '../../../services/translation';

interface StockListTypes {
  title: string;
  libby: LibbyObject;
}

export const StockList = ({ title, libby }: StockListTypes) => {
  const { handleProductSort, rows, orderBy, direction, filter, setFilter, resetFilter, working } = useStockLogic(libby);
  const { t } = useTranslation();
  return (
    <ScreenAligned title={title}>
      <SearchFilters onFilterChange={setFilter} filter={filter} resetFilter={resetFilter} />
      <InfoTable columns={stockColumns} rows={rows} onSortChange={handleProductSort} direction={direction} orderBy={orderBy} onBottomScroll={() => {}} />
      <LoadingData label={`${t('Loaded stock')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
