import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { EditOrderDialog } from '../../../../../components/EditOrderDialog/EditOrderDialog';
import customFormDialog from '../../../../../services/customFormDialog';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject, LibbyObject } from '../../../../../types';
import { NewOrderType } from '../types/types';
import { validate } from '../model/model';

const CreateEditOrderModal = customFormDialog(EditOrderDialog);

interface useSendOrderEditType {
  data: AnyObject;
  libby: LibbyObject;
  states: AnyObject;
  handleGetCity: (city: string, province: string) => Promise<any[]>;
}

export const useSendOrderEdit = ({ data, libby, states, handleGetCity }: useSendOrderEditType) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [reset, setReset] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleModal = async () => {
    const { buyer, shipment } = data;
    const { address } = shipment;
    const formInitialValues = {
      first_name: buyer.first_name || '',
      last_name: buyer.last_name || '',
      document: buyer.document || '',
      email: buyer.email || '',
      phone_number: buyer.phone_number || '',
      buyerDepartment: buyer.department || '',
      buyerFloor: buyer.floor || '',
      buyerStreet: buyer.street || '',
      buyerStreetNumber: buyer.street_number || '',
      buyerExtraAddress: buyer.extra_address || '',
      buyerZip: buyer.zip || '',
      buyerCity: buyer.city.name || '',
      buyerProvince: buyer.city.state.name || '',
      province: address.city.state.name || '',
      city: address.city.name || '',
      street: address.street || '',
      street_number: address.street_number || '',
      zip: address.zip || '',
      floor: address.floor || '',
      department: address.department || '',
      extra_address: address.extra_address || ''
    };

    const confirm: any = await CreateEditOrderModal.show({
      titleEditOrder: t('Edit Order'),
      confirmText: t('Save'),
      cancelText: t('Cancel'),
      data,
      formInitialValues,
      reset,
      validate,
      states,
      cityArray: address.city,
      buyerCityArray: buyer.city,
      handleGetCity
    });
    const {
      first_name,
      last_name,
      document,
      email,
      buyerDepartment,
      buyerFloor,
      buyerStreet,
      buyerStreetNumber,
      buyerExtraAddress,
      buyerZip,
      buyerCity,
      city,
      street,
      zip,
      floor,
      department,
      phone_number,
      street_number,
      extra_address,
      province,
      buyerProvince
    } = confirm;

    const newCity = handleGetCity && (await handleGetCity(city, province));

    const arrayBuyerCity = handleGetCity && (await handleGetCity(buyerCity, buyerProvince));

    if (confirm) {
      setLoading(true);
      try {
        const newOrderSaved: NewOrderType = await libby.ster_order_table_update.save({
          ...data,
          buyer: {
            ...buyer,
            city: arrayBuyerCity[0],
            first_name,
            last_name,
            document,
            email,
            phone_number,
            street: buyerStreet,
            street_number: parseInt(buyerStreetNumber, 10),
            department: buyerDepartment,
            floor: parseInt(buyerFloor, 10),
            extra_address: buyerExtraAddress,
            zip: parseInt(buyerZip, 10)
          },
          shipment: {
            ...shipment,
            receiver: {
              ...shipment.receiver,
              first_name,
              last_name,
              document,
              email,
              phone_number
            },
            address: {
              ...address,
              city: newCity[0],
              street,
              street_number: parseInt(street_number, 10),
              zip: parseInt(zip, 10),
              floor: parseInt(floor, 10),
              department,
              extra_address
            }
          }
        });
        if (newOrderSaved) {
          enqueueSnackbar(t('Order saved'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('Something went wrong'), { variant: 'error' });
          setReset(!reset);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setReset(!reset);
        enqueueSnackbar(t('Something went wrong'), { variant: 'error' });
      }
    }
  };
  return { handleModal, loading };
};
