import React from 'react';
import { makeMapLabelValues } from '../../../../utils/mappers';
import { useTranslation } from '../../../../../services/translation';
import { formatDate } from '../../../../../utils';
import { DetailsData } from '../../../../../components/DetailsData';
import { Collect_state, Account, Collect, Cart } from '../../../../../interfaces/business';

export interface OrderDetailDataType {
  onEditCollect?: Function;
  working?: boolean;
  data: Collect | undefined;
  readOnlyData: boolean;
}

export const OrderDetailData = ({ data: dataAll, working, onEditCollect, readOnlyData }: OrderDetailDataType) => {
  const { t } = useTranslation();

  const basicInformationMap = [
    {
      key: 'dispatch',
      label: 'Dispatch',
      formatter: (value: any, data: Collect) => data?.dispatch?.name || '-'
    },
    {
      key: 'cart',
      label: 'Cart',
      formatter: (value: Cart) => value?.name || '-'
    },
    {
      key: 'courier',
      label: 'Courier',
      formatter: (value: any, data: Collect) => data?.dispatch?.courier_service?.courier?.name || '-'
    },
    {
      key: 'courier_service',
      label: 'Service',
      formatter: (value: any, data: Collect) => data?.dispatch?.courier_service?.name || '-'
    },
    {
      key: 'account',
      label: 'Assigned',
      formatter: (value: Account) => value?.username || '-'
    },
    {
      key: 'collectState',
      label: 'State',
      formatter: (value: Collect_state) => t(value.name)
    },
    {
      key: 'delivery_date',
      label: 'Delivery date',
      formatter: (value: any, data: any) => (data?.dispatch?.delivery_date ? formatDate(data?.dispatch?.delivery_date, false) : '-')
    }
  ];

  const basicInformationMapInquiry = [...basicInformationMap];

  const mapperLabelValues = makeMapLabelValues(t, 'row', false, {
    lg: 2,
    md: 2,
    sm: 6,
    style: { padding: '10px' }
  });

  const basicMapper = mapperLabelValues(onEditCollect ? basicInformationMap : basicInformationMapInquiry);

  return <DetailsData working={working} data={dataAll} onEdit={onEditCollect} readOnlyData={readOnlyData} basicMapper={basicMapper} />;
};
