import { useState, useEffect, useCallback } from 'react';
import { LibbyObject } from '../../../types';
import { useLibbyFetchById } from '../../../hooks/useLibbyFetchById';
import { Collect } from '../../../interfaces/business';

interface useCollectStartedProp {
  libby: LibbyObject;
  id: string;
  updateData: (Collect: Collect, id: string) => void;
}

export const useCollectStarted = ({ libby, id, updateData }: useCollectStartedProp) => {
  const { working, data, recall } = useLibbyFetchById(libby, {
    daoName: 'ster_dispatch_collect_details',
    id,
    aspect: 'list_details'
  });

  const [dataCollect, setDataCollect] = useState<any>();

  useEffect(() => {
    setDataCollect(data);
  }, [data]);

  const updateDataCollect = useCallback(
    (newDataCollect) => {
      setDataCollect(newDataCollect);
      updateData(newDataCollect, 'collect_id');
    },
    [updateData]
  );

  return {
    working,
    data: dataCollect,
    recall,
    updateData: updateDataCollect
  };
};
