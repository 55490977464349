import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useParams } from 'react-router-dom';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useBreadcrumbs, useLibbyFetch } from '../../../../hooks';
import { LibbyObject, OrderType } from '../../../../types/types';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { useOrderBuyerLogs, useOrderLogs, useOrderShipmentAddressLogs } from './hook/useOrderStateLogic';
import { StateLogsTab } from './utils/StateLogsTabs';

type OrderStateLogsProps = {
  libby: LibbyObject;
  title: TitleBreadcrumbs[];
};

const OrderStateLogsRaw = ({ libby, title }: OrderStateLogsProps) => {
  const { id } = useParams();

  const { data: states } = useLibbyFetch(libby, {
    daoName: 'order_state'
  });

  const { working, data } = useOrderLogs({ libby, id });
  const { dataBuyer } = useOrderBuyerLogs({ libby, id });
  const { dataShipment } = useOrderShipmentAddressLogs({ libby, id });

  const titleShow = useBreadcrumbs('Order Logs', title, `#${id}`);

  const rows = useMemo(
    () =>
      data.map((dataInner: OrderType) => {
        const stateSelected = states.find((state) => state.order_state_id === dataInner.order_state_id);
        return {
          order_log_id: dataInner.order_log_id,
          order: dataInner.order,
          order_state_name: stateSelected ? stateSelected.name : '',
          order_state_name_lower: stateSelected ? stateSelected.name.toLowerCase() : '',
          order_state_id: dataInner.order_state_id,
          updated_by: dataInner.updated_by?.username || '',
          updated_at: dataInner.updated_at,
          created_at: dataInner.created_at
        };
      }),
    [data, states]
  );

  return (
    <ScreenAligned title={titleShow} working={working} showData={!!data}>
      <StateLogsTab data={rows} dataBuyer={dataBuyer} dataShipment={dataShipment} />
    </ScreenAligned>
  );
};

export const OrderStateLogs = DatabaseConnector(OrderStateLogsRaw)('ster_order_logs', 'ster_order_buyer_logs', 'ster_order_shipment_address_logs', 'order_state');
