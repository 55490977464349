import React, { useLayoutEffect, useMemo, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { Box, FormControl, FormHelperText, InputLabel, OutlinedInput, Typography } from '@material-ui/core';
import { SelectRole } from '../../components/SelectRole';

import { useTranslation } from '../../../../../services/translation';
import { AnyObject } from '../../../../../types/types';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../../utils/materialHelpers';
import { ValidatePassword } from '../../../../../components/ValidatePassword';
import { LoadingTable } from '../../../../../components/LoadingTable';
import { useStyles } from '../styles';
import { Account } from '../../../../../interfaces/business';

type InputsFormProps = {
  account: Account | null;
  username: AnyObject;
  password: AnyObject;
  email: AnyObject;
  account_role?: AnyObject;
  duplicateAccount: boolean;
  duplicateAccountEmailData: boolean;
  loadingAccountEmailData: boolean;
};

export const InputsForm = ({ account, username, password, email, account_role, duplicateAccount, duplicateAccountEmailData, loadingAccountEmailData }: InputsFormProps) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  const accountInput = useRef<HTMLHeadingElement>(null);
  const passwordInput = useRef<HTMLHeadingElement>(null);
  const emailInput = useRef<HTMLHeadingElement>(null);
  const classes = useStyles();

  useLayoutEffect(() => {
    if (!account) {
      setTimeout(() => {
        if (accountInput.current != null) {
          accountInput.current.focus();
        }
      }, 0);
    } else if (emailInput && emailInput.current) {
      setTimeout(() => {
        if (emailInput.current != null) {
          emailInput.current.focus();
        }
      }, 0);
    } else if (passwordInput && passwordInput.current) {
      setTimeout(() => {
        if (passwordInput.current != null) {
          passwordInput.current.focus();
        }
      }, 0);
    }
  }, [account]);

  const disabledInput = useMemo(() => duplicateAccountEmailData || !!getErrorAndHelperText(email).error, [duplicateAccountEmailData, email, getErrorAndHelperText]);

  return (
    <>
      <TextField {...username.input} disabled={!!account} {...textFieldProps(t('Username'))} inputRef={accountInput} />

      {!duplicateAccount && !!account && (
        <div>
          <Box mb={2} mt={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">{textFieldProps(t('Email')).label}</InputLabel>
              <OutlinedInput
                aria-describedby="component-error-text"
                {...textFieldProps(t('Email'))}
                {...email.input}
                {...getErrorAndHelperText(email)}
                type="email"
                inputRef={emailInput}
                endAdornment={!!loadingAccountEmailData && <LoadingTable />}
              />
              {disabledInput && (
                <FormHelperText error id="component-error-text">
                  {!getErrorAndHelperText(email).error ? (
                    <>
                      {t('This email is already associated with another associated id')}

                      <Box flexDirection="row" display="flex">
                        <Typography variant="h6" color="textPrimary" className={classes.errorTitle}>
                          {`${t('Associated id')}: `}
                        </Typography>
                        <Box ml={1}>
                          <Typography variant="h6" color="primary" className={classes.errorTitle}>
                            {account?.username}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    getErrorAndHelperText(email).helperText
                  )}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <ValidatePassword ref={passwordInput} genericInput={password} value={password.input.value} disabled={disabledInput} />
          {account_role && <SelectRole inputProps={{ ...account_role.input, ...getErrorAndHelperText(account_role) }} disabled={disabledInput} />}
        </div>
      )}
    </>
  );
};

InputsForm.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  account: {}
};
