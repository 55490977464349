export const stockLocations = [
  {
    id: '798',
    name: 'BCOR/Stock'
  },
  {
    id: '340',
    name: 'BNA/Post-Production'
  },
  {
    id: '346',
    name: 'BNA/Pre-Production'
  },
  {
    id: '342',
    name: 'BNA/Stock'
  },
  {
    id: '478',
    name: 'C.Arg/Stock'
  },
  {
    id: '433',
    name: 'Comex/Stock'
  },
  {
    id: '677',
    name: 'DE110/Stock'
  },
  {
    id: '79',
    name: 'Dep. 109/Stock'
  },
  {
    id: '20',
    name: 'Dep60/Depósito desecho'
  },
  {
    id: '13',
    name: 'Dep60/Input'
  },
  {
    id: '681',
    name: 'Dep60/IT'
  },
  {
    id: '567',
    name: 'Dep60/MP'
  },
  {
    id: '619',
    name: 'Dep60/MP/MON'
  },
  {
    id: '813',
    name: 'Dep60/MP/MON/leeseda'
  },
  {
    id: '581',
    name: 'Dep60/MP/NBK'
  },
  {
    id: '879',
    name: 'Dep60/MP/NBK/greatwall'
  },
  {
    id: '808',
    name: 'Dep60/MP/NBK/hena'
  },
  {
    id: '809',
    name: 'Dep60/MP/NBK/iproda'
  },
  {
    id: '807',
    name: 'Dep60/MP/NBK/semmax'
  },
  {
    id: '881',
    name: 'Dep60/MP/NBK/semmax 2C'
  },
  {
    id: '882',
    name: 'Dep60/MP/NBK/semmax 3C'
  },
  {
    id: '802',
    name: 'Dep60/MP/NBK/semmax panman'
  },
  {
    id: '595',
    name: 'Dep60/MP/PCP'
  },
  {
    id: '578',
    name: 'Dep60/MP/TBL'
  },
  {
    id: '815',
    name: 'Dep60/MP/TBL/aaa'
  },
  {
    id: '814',
    name: 'Dep60/MP/TBL/iproda'
  },
  {
    id: '12',
    name: 'Dep60/Stock'
  },
  {
    id: '685',
    name: 'Dep60/TBR'
  },
  {
    id: '886',
    name: 'devol/Stock'
  },
  {
    id: '205',
    name: 'Fráve/Post-Production'
  },
  {
    id: '204',
    name: 'Fráve/Pre-Production'
  },
  {
    id: '199',
    name: 'Fráve/Stock'
  },
  {
    id: '860',
    name: 'Gar21/Stock'
  },
  {
    id: '723',
    name: 'GARBC/Stock'
  },
  {
    id: '100',
    name: 'ICBC/Stock'
  },
  {
    id: '160',
    name: 'INGCC/Gestión de Calidad'
  },
  {
    id: '158',
    name: 'INGCC/Gestión de Calid. dev. proveedor'
  },
  {
    id: '159',
    name: 'INGCC/Gestión de Calid mat. no conf.'
  },
  {
    id: '161',
    name: 'INGCC/Ingenieria y Dearrollo'
  },
  {
    id: '151',
    name: 'INGCC/Stock'
  },
  {
    id: '23',
    name: 'ML Full/Stock'
  },
  {
    id: '148',
    name: 'Muest/Stock'
  },
  {
    id: '149',
    name: 'Outlet/Equipos de Segunda'
  },
  {
    id: '55',
    name: 'Outlet/Stock'
  },
  {
    id: '525',
    name: 'PAMI/Post-Production'
  },
  {
    id: '524',
    name: 'PAMI/Pre-Production'
  },
  {
    id: '521',
    name: 'PAMI/Stock'
  },
  {
    id: '868',
    name: 'PCATA/Stock'
  },
  {
    id: '473',
    name: 'PostV/Mercadería en tránsito'
  },
  {
    id: '632',
    name: 'PostV/MOS_USPA'
  },
  {
    id: '490',
    name: 'PostV/OUT'
  },
  {
    id: '467',
    name: 'PostV/Stock'
  },
  {
    id: '371',
    name: 'Prod./Input linea 1'
  },
  {
    id: '372',
    name: 'Prod./Input linea 2'
  },
  {
    id: '451',
    name: 'Prod./Input linea 3'
  },
  {
    id: '532',
    name: 'Prod./NG'
  },
  {
    id: '753',
    name: 'Prod./NG/PCP'
  },
  {
    id: '533',
    name: 'Prod./NG/TV'
  },
  {
    id: '19',
    name: 'Prod./Post-Production'
  },
  {
    id: '138',
    name: 'Prod./Pre-Production'
  },
  {
    id: '554',
    name: 'Prod./SerTec'
  },
  {
    id: '812',
    name: 'Prod./SerTec/aaa'
  },
  {
    id: '810',
    name: 'Prod./SerTec/hena'
  },
  {
    id: '811',
    name: 'Prod./SerTec/iproda'
  },
  {
    id: '805',
    name: 'Prod./SerTec/Leeseda'
  },
  {
    id: '806',
    name: 'Prod./SerTec/Semmax fugluz'
  },
  {
    id: '893',
    name: 'Prod./SerTec/Semmax INC'
  },
  {
    id: '892',
    name: 'Prod./SerTec/Semmax NG'
  },
  {
    id: '803',
    name: 'Prod./SerTec/Semmax RW'
  },
  {
    id: '804',
    name: 'Prod./SerTec/Semmax rwn'
  },
  {
    id: '133',
    name: 'Prod./Stock'
  },
  {
    id: '599',
    name: 'Prod./Stock/LICMS'
  },
  {
    id: '528',
    name: 'Prod./TBR'
  },
  {
    id: '555',
    name: 'SE/MP'
  },
  {
    id: '558',
    name: 'SE/MP/GAB'
  },
  {
    id: '557',
    name: 'SE/MP/MON'
  },
  {
    id: '575',
    name: 'SE/MP/NBK'
  },
  {
    id: '556',
    name: 'SE/MP/TBL'
  },
  {
    id: '559',
    name: 'SE/MP/VAR'
  },
  {
    id: '726',
    name: 'SE/OTR'
  },
  {
    id: '683',
    name: 'SE/Outlet'
  },
  {
    id: '572',
    name: 'SE/PVOUT'
  },
  {
    id: '571',
    name: 'SE/PVSTOCK'
  },
  {
    id: '1033',
    name: 'Serv. Téc./Devoluciones'
  },
  {
    id: '86',
    name: ' Serv. Téc./Devoluciones2'
  },
  {
    id: '259',
    name: 'Serv. Téc./Mercadería en tránsito'
  },
  {
    id: '47',
    name: 'Serv. Téc./RMA'
  },
  {
    id: '31',
    name: 'Serv. Téc./Stock'
  },
  {
    id: '452',
    name: 'Serv. Téc./Stock de Rep.'
  },
  {
    id: '498',
    name: 'SE/Stock'
  },
  {
    id: '116',
    name: 'SHOWR/Stock'
  },
  {
    id: '163',
    name: 'SKD35/Stock'
  },
  {
    id: '586',
    name: 'TEMP/Stock'
  },
  {
    id: '628',
    name: 'TiBNA/Post-Production'
  },
  {
    id: '630',
    name: 'TiBNA/Pre-Production'
  },
  {
    id: '627',
    name: 'TiBNA/Stock'
  },
  {
    id: '444',
    name: 'TN/Stock'
  },
  {
    id: '665',
    name: 'TOMLF/Stock'
  },
  {
    id: '649',
    name: 'TO_ML/Stock'
  },
  {
    id: '418',
    name: 'UBA/Post-Production'
  },
  {
    id: '414',
    name: 'UBA/Pre-Production'
  },
  {
    id: '417',
    name: 'UBA/Stock'
  },
  {
    id: '375',
    name: 'WILDE/Stock'
  }
];
