import React, { useCallback } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { FormProps } from '../../../../../../types';
import { useTranslation } from '../../../../../../services/translation';
import { makeGetErrorAndHelperText, replaceNonNumericCharacters, textFieldProps } from '../../../../../../utils';
import { ResetForm } from '../../../../../../components/ResetForm';
import { useModalFormStyles } from '../../../../../../theme';
import { SelectMarketPlaceSlaUnassigned } from '../../../../../components';

export type CreateMarketplaceType = {
  sla: number;
  marketplace: string;
};

export const AddSlaForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {}
}: FormProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(
    async ({ marketplace, sla }) => {
      onConfirm({
        marketplace,
        sla
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  const marketplace = useField('marketplace', form);
  const marketplaceName = useField('marketplace_name', form);
  const sla = useField('sla', form);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <SelectMarketPlaceSlaUnassigned
            inputProps={{
              onChange: marketplace.input.onChange,
              value: marketplace.input.value || '',
              name: marketplaceName.input.value,
              ...getErrorAndHelperText(marketplace)
            }}
          />

          <TextField
            {...textFieldProps(t('Sla'))}
            {...sla.input}
            {...getErrorAndHelperText(sla)}
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = replaceNonNumericCharacters(value);
              sla.input.onChange(event);
              return event;
            }}
            type="text"
          />

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
