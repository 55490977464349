import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { orderBy } from 'lodash';
import { Box, Grid, Switch, Theme, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import InfoTable, { Column } from '../../../components/InfoTable';
import { useTranslation } from '../../../../services/translation';
import { useGlobalContext, useLibbyCall } from '../../../../hooks';
import { LibbyObject } from '../../../../types/types';
import { ChartPie } from '../../../../chart/ChartPie';
import { format } from '../../../../util';
import { FilterBarSelection } from '../../../Reporting/routes/ReportingList/FilterBar';
import { BarChartComponent } from '../../../../components/BarChart';
import { HistoryMarketplace } from './components/history';
import { endDateFormat, startDateFormat } from '../../../../utils';
import { MarketplaceReportingType, TotalMarketplaceReportingType } from './type/ReportingMarketplace';
import { useStyles } from './styles';
import { useInterval } from '../../../../hooks/useIntervalPage';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { ButtonDetailsType } from '../../../../types/Button';
import { ButtonComponent } from '../../../../components/Button';
import { CHART_COLORS } from '../../../../chart/types';
import { FilterReportinSales, SCREENS } from '../../../../components/FilterManager';
import { useFilterLibbyCall } from '../../../../business/Filter';
import { Filter } from '../../../../interfaces/business';
import { userInfoType } from '../../../../types';
import KpiComponent, { KpiData, KpiSubData } from '../../../components/Kpi';

export type ReportingSalesListProps = { vatEnabled?: boolean; libby: LibbyObject; typeReporting: 'Sales' | 'Orders'; daoNameHistory: string; daoName: string; columnsAux: Array<Column>; columnsProductAux: Array<Column> };

const styleChartPie = {
  top: '50%',
  right: 20,
  transform: 'translate(0, -50%)',
  lineHeight: '24px',
  cursor: 'pointer'
};

type AggregateRow = {
  [k: string]: any;
};

type TypeColumn = {
  name: string;
  total: number;
  count: number;
  id: string;
};

const filterInit: FilterBarSelection = {
  marketplaces: [],
  courier: [],
  from: startDateFormat(moment()).toDate(),
  to: endDateFormat(moment().add('1', 'days')).toDate(),
  canal: [],
  company: []
};

const initFilter: Filter<FilterBarSelection> = {
  name: '',
  private: false,
  preset: false,
  account: { username: '', account_id: '-1' },
  screen: { screen_id: SCREENS.REPORTING_SALES.toString(), name: '' },
  last_update: new Date(),
  metadata: filterInit
};

const emptyName = ['Discounts', 'Shipping cost', 'Sub total'];

const textBold = [...emptyName, 'Total'];
const ReportingSalesListRaw = ({
  libby,
  typeReporting = 'Sales',
  daoNameHistory = 'ster_order_history_marketplace',
  daoName = 'ster_order_reporting_sales',
  columnsProductAux,
  columnsAux,
  vatEnabled = false
}: ReportingSalesListProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { userInfo }: { userInfo: userInfoType } = useGlobalContext();

  const { data: filtersByUser = [], working: wFilterByUser } = useFilterLibbyCall<Filter<FilterBarSelection>[]>({
    methodName: 'getByUserFilter',
    params: [userInfo?.id || '', SCREENS.REPORTING_SALES]
  });

  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');

  const [directionProduct, setDirectionProduct] = useState<'asc' | 'desc'>('desc');

  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));

  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const matches = useMediaQuery('(min-width:1500px)');

  const matchesDesktopLarge = useMediaQuery('(min-width:1600px)');

  // Build the columns, depends on t function for translations
  const columns = useMemo<Array<Column>>(
    () => [
      {
        id: 'name',
        label: 'Marketplace',
        style: {
          whiteSpace: 'nowrap',
          width: '150px'
        },
        render: (value: TypeColumn) => (
          <Box fontWeight={textBold.includes(value.name) ? 'bold' : 'regular'}>
            <Typography noWrap variant="body1" color="textSecondary">
              {t(value.name)}
            </Typography>
          </Box>
        ),
        noSort: true
      },
      {
        id: 'count',
        label: 'Count',
        render: (value: TypeColumn) => (textBold.includes(value.name) ? <b>{format(value.count, 'Integer')}</b> : format(value.count, 'Integer')),
        align: 'right',
        noSort: true
      },
      {
        id: 'total',
        label: 'Total',
        render: (value: TypeColumn) => (textBold.includes(value.name) ? <b>{format(value.total, 'Currency', t)}</b> : format(value.total, 'Currency', t)),
        align: 'right'
      }
    ],
    [t]
  );

  const columnsProducts: Array<Column> = [
    {
      id: 'name',
      label: 'Products',
      style: {
        whiteSpace: 'nowrap'
      },
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (
        <Tooltip title={`${tr(value.name)}`}>
          <Box maxWidth={isMd ? 400 : 100} minWidth={100}>
            <Typography noWrap variant="body1" color="textSecondary" align={textBold.includes(value.name) ? 'right' : 'left'} className={textBold.includes(value.name) ? classes.bold : ''}>
              {tr(value.name)}
            </Typography>
          </Box>
        </Tooltip>
      ),
      noSort: true
    },
    {
      id: 'count',
      label: 'Count',
      render: (value: TypeColumn) => {
        const text = emptyName.includes(value.name) ? '' : format(value.count, 'Integer');
        return textBold.includes(value.name) ? <b>{text}</b> : text;
      },
      align: 'right',
      noSort: true
    },
    {
      id: 'total',
      label: 'Total',
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (textBold.includes(value.name) ? <b>{format(value.total, 'Currency', tr)}</b> : format(value.total, 'Currency', tr)),
      align: 'right'
    }
  ];

  // eslint-disable-next-line arrow-body-style

  const { data, recall, working } = useLibbyCall(libby, {
    daoName,
    methodName: 'getByDate',
    params: [filterInit]
  });

  const workingData = useMemo(() => (typeof working === 'boolean' && data?.marketplaces ? working : true), [working, data]);

  const dataReportingSales = useMemo(
    () =>
      data?.marketplaces
        ? data
        : {
            marketplaces: [],
            products: [],
            companies: [],
            source: []
          },
    [data]
  );

  // FIXME CHINO-SDK IS NOT TAKING THE ALIAS, REMOVE THIS WHEN IS OK

  const { data: allDataMarketplace } = useLibbyCall(libby, {
    daoName: 'ster_marketplace',
    methodName: 'getAll'
  });

  const searchMarketplaceName = useCallback((marketplace_id_search: string) => allDataMarketplace.find((dataMarketplace: any) => dataMarketplace.marketplace_id === marketplace_id_search).name, [allDataMarketplace]);

  const statistics = useMemo(() => {
    const dataGroupReduce = dataReportingSales.marketplaces.reduce((result: any[], element: AggregateRow) => {
      if (element.source_marketplace_marketplace_id && allDataMarketplace.length) {
        const id = element.source_marketplace_marketplace_id;
        result.push({
          count: parseInt(element.countofsource_marketplace_marketplace_id, 10),
          total: parseFloat(element.sumofamount),
          vat: element.sumvat ? parseFloat(element.sumvat).toFixed(2) : 0,
          name: searchMarketplaceName(id),
          key: id,
          id,
          color: CHART_COLORS[parseInt(id, 10)]
        });
      }
      return result;
    }, []);

    return orderBy(dataGroupReduce, ['total', 'count'], ['desc']);
  }, [dataReportingSales, allDataMarketplace, searchMarketplaceName]);

  const statisticsProducts: TypeColumn[] = useMemo(
    () =>
      dataReportingSales.products.reduce((result: any[], element: AggregateRow) => {
        if (element.namep) {
          result.push({
            count: parseInt(element.countofitems_name, 10),
            total: parseFloat(element.sumofamount),
            vat: element.sumvat ? parseFloat(element.sumvat).toFixed(2) : 0,
            name: element.namep
          });
        }
        return result;
      }, []),
    [dataReportingSales]
  );

  const statisticsCompany = useMemo(() => {
    const dataGroupReduce = dataReportingSales.companies.reduce((result: any[], element: AggregateRow) => {
      if (element.source_marketplace_marketplace_id) {
        const id = element.source_marketplace_marketplace_id;
        result.push({
          count: parseInt(element.countofsource_marketplace_marketplace_id, 10),
          total: parseFloat(element.sumofamount),
          name: element.company_name,
          key: id,
          id
        });
      }
      return result;
    }, []);

    return orderBy(dataGroupReduce, ['total', 'count'], ['desc']);
  }, [dataReportingSales]);

  const statisticsOrderSourceType = useMemo(() => {
    const dataGroupReduce = dataReportingSales.source.reduce((result: any[], element: AggregateRow) => {
      if (element.source_marketplace_marketplace_id) {
        const id = element.source_marketplace_marketplace_id;
        result.push({
          count: parseInt(element.countofsource_marketplace_marketplace_id, 10),
          total: parseFloat(element.sumofamount),
          name: element.order_source_type_name,
          key: id,
          id
        });
      }
      return result;
    }, []);

    return orderBy(dataGroupReduce, ['total', 'count'], ['desc']);
  }, [dataReportingSales]);

  const withOrder = useMemo(() => {
    const copyStatistics: Array<any> = [...statistics];

    const allTotal = copyStatistics.reduce(
      (transport: AggregateRow, element: AggregateRow) => {
        transport.count += element.count;
        transport.total += element.total;
        transport.vat += element.vat ? parseFloat(element.vat) : 0;
        return transport;
      },
      { name: 'Total', count: 0, total: 0, vat: 0 }
    );
    if (vatEnabled) copyStatistics.push({ name: 'Vat', count: 0, total: `-${allTotal.vat}` });

    copyStatistics.push(vatEnabled ? { ...allTotal, total: allTotal.total - allTotal.vat } : allTotal);
    const all = copyStatistics.pop();
    const rowsOrderBy = orderBy(copyStatistics, ['total', 'count'], [direction]);
    rowsOrderBy.push(all);

    return rowsOrderBy;
  }, [statistics, direction, vatEnabled]);

  const statisticsWithTotal = useMemo(
    () =>
      statistics.reduce(
        (transport: AggregateRow, element: AggregateRow) => {
          transport.count += element.count;
          transport.total += element.total;
          transport.vat += element.vat ? parseFloat(element.vat) : 0;
          return transport;
        },
        { count: 0, total: 0, vat: 0 }
      ),
    [statistics]
  );

  const withOrderProducts = useMemo(() => {
    if (!dataReportingSales.marketplaces.length) return [];
    const { subTotal, total, shipping_cost, vat } = dataReportingSales.marketplaces.reduce(
      (resultMarketplace: TotalMarketplaceReportingType, { sumsubamount, sumofamount, sumshipping_cost, sumvat }: MarketplaceReportingType) => {
        resultMarketplace.subTotal += parseFloat(sumsubamount);
        resultMarketplace.total += parseFloat(sumofamount);
        resultMarketplace.vat += sumvat ? parseFloat(sumvat) : 0;
        resultMarketplace.shipping_cost += parseFloat(sumshipping_cost);
        return resultMarketplace;
      },
      { subTotal: 0, total: 0, shipping_cost: 0, vat: 0 }
    );
    const newArray: Array<any> = [...statisticsProducts];
    newArray.push(
      newArray.reduce(
        (transport: AggregateRow, element: AggregateRow) => {
          transport.count += element.count;
          transport.total += element.total;
          transport.vat += element?.vat || 0;
          return transport;
        },
        { name: 'Sub total', count: 0, total: 0, vat: 0 }
      )
    );

    const all = newArray.pop();
    const rowsOrderBy = orderBy(newArray, ['total', 'count'], [directionProduct]);
    rowsOrderBy.push(all);
    const discounts = subTotal + shipping_cost - total;
    const totalWithDiscounts = all.total - discounts;
    const totalAll = vatEnabled ? totalWithDiscounts - vat : totalWithDiscounts;
    rowsOrderBy.push({ name: 'Discounts', total: `-${discounts}` });
    if (vatEnabled) rowsOrderBy.push({ name: 'Vat', total: `-${vat}` });
    rowsOrderBy.push({ name: 'Shipping cost', total: shipping_cost });
    rowsOrderBy.push({ name: 'Total', total: totalAll + shipping_cost, count: all.count });
    return rowsOrderBy;
  }, [statisticsProducts, directionProduct, dataReportingSales, vatEnabled]);

  const handleRequestSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setDirection(newDirection);
  };

  const handleRequestProductSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setDirectionProduct(newDirection);
  };

  const [filter, setFilter] = useState<Filter<FilterBarSelection>>(initFilter);

  const [stateRefresh, setState] = React.useState(false);

  const delay = useMemo(() => (stateRefresh ? 60000 : null), [stateRefresh]);

  useEffect(() => {
    if (!wFilterByUser && filtersByUser.length > 0) setFilter(filtersByUser[0]);
  }, [wFilterByUser, filtersByUser]);

  const refresh = useCallback(() => {
    recall?.(filter.metadata);
  }, [recall, filter]);

  const { time, resetTime } = useInterval(refresh, delay);

  const onChangeMarketplace = useCallback(
    (dataMarketplace: TypeColumn) => {
      const newFilter: FilterBarSelection = {
        ...filter.metadata,
        marketplaces: [
          {
            id: dataMarketplace.id,
            value: dataMarketplace.name
          }
        ]
      };
      setFilter({ ...filter, metadata: newFilter });
      recall?.(newFilter);
    },
    [filter, recall]
  );

  const onChangeCompany = useCallback(
    (dataCompany: TypeColumn) => {
      const newFilter: FilterBarSelection = {
        ...filter.metadata,
        company: [
          {
            id: dataCompany.id,
            value: dataCompany.name
          }
        ]
      };
      setFilter({ ...filter, metadata: newFilter });
      recall?.(newFilter);
    },
    [filter, recall]
  );

  const onChangeOrderSourceType = useCallback(
    (dataOrderSource: TypeColumn) => {
      const newFilter: FilterBarSelection = {
        ...filter.metadata,
        canal: [
          {
            id: dataOrderSource.id,
            value: dataOrderSource.name
          }
        ]
      };
      setFilter({ ...filter, metadata: newFilter });
      recall?.(newFilter);
    },
    [filter, recall]
  );
  const OnChangeApplyFilters = useCallback(() => {
    if (stateRefresh) {
      resetTime();
    } else {
      refresh();
    }
  }, [refresh, stateRefresh, resetTime]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'dispatchDetailAddOrder',
        onClick: OnChangeApplyFilters,
        title: `${t('Refresh')} ${stateRefresh ? `(${time} ${t('Seconds').toLowerCase()})` : ''}`,
        disabled: workingData,
        loading: stateRefresh ? workingData : false
      }
    ],
    [time, OnChangeApplyFilters, workingData, stateRefresh, t]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.checked);
  };

  const allKpi: KpiData[] = useMemo(() => {
    const size = vatEnabled ? 3 : 6;
    const dataKpi: KpiSubData[] = [
      {
        title: 'Amount sales',
        value: statisticsWithTotal.count,
        xs: 12,
        sm: 12,
        md: size,
        lg: size,
        xl: size
      },
      {
        title: 'Total sales',
        value: format(statisticsWithTotal.total, 'Currency', t),
        xs: 12,
        sm: 12,
        md: size,
        lg: size,
        xl: size
      }
    ];
    if (vatEnabled) {
      dataKpi.push({
        title: 'Total vat',
        value: format(statisticsWithTotal.vat, 'Currency', t),
        xs: 12,
        sm: 12,
        md: size,
        lg: size,
        xl: size
      });
      dataKpi.push({
        title: 'Total without taxation',
        value: format(statisticsWithTotal.total - parseFloat(statisticsWithTotal.vat), 'Currency', t),
        xs: 12,
        sm: 12,
        md: size,
        lg: size,
        xl: size
      });
    }
    return [
      {
        data: dataKpi
      }
    ];
  }, [statisticsWithTotal, t, vatEnabled]);
  return (
    <ScreenAligned
      title={typeReporting}
      additionalTitle={
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Switch checked={stateRefresh} onChange={handleChange} name="checkedA" color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />
          </Box>
          {buttons.map((dataButton: ButtonDetailsType) => (
            <ButtonComponent
              key={dataButton.id}
              title={dataButton.title}
              className={dataButton.className}
              variant={dataButton.variant}
              color={dataButton.color}
              onClick={dataButton.onClick}
              type={dataButton.type}
              disabled={dataButton.disabled}
              loading={dataButton.loading}
            />
          ))}
        </>
      }
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <FilterReportinSales initialFilter={initFilter} filter={filter} onFilter={setFilter} initExpanded working={workingData} withSaved />

        <KpiComponent allKpi={allKpi} />

        <Grid container xs={12} spacing={isSm ? 4 : 2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Box bgcolor="white" p={isXs ? 2 : 4} height="100%" justifyContent="center">
              <Typography variant="h5">{t('Number of sales by marketplace')}</Typography>
              <ChartPie data={statistics} working={workingData} type="Pie" key="source_marketplace_name" value="count" height={300} format="Integer" xs={12} onChange={onChangeMarketplace} />
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Box bgcolor="white" p={isXs ? 2 : 4} height="100%" justifyContent="center">
              <Typography variant="h5">{`${t('Total amount of sales by company')}`}</Typography>
              <ChartPie
                layout={matchesDesktopLarge || matches ? 'vertical' : undefined}
                verticalAlign={matchesDesktopLarge || matches ? 'middle' : undefined}
                outerRadius={matchesDesktopLarge ? '95%' : matches ? '75%' : undefined}
                cx={matchesDesktopLarge ? '32%' : matches ? '34%' : undefined}
                cy={matchesDesktopLarge ? '50%' : undefined}
                wrapperStyle={matchesDesktopLarge || matches ? styleChartPie : undefined}
                data={statisticsCompany}
                working={workingData}
                type="Pie"
                key="source_marketplace_name"
                value="total"
                height={300}
                format="Integer"
                xs={12}
                onChange={onChangeCompany}
              />
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Box bgcolor="white" p={isXs ? 2 : 4} height="100%" justifyContent="center">
              <Typography variant="h5">{`${t('Total amount of sales by source type')}`}</Typography>
              <ChartPie
                layout={matchesDesktopLarge || matches ? 'vertical' : undefined}
                verticalAlign={matchesDesktopLarge || matches ? 'middle' : undefined}
                outerRadius={matchesDesktopLarge ? '95%' : matches ? '75%' : undefined}
                cx={matchesDesktopLarge ? '32%' : matches ? '34%' : undefined}
                cy={matchesDesktopLarge ? '50%' : undefined}
                wrapperStyle={matchesDesktopLarge || matches ? styleChartPie : undefined}
                data={statisticsOrderSourceType}
                working={workingData}
                type="Pie"
                key="source_marketplace_name"
                value="total"
                height={300}
                format="Integer"
                xs={12}
                onChange={onChangeOrderSourceType}
              />
            </Box>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box bgcolor="white" p={isXs ? 2 : 4} height="100%" justifyContent="center">
              <Box height="10%">
                <Typography variant="h5">{t('Sales')}</Typography>
              </Box>
              <Box display="flex" height="90%" justifyContent="center" alignItems="center" py={2}>
                <BarChartComponent working={workingData} dataChart={statistics} onChange={onChangeMarketplace} bar={['total']} dataKeyXAxis="total" dataIgnorePayload={['count', 'key', 'id', 'color']} />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box bgcolor="white" p={isXs ? 2 : 4} height="100%">
              <Box height="10%">
                <Typography variant="h5">{t('Top 10 products')}</Typography>
              </Box>
              <Box display="flex" height="90%" justifyContent="center" alignItems="center" py={2}>
                <BarChartComponent working={workingData} dataChart={orderBy(statisticsProducts.slice(0, 10), ['total', 'count'], ['desc'])} bar={['total']} dataKeyXAxis="total" dataIgnorePayload={['count', 'key']} />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <HistoryMarketplace libby={libby} filter={filter.metadata} daoName={daoNameHistory} />
        <Box my={2} display="flex" width="100%">
          <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={4}>
            <InfoTable
              padding={false}
              columns={columnsAux || columns}
              rows={withOrder}
              onBottomScroll={() => {}}
              onSortChange={handleRequestSort}
              direction={direction}
              rowIdKey="name"
              height="auto"
              md={6}
              sm={12}
              xs={12}
              working={workingData}
            />

            <InfoTable
              padding={false}
              columns={columnsProductAux || columnsProducts}
              rows={withOrderProducts}
              onBottomScroll={() => {}}
              onSortChange={handleRequestProductSort}
              direction={directionProduct}
              rowIdKey="name"
              height="auto"
              md={6}
              sm={12}
              xs={12}
              working={workingData}
            />
          </Grid>
        </Box>
      </Grid>
    </ScreenAligned>
  );
};

// El dao a usar debe de heredar de LibbyFetchDAO para que funcione
export const ReportingSalesList = DatabaseConnector(ReportingSalesListRaw)(
  'ster_order_table',
  'ster_order_so',
  'ster_order_table_update',
  'ster_order_history_marketplace',
  'ster_marketplace',
  'ster_order_reporting_sales',
  'ster_order_reporting_orders',
  'ster_order_reporting_orders_history_marketplace',
  'ster_order_reporting_sales_taxation'
);
