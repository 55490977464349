import { Order_reporting_billing } from '../../../interfaces/business/orders/Order_reporting_reporting_billings_ml';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_reporting_billing_ml';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Order_reporting_billing>(DAO_NAME);

// hooks
export const useReportingBillingMlFetchById = fetchById;
export const useReportingBillingMlLibbyCall = libbyCall;
export const useReportingBillingMlDAO = libbyDAO;
export const useReportingBillingMlLibbyFetch = libbyFetch;
