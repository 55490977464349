import React, { useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from '../../services/translation';
import { DataGenerateAutocompleteType } from '../../utils/generateAutocomplete';

export type GeneralSelectProps = {
  working: boolean;
  disabled?: boolean;
  label: string;
  data: DataGenerateAutocompleteType[];
  onChange: (event: React.ChangeEvent<{}>) => void;
  [k: string]: any;
  id: string;
  placeholder?: string;
  value: DataGenerateAutocompleteType[];
};

export const GeneralAutocomplete = ({ value, id, onChange, working, label, disabled, data, placeholder = '' }: GeneralSelectProps) => {
  const { t } = useTranslation();
  const textLabel = useMemo(() => (label.length ? `All ${label.toLowerCase()}` : label), [label]);
  return (
    <Autocomplete
      value={value}
      style={{ display: 'inline-block', width: '100%' }}
      multiple
      id={id}
      options={data}
      getOptionLabel={(option: { id: string; value: string }) => option.value}
      filterSelectedOptions
      onChange={onChange}
      loading={working}
      disabled={disabled}
      renderInput={(params) => <TextField {...params} variant="outlined" label={t(textLabel)} placeholder={t(placeholder)} />}
    />
  );
};
