import React, { useCallback, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AnyObject, LibbyObject } from '../../types/types';
import { GeneralSelect } from '../../components/GeneralSelect';
import { useLibbyFetch } from '../../hooks/useLibbyFetch';
import { makeFilter } from '../Orders/routes/OrdersList/utils/makeFilter';
import { useTranslation } from '../../services/translation';
import { DataGenerateSelectType } from '../../utils/generateSelect';

type SelectImportTypeProps = {
  multiple: boolean;
  typeOrderCourierSearch: string;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
};

export const SelectOrderCourierRaw = ({ multiple = false, typeOrderCourierSearch, libby, inputProps = {}, optionAll = false, disabled = false, sort, ...styles }: SelectImportTypeProps) => {
  const { t } = useTranslation();
  const filter = useMemo(() => {
    const typeOrderCourierMarketplaceSearch = typeOrderCourierSearch;
    return makeFilter({ typeOrderCourierMarketplaceSearch });
  }, [typeOrderCourierSearch]);

  const paramsFetch = useMemo(
    () => ({
      daoName: 'courier_order',
      filter
    }),
    [filter]
  );

  const { data: dataRaw, working }: { data: AnyObject; working: boolean } = useLibbyFetch(libby, paramsFetch);

  const data = useMemo(
    () =>
      dataRaw.map(({ courier_id, name }: AnyObject) => ({
        id: courier_id,
        value: name
      })),
    [dataRaw]
  );

  const onChangeAutoComplete = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((marketplace: DataGenerateSelectType) => ({
          value: marketplace.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          renderInput={(params) => <TextField {...params} variant="outlined" label={t('All the courier')} placeholder="" />}
        />
      ) : (
        <GeneralSelect disabled={disabled} working={working} label="Courier" optionAll={optionAll} data={data} inputProps={inputProps} {...styles} sort={sort} />
      )}
    </>
  );
};

export const SelectOrderCourier = DatabaseConnector(SelectOrderCourierRaw)('courier_order');
