import { useCallback, useMemo } from 'react';
import { Order_reporting_goal_sales, Order_reporting_goal_sales_all } from '../../../../../interfaces/business';
import { FilterBarSelectionReportingObjectiveSales } from '../type';
import { format } from '../../../../../util';
import { useTranslation } from '../../../../../services/translation';
import { useReportingObjectiveSalesLibbyCall } from '../../../../../business/orders/order/ReportingObjectSales';
import { UpdateObjectiveForm } from '../components/UpdateObjectiveForm';
import CustomModal from '../../../../../services/customFormDialog';

type useReportingObjectiveSaleCategoryLogicProps = {
  filterInit: FilterBarSelectionReportingObjectiveSales;
  filter: FilterBarSelectionReportingObjectiveSales;
};

type TotalGol = { objective: number; reached: number };

const initTotalGoal = {
  objective: 0,
  reached: 0
};

const UpdateObjectiveModal = CustomModal(UpdateObjectiveForm);

export const useReportingObjectiveSaleCategoryLogic = ({ filterInit, filter }: useReportingObjectiveSaleCategoryLogicProps) => {
  const { t } = useTranslation();

  const { data, working, recall } = useReportingObjectiveSalesLibbyCall<Order_reporting_goal_sales_all>({
    methodName: 'getByDateOrderReportingObjectiveSales',
    params: [filterInit]
  });

  const statisticsProductsCategory = useMemo(
    () =>
      data?.goal_product_monetary
        ? data?.goal_product_monetary.reduce((result: any[], element: Order_reporting_goal_sales) => {
            if (element.name) {
              result.push({
                name: element.name,
                objective: element.value_goal,
                reached: element.total,
                'percentage reached': `${((element.total * 100) / element.value_goal).toFixed(2)}%`
              });
            }
            return result;
          }, [])
        : [],
    [data]
  );

  const dataFormatPayload = [
    {
      name: 'reached',
      format: (value: any) => format(value, 'Currency', t)
    },
    {
      name: 'objective',
      format: (value: any) => format(value, 'Currency', t)
    }
  ];

  const statisticsQuantityCategory = useMemo(
    () =>
      data?.goal_product_unit.length
        ? data?.goal_product_unit.reduce((result: any[], element: Order_reporting_goal_sales) => {
            if (element.name) {
              result.push({
                name: element.name,
                objective: element.value_goal,
                reached: element.total,
                'percentage reached': `${((element.total * 100) / element.value_goal).toFixed(2)}%`
              });
            }
            return result;
          }, [])
        : [],
    [data]
  );
  const statisticsProductCanalWithTotal = useMemo(
    () =>
      data?.goal_product_unit.length
        ? data?.goal_product_unit.reduce((dataTotal: TotalGol, element: Order_reporting_goal_sales) => {
            return {
              objective: dataTotal.objective + element.value_goal,
              reached: dataTotal.reached + element.total
            };
          }, initTotalGoal)
        : initTotalGoal,
    [data]
  );

  const statisticsReportingObjectiveSaleCanal = useMemo(
    () =>
      data?.goal_marketplace_monetary.length
        ? data?.goal_marketplace_monetary.reduce((result: any[], element: Order_reporting_goal_sales) => {
            if (element.name) {
              result.push({
                name: element.name,
                objective: element.value_goal,
                reached: element.total,
                'percentage reached': `${((element.total * 100) / element.value_goal).toFixed(2)}%`
              });
            }
            return result;
          }, [])
        : [],
    [data]
  );

  const statisticsProductsMonetaryAllTotal = useMemo(() => {
    const dataStatisticsCategory = statisticsProductsCategory?.reduce(
      (total, statisticReportingObjectiveSaleCanal) => {
        return {
          objective: total.objective + statisticReportingObjectiveSaleCanal.objective,
          reached: total.reached + statisticReportingObjectiveSaleCanal.reached
        };
      },
      {
        objective: 0,
        reached: 0
      }
    );
    dataStatisticsCategory.reached = data?.goal_product_monetary_discount
      ? dataStatisticsCategory.reached - data?.goal_product_monetary_discount.discounts + data?.goal_product_monetary_discount.shippings_cost
      : dataStatisticsCategory.reached;
    return filter?.category?.length
      ? dataStatisticsCategory
      : statisticsReportingObjectiveSaleCanal?.reduce(
          (total, statisticReportingObjectiveSaleCanal) => {
            return {
              objective: total.objective + statisticReportingObjectiveSaleCanal.objective,
              reached: total.reached + statisticReportingObjectiveSaleCanal.reached
            };
          },
          {
            objective: 0,
            reached: 0
          }
        );
  }, [statisticsReportingObjectiveSaleCanal, filter, statisticsProductsCategory, data]);

  const dataFormatPayloadCanal = [
    {
      name: 'reached',
      format: (value: string) => format(value, 'Currency', t)
    },
    {
      name: 'objective',
      format: (value: string) => format(value, 'Currency', t)
    }
  ];

  const handleOnOpenUpdateObjective = useCallback(async () => {
    try {
      await UpdateObjectiveModal.show({
        confirmText: t('Update'),
        cancelText: t('Cancel'),
        title: t('Update Objective')
      });
    } catch (error) {
      // nothing
    }
  }, [t]);

  return {
    working,
    data,
    recall,
    statisticsProductsCategory,
    dataFormatPayload,
    statisticsQuantityCategory,
    statisticsProductCanalWithTotal,
    dataFormatPayloadCanal,
    statisticsReportingObjectiveSaleCanal,
    handleOnOpenUpdateObjective,
    statisticsProductsMonetaryAllTotal
  };
};
