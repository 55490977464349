import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ReportingLogisticList } from './routes';
import { ReportingLogisticNonWorkingDayList } from './routes/ReportingLogisticNonWorkingDayList';
import { ReportingLogisticSlaList } from './routes/ReportingLogisticSlaList';

export const ReportingLogistic = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <ReportingLogisticList />
      </Route>
      <Route exact path={`${match.path}/sla`}>
        <ReportingLogisticSlaList />
      </Route>
      <Route exact path={`${match.path}/non-working-day`}>
        <ReportingLogisticNonWorkingDayList />
      </Route>
    </Switch>
  );
};
