import { Account } from '../../../../interfaces/business';
import { makeValidator, ValidatorType, ValidatorRule } from '../../../../utils';

export const formInitialValues = {
  username: '',
  password: '',
  account_role: '-1'
};

export const initAccount: Account = {
  account_enabled: true,
  account_id: -1,
  creation_date: new Date(),
  email: '',
  password: '',
  username: '',
  role: {
    account_role_id: 1,
    role: '',
    account_role_menu: [],
    menu_action_account_role: {
      menu_action_account_role_id: -1,
      menu_action: {
        menu_action_id: -1,
        name: '',
        menu: {
          menu_id: -1,
          name: ''
        }
      }
    }
  }
};

export const formInitialValuesPublic = {
  username: '',
  password: ''
};

const validationsPublic: ValidatorRule[] = [
  { key: 'username', validators: [ValidatorType.REQUIRED] },
  { key: 'password', validators: [ValidatorType.REQUIRED] },
  { key: 'email', validators: [ValidatorType.REQUIRED, ValidatorType.EMAIL, ValidatorType.ALLOWEDDOMAIN] }
];

const validations: ValidatorRule[] = [
  { key: 'username', validators: [ValidatorType.REQUIRED] },
  { key: 'password', validators: [ValidatorType.REQUIRED, ValidatorType.ATLEASTEIGHT, ValidatorType.ONEUPPERCASE, ValidatorType.ONELOWERCASE, ValidatorType.ONENUMBER, ValidatorType.ONESPECIAL] },
  { key: 'account_role', validators: [ValidatorType.REQUIRED] },
  { key: 'email', validators: [ValidatorType.REQUIRED, ValidatorType.EMAIL, ValidatorType.ALLOWEDDOMAIN] }
];

export const validate = makeValidator(validations);

export const validatePublic = makeValidator(validationsPublic);
export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
