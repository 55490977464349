import { useMemo } from 'react';
import { useGoalLibbyCall } from '../business/goal';
import { Goal } from '../interfaces/business';
import { useTranslation } from '../services/translation';

interface UseDataCallGoalProp {
  marketplace: boolean;
  methodName?: string;
}

export const useDataCallGoal = ({ marketplace = false, methodName = 'getAllGoalByDate' }: UseDataCallGoalProp) => {
  const { t } = useTranslation();
  const {
    data: dataAll,
    working,
    recall
  } = useGoalLibbyCall({
    methodName,
    aspect: 'list_all_goal',
    noAutoCall: true
  });

  const data = useMemo(() => {
    const dataGoal = dataAll as Goal[];
    const dataFilterGoal = dataGoal?.filter(({ goal_marketplace, goal_product }: Goal) => {
      return marketplace ? goal_marketplace?.length : goal_product?.length;
    });

    return dataFilterGoal?.length
      ? dataFilterGoal.map(({ goal_id, name }: Goal) => ({
          id: goal_id,
          value: t(name)
        }))
      : [];
  }, [dataAll, marketplace, t]);

  return { dataAll, data, working, recall };
};
