import FormControl from '@material-ui/core/FormControl';
import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { orderBy } from 'lodash';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from '../../services/translation';
import { DataGenerateSelectType } from '../../utils/generateSelect';
import { makeGetErrorAndHelperText } from '../../utils';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
    '& > div > div > div': {
      flex: 1
    }
  },
  pText: {
    margin: '0px 4px',
    fontWeight: 'bold'
  }
});

export type GeneralSelectProps = {
  working: boolean;
  disabled?: boolean;
  inputProps?: TextFieldProps;
  label: string;
  optionAll: boolean;
  customAll?: string;
  data: DataGenerateSelectType[];
  [k: string]: any;
  sort?: boolean | 'asc' | 'desc';
  sortBy?: string;
  field?: any;
  msgEmptyData?: string;
  msgEmptyEnable?: boolean;
};

export const GeneralSelect = ({
  field,
  inputProps,
  working,
  label,
  disabled,
  optionAll,
  customAll,
  data,
  sort = 'asc',
  sortBy = 'value',
  msgEmptyData = 'No availability to select',
  msgEmptyEnable = false,
  ...styles
}: GeneralSelectProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const translatedLabel = t(label);

  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  const getErrorValidate = field ? { ...getErrorAndHelperText(field) } : {};

  return (
    <FormControl className={classes.formControl} style={styles}>
      <TextField
        {...inputProps}
        label={working && !disabled ? `${t('Loading')} ${translatedLabel} ...` : translatedLabel}
        InputLabelProps={{
          shrink: true
        }}
        placeholder={translatedLabel}
        fullWidth
        select
        disabled={!!disabled || !data.length}
        {...getErrorValidate}
      >
        {working && !disabled ? (
          <MenuItem value="0">
            <CircularProgress size={24} />
          </MenuItem>
        ) : (
          optionAll && <MenuItem value="0">{t(customAll || 'All')}</MenuItem>
        )}

        {!working &&
          !sort &&
          data.map(({ id, value, name = false, extra }) => (
            <MenuItem value={name ? String(value) : String(id)} key={id}>
              {t(value)}
              {extra && <p className={classes.pText}>{extra}</p>}
            </MenuItem>
          ))}
        {!working &&
          sort &&
          orderBy(
            data.map((item) => ({ ...item, value: t(item.value) })),
            [sortBy],
            [sort]
          ).map(({ id, value, name = false, extra }) => (
            <MenuItem value={name ? String(value) : String(id)} key={id}>
              {t(value)}
              {extra && <p className={classes.pText}>{extra}</p>}
            </MenuItem>
          ))}
      </TextField>

      {!data.length && msgEmptyEnable && (
        <Box mt={1}>
          <Typography color="error" variant="body2">
            {t(msgEmptyData)}
          </Typography>
        </Box>
      )}
    </FormControl>
  );
};
